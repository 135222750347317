import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const AddProfileForm = ({ handleCloseModal, handleAddProfile, clientRef }) => {
    const history = useHistory();

    const [profileData, setProfileData] = useState({ // Initializing state for profile data
        _email: '',
        firstName: '',
        lastName: '',
        password: '',
        accountCreationDate: null,
        avatarURL: '',
        canPlaceOrders: true,
        catalogsList: [],
        orderList: [],
        ref_log_access: '',
        role: 'Client',
        clientRef: clientRef,
    });

    const [repeatPassword, setRepeatPassword] = useState(''); // Initializing state for repeat password

    const handleChange = (e) => { // Handler for input change
        const { name, value } = e.target;
        if (name === '_email' || name === 'firstName' || name === 'lastName' || name === 'password' || name === 'repeatPassword') {
            if (name === 'repeatPassword') {
                setRepeatPassword(value);
            } else {
                setProfileData(prevState => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
    };

    const handleSubmit = async (e) => { // Handler for form submission
        e.preventDefault();

        if (profileData.password !== repeatPassword) {
            console.error('Passwords do not match');
            return;
        }

        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(
                profileData._email,
                profileData.password
            );

            const { user } = userCredential;
            const timestamp = firebase.firestore.Timestamp.now();

            await handleAddProfile({
                ...profileData,
                accountCreationDate: timestamp,
            });

            // console.log('Profile successfully added:', user);

            // Resetting form and state
            setProfileData({
                _email: '',
                firstName: '',
                lastName: '',
                password: '',
                accountCreationDate: null,
                avatarURL: '',
                canPlaceOrders: true,
                catalogsList: [],
                orderList: [],
                ref_log_access: '',
                role: 'Client',
                clientRef: clientRef,
            });

            setRepeatPassword('');

            handleCloseModal(); // Closing modal

            history.go(0); // Refreshing page

        } catch (error) {
            console.error('Error adding profile:', error.message);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="_email">Email</Label>
                <Input
                    type="email"
                    name="_email"
                    id="_email"
                    className="form-control"
                    placeholder="Enter email"
                    value={profileData._email}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter first name"
                    value={profileData.firstName}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter last name"
                    value={profileData.lastName}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">Password</Label>
                <Input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter password"
                    value={profileData.password}
                    onChange={handleChange}
                    required
                    autoComplete="new-password"
                />
            </FormGroup>
            <FormGroup>
                <Label for="repeatPassword">Repeat Password</Label>
                <Input
                    type="password"
                    name="repeatPassword"
                    id="repeatPassword"
                    className="form-control"
                    placeholder="Repeat password"
                    value={repeatPassword}
                    onChange={handleChange}
                    required
                    autoComplete="new-password"
                />
            </FormGroup>
            <Button type="submit" color="primary" className="mx-auto d-block">Confirm</Button>
        </Form>
    );
};

export default AddProfileForm;