const crypto = require('crypto');
export function generateOTP() {
  // Generate a random 6-digit number
  const otp = Math.floor(100000 + Math.random() * 900000);
  return otp.toString(); // Convert to string
}

// Function to encode a password using environment variable as secret key
export function encodePassword(password) {
  const secretKey = process.env.REACT_APP_ESK;
  if (!secretKey || secretKey.length !== 40) {
    throw new Error("Invalid or missing secret key");
  }
  const key = secretKey.slice(0, 16); // Take the first 16 bytes for AES-128 (or you can generate a 16-byte key if your key is longer)
  const iv = secretKey.slice(16, 32); 
  const cipher = crypto.createCipheriv('aes-128-cbc', Buffer.from(key), Buffer.from(iv));
  let encrypted = cipher.update(password, 'utf-8', 'hex');
  encrypted += cipher.final('hex');
  return encrypted;
}

// Function to decode an encoded password using environment variable as secret key
export function decodePassword(encodedPassword) {
  const secretKey = process.env.REACT_APP_ESK;
  if (!secretKey || secretKey.length !== 40) {
    throw new Error("Invalid or missing secret key");
  }
  const key = secretKey.slice(0, 16); // Take the first 16 bytes for AES-128 (or you can generate a 16-byte key if your key is longer)
  const iv = secretKey.slice(16, 32); 
  const decipher = crypto.createDecipheriv('aes-128-cbc', Buffer.from(key), Buffer.from(iv));
  let decrypted = decipher.update(encodedPassword, 'hex', 'utf-8');
  decrypted += decipher.final('utf-8');
  return decrypted;
}

export function getPassword(username){
  const key = process.env.REACT_APP_AR_INITIALS;
  const pw = localStorage.getItem(key+username);
  
  if(pw && pw != "null") return decodePassword(pw);
  return '';
}

export function storePassword(username,password){
  const key = process.env.REACT_APP_AR_INITIALS;
  localStorage.setItem(key+username,password);
}

export function logStorageOut(){
  //get persiting variables
  const version = localStorage.getItem('version');
  const email = localStorage.getItem('email');
  const encpw = localStorage.getItem(process.env.REACT_APP_AR_INITIALS+email);
  localStorage.clear();
  localStorage.setItem('version',version);
  localStorage.setItem(process.env.REACT_APP_AR_INITIALS+email,encpw);
  
}