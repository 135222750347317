import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import SignIn from "./auth/signin";
import ForgotPassword from "./auth/forgotPassword";
import app from "./data/base";
import ConfigDB from "./data/customizer/config";
import "./index.scss";
import ARShades3dsViewer from "./pages/ARShades3dViewer/ARShades3dsViewer";
import ARShades3dViewer from "./pages/ARShades3dViewer/ARShades3dViewer";
import ARShades3dViewerCustom from "./pages/ARShades3dViewer/ARShades3dsViewerCustom";
import ARShades3dViewerCustomTwo from "./pages/ARShades3dViewer/ARShades3dsViewerCustomTwo";
import ARShades3dViewerCustomThree from "./pages/ARShades3dViewer/ARShades3dsViewerCustomThree";
import SessionAggregator from "./component/general/dashboard/analyticsAggrgator/SessionAggregatorApp";
import AggregatedDataTable from "./component/general/dashboard/analyticsAggrgator/AggregatatedDataTable";
import Register from "./pages/authentication/register";
import store from "./store/index";
import * as serviceWorker from "./serviceWorker";
import { logStorageOut } from "./data/utils";
import { routes } from "./route/route";

const Root = (props) => {
  const [anim, setAnim] = useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(false);

  useEffect(() => {
    const color = localStorage.getItem("color");
    document.getElementById("color").setAttribute(
      "href",
      `${process.env.PUBLIC_URL}/assets/css/color-2.css`
    );
    setAnim(animation);
    app.auth().onAuthStateChanged(setCurrentUser);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const checkVersionAndToken = () => {
    const appVersion = process.env.REACT_APP_CURRENT_VERSION;
    const currentVersion = localStorage.getItem('version');
    const csToken = localStorage.getItem('csToken');

    if (!csToken || (currentVersion != null && currentVersion < appVersion)) {
      logStorageOut();
      logOut();
    } else {
      localStorage.setItem('version', appVersion);
    }
  }

  const logOut = () => {
    logStorageOut();
    app.auth().signOut();
  };

  //  Controllo TOKEN verifica
  useEffect(() => {
    checkVersionAndToken();

    const handleStorageChange = (event) => {
      if (event.key === "csToken" && event.oldValue !== event.newValue) {
        logOut();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={SignIn} />
            <Route path={`${process.env.PUBLIC_URL}/forgotPassword`} component={ForgotPassword} />
            <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
            <Route path={`${process.env.PUBLIC_URL}/Arshades3ds/:TokenBrand/glasses/:TokenGlasses`} component={ARShades3dsViewer} />
            <Route path={`${process.env.PUBLIC_URL}/Arshades3d/:TokenBrand/glasses/:TokenGlasses/variant/:TokenVariant`} component={ARShades3dViewer} />
            <Route path={`${process.env.PUBLIC_URL}/animation`} component={ARShades3dViewerCustom} />
            <Route path={`${process.env.PUBLIC_URL}/animationTwo`} component={ARShades3dViewerCustomTwo} />
            <Route path={`${process.env.PUBLIC_URL}/aggregatore`} component={SessionAggregator} />
            <Route path={`${process.env.PUBLIC_URL}/aggregatoreVista`} component={AggregatedDataTable} />
            <Route path={`${process.env.PUBLIC_URL}/animationThree`} component={ARShades3dViewerCustomThree} />

            {currentUser !== null ? (
              <App>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  render={() => {
                    return <Redirect to={`${process.env.PUBLIC_URL}/home`} />;
                  }}
                />
                <TransitionGroup>
                  {routes.map(({ path, Component }) => (
                    <Route
                      key={path}
                      exact
                      path={`${process.env.PUBLIC_URL}${path}`}
                    >
                      {({ match }) => (
                        <CSSTransition
                          in={match != null}
                          timeout={500}
                          classNames={anim}
                          unmountOnExit
                        >
                          <div>
                            <Component />
                          </div>
                        </CSSTransition>
                      )}
                    </Route>
                  ))}
                </TransitionGroup>
              </App>
            ) : (
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            )}
          </Switch>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </Fragment>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();