import React, { Fragment, useEffect } from "react";
import Footer from "./component/common/footer/footer";
import Loader from "./component/common/loader/loader";
import NavBar from "./component/common/nav/navBar";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "./redux/config/config";
import { useLocation } from 'react-router-dom';
import app from "./data/base";

const App = ({ children }) => {
  const isIcon = useSelector((state) => state.config.isIconView);
  const location = useLocation();
  const isARShadesStudioModeller = location.pathname.includes("/validation");

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          {isARShadesStudioModeller ? (
            <>
              <NavBar isARShadesStudioModeller={true} />
              <div className="page-body validation-body" >{children}</div>
            </>
          ) : (
            <>
              <NavBar />
              <div className="page-body" style={{ marginLeft: 120 }}>{children}</div>
              <Footer />
            </>
          )}
          {/* <ThemeCustomize></ThemeCustomize> */}
        </div>
      </div>
    </Fragment>
  );
};

export default App;