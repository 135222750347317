import React, { useState } from "react";
import { Input, Button, Label } from "reactstrap";
import styles from "./Password.module.css";

function Password(props) {
  const [pwdInput, setPwdInput] = useState("");
  const [error, setError] = useState(false);

  function handler() {
    setError(false);
    if (pwdInput === props.password) {
      props.unlock();
    } else {
      setError(true);
      return;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <Label className={styles.label}>Password</Label>
        <Input
          className={styles.input}
          type="password"
          onChange={(e) => setPwdInput(e.target.value)}
        />
        <Button className={styles.button} onClick={handler}>
          Submit
        </Button>
      </div>

      {error && (
        <p className={styles.errorMsg}>
          <b>Wrong Password</b>
        </p>
      )}
    </div>
  );
}

export default Password;
