import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import SubscriptionForm from './SubscriptionForm';
import AddBrandForm from './AddBrandForm'; // Importa la form del brand

const AddSubscriptionModal = ({ handleCloseModal, addNewSubscription, addNewBrand, riferimentoTipo, licenseId, clientId, subscriptionType }) => {
    const [modal, setModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showBrandForm, setShowBrandForm] = useState(false); // Stato per gestire la visualizzazione della form del brand

    const toggleModal = () => setModal(!modal);

    const handleSubmitSubscription = async (data) => {
        try {
            await addNewSubscription(data);
            setSuccessMessage('Subscription added successfully!');
            toggleModal();
            setShowBrandForm(true); // Mostra la form del brand dopo aver aggiunto la sottoscrizione
        } catch (error) {
            console.error('Error adding subscription:', error.message);
            alert('Error adding subscription: ' + error.message);
        }
    };

    const handleSubmitBrand = async (brandData) => {
        try {
            await addNewBrand(brandData);
            setSuccessMessage('Brand added successfully!');
            setShowBrandForm(false); // Chiudi la form del brand dopo l'aggiunta
            handleCloseModal(); // Chiudi la modale principale dopo l'aggiunta del brand
        } catch (error) {
            console.error('Error adding brand:', error.message);
            alert('Error adding brand: ' + error.message);
        }
    };

    return (
        <>
            <SubscriptionForm
                addNewSubscription={handleSubmitSubscription}
                riferimentoTipo={riferimentoTipo}
                licenseId={licenseId}
                clientId={clientId}
                subscriptionType={subscriptionType}
            />
        </>
    );
};

export default AddSubscriptionModal;    