import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { db } from '../data/base';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import './OrderPageModule.css';
import HomeCard from '../component/cards/homecard';

export default function OrderPage() {
  const [csvData, setCsvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All');
  const [isCsvLoaded, setIsCsvLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [statusCounts, setStatusCounts] = useState({
    'Disponibile': "0",
    'In Progress': "0",
    'Non Disponibile': "0",
  });
  const [searchSku, setSearchSku] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [activeMode, setActiveMode] = useState('');
  const columnsOfInterest = ['skuCode', 'Name', 'Sku Present?', 'Stato'];

  const searchBySku = async () => {
    if (searchSku.trim() === '') {
      toast.error('Inserisci una SKU.');
      return;
    }

    if (searchSku.trim().length !== 13) {
      toast.error('La SKU deve essere lunga esattamente 13 caratteri.');
      return;
    }

    setLoading(true);
    setActiveMode('search');
    try {
      const skuCode = searchSku.trim().substring(0, 1500);
      const querySnapshot = await db.collection('Modello')
        .where('sku_model', '==', skuCode)
        .get();

      let searchResult = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          const row = {
            skuCode: skuCode,
            'Sku Present?': 'SI',
            'Stato': doc.data().stato || 'Non Disponibile',
            'Name': doc.data().nomeOcchiale || 'Empty',
          };
          searchResult.push(row);
        });
      } else {
        searchResult = [{
          skuCode: skuCode,
          'Sku Present?': 'NO',
          'Stato': 'Non Disponibile',
          'Name': 'Empty',
        }];
      }
      setSearchResults(searchResult);
      setShowSearchResults(true);
    } catch (error) {
      console.error('Errore nella query Firestore:', error);
    }
    setLoading(false);
  };

  const resetState = () => {
    setCsvData([]);
    setFilteredData([]);
    setStatusFilter('All');
    setIsCsvLoaded(false);
    setLoading(false);
    setStatusCounts({
      'Disponibile': "0",
      'In Progress': "0",
      'Non Disponibile': "0",
    });
    setSearchSku('');
    setSearchResults([]);
    setShowSearchResults(false);
    setActiveMode('');
  };

  const fetchAndUpdateData = async (data) => {
    const updatedData = await Promise.all(data.map(async (row) => {
      if (row.skuCode) {
        const skuCode = row.skuCode.substring(0, 1500);
        try {
          const querySnapshot = await db.collection('Modello')
            .where('sku_model', '==', skuCode)
            .get();

          if (!querySnapshot.empty) {
            row['Sku Present?'] = 'SI';
            row['Stato'] = 'Non Disponibile';

            querySnapshot.forEach(doc => {
              row['Stato'] = doc.data().stato || 'Non Disponibile';
              if (row['Stato'] === 'Pubblicato' || row['Stato'] === 'In Pubblicazione') {
                row['Stato'] = 'Disponibile';
              } else {
                row['Stato'] = 'In Progress';
              }
              row['Name'] = doc.data().nomeOcchiale || 'Empty';
            });
          } else {
            row['Sku Present?'] = 'NO';
            row['Stato'] = 'Non Disponibile';
            row['Name'] = 'Empty';
          }
        } catch (error) {
          console.error('Errore nella query Firestore:', error);
          row['Sku Present?'] = 'NO';
          row['Stato'] = 'Non Disponibile';
          row['Name'] = 'Empty';
        }
      } else {
        row['Sku Present?'] = 'NO';
        row['Stato'] = 'Non Disponibile';
        row['Name'] = 'Empty';
      }
      return row;
    }));
    return updatedData;
  };

  const onDrop = useCallback((acceptedFiles) => {
    resetState();
    setShowSearchResults(false);
    setActiveMode('upload');

    if (acceptedFiles.length > 0) {
      toast.success('File CSV caricato con successo!');
      setLoading(true);
      setIsCsvLoaded(true);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('File reading was aborted');
        reader.onerror = () => console.log('File reading has failed');
        reader.onload = async () => {
          const binaryStr = reader.result;
          Papa.parse(binaryStr, {
            header: true,
            complete: async (results) => {
              const rawData = results.data.map(row => {
                const filteredRow = {};
                columnsOfInterest.forEach(column => {
                  if (column === 'Name' && row['nome_modello'] !== undefined) {
                    filteredRow[column] = row['nome_modello'];
                  } else if (row[column] !== undefined) {
                    filteredRow[column] = row[column];
                  } else {
                    if (['Sku Present?', 'Stato'].includes(column)) {
                      filteredRow[column] = '';
                    }
                  }
                });
                return filteredRow;
              });

              const addUniqueData = (data) => {
                const seen = new Set();
                return data.filter(row => {
                  if (seen.has(row.skuCode)) {
                    return false;
                  }
                  seen.add(row.skuCode);
                  return true;
                });
              };

              const uniqueData = addUniqueData(rawData);

              const updatedData = await fetchAndUpdateData(uniqueData);

              const counts = getStatusCounts(updatedData);
              setStatusCounts(counts);

              setCsvData(updatedData);
              setFilteredData(applyFilters(updatedData));
              setLoading(false);
            },
          });
        };

        reader.readAsText(file);
      });
    }
  }, []);

  const sortByStatus = (data) => {
    const statusOrder = {
      'Disponibile': 1,
      'In Progress': 2,
      'Non Disponibile': 3,
      '': 4
    };

    return data.sort((a, b) => {
      return (statusOrder[a['Stato']] || 4) - (statusOrder[b['Stato']] || 4);
    });
  };

  const applyFilters = (data) => {
    const filtered = data.filter(row => {
      const matchesStatusFilter = statusFilter === 'All' || row['Stato'] === statusFilter;
      return matchesStatusFilter;
    });
    return sortByStatus(filtered);
  };

  useEffect(() => {
    if (!showSearchResults) {
      setFilteredData(applyFilters(csvData));
    }
  }, [csvData, statusFilter, showSearchResults]);

  const getStatusCounts = (data) => {
    const counts = {
      'Disponibile': 0,
      'In Progress': 0,
      'Non Disponibile': 0,
    };

    data.forEach(row => {
      if (counts.hasOwnProperty(row['Stato'])) {
        counts[row['Stato']] += 1;
      }
    });

    return counts;
  };

  const handleStatusClick = (status) => {
    setStatusFilter(status === statusFilter ? 'All' : status);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  return (
    <div className={`order-page ${isCsvLoaded ? '' : 'centered'}`}>
      {/* Icona di ricarica */}
      {(activeMode === 'search' || activeMode === 'upload') && (
        <div className="reload-icon" onClick={handleReload}>
          <FaArrowLeft />
        </div>
      )}

      {activeMode !== 'search' && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>For multiple sku search, drag 'n' drop a CSV file here, or click to select a file</p>
        </div>
      )}

      {activeMode !== 'upload' && (
        <div className={`dropzone ${activeMode !== 'upload' ? '' : 'hidden'}`}>
          <p>For a single sku search, enter the sku code here</p>
          <div className="search-input-group">
            <input
              type="text"
              value={searchSku}
              onChange={(e) => setSearchSku(e.target.value)}
              placeholder="Insert SKU"
            />
            <button onClick={searchBySku}>Cerca</button>
          </div>
        </div>
      )}

      {loading && (
        <div className="loading-message">
          <p>Caricamento in corso...</p>
        </div>
      )}

      {isCsvLoaded && !loading && !showSearchResults && activeMode === 'upload' && (
        <div className="status-summary">
          <HomeCard
            leftTitle={csvData.length}
            leftSubtitle={"All Sku"}
            onClickFunction={() => handleStatusClick('All')}
          />
          <HomeCard
            leftTitle={statusCounts['Disponibile'] === 0 ? "0" : statusCounts['Disponibile']}
            leftSubtitle={"Disponibile"}
            onClickFunction={() => handleStatusClick('Disponibile')}
          />
          <HomeCard
            leftTitle={statusCounts['In Progress'] === 0 ? "0" : statusCounts['In Progress']}
            leftSubtitle={"In Progress"}
            onClickFunction={() => handleStatusClick('In Progress')}
          />
          <HomeCard
            leftTitle={statusCounts['Non Disponibile'] === 0 ? "0" : statusCounts['Non Disponibile']}
            leftSubtitle={"Non Disponibile"}
            onClickFunction={() => handleStatusClick('Non Disponibile')}
          />
        </div>
      )}

      {showSearchResults && activeMode === 'search' && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {columnsOfInterest.map((col) => (
                  <th key={col}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {searchResults.map((row, index) => (
                <tr key={index}>
                  {columnsOfInterest.map((col) => (
                    <td key={col}>{row[col] || ''}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!showSearchResults && filteredData.length > 0 && !loading && activeMode === 'upload' && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {columnsOfInterest.map((col) => (
                  <th key={col}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={index}>
                  {columnsOfInterest.map((col) => (
                    <td key={col}>{row[col] || ''}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}