import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineSearch } from "react-icons/ai";

const SearchComponent = ({ placeholder, onHandleSubmit }) => {
  const { register, errors, setValue } = useForm();

  useEffect(() => {
    register({ name: "search" });
  }, [register]);

  const handleChange = (event) => {
    setValue("search", event.target.value);
    onHandleSubmit(event.target.value);
  };

  return (
    <form className="search-form">
      <div className="search-input-container">
        <button type="submit" className="search-btn">
          <AiOutlineSearch className="search-icon" />
        </button>
        <input
          className="search-input"
          type="text"
          placeholder={placeholder}
          name="search"
          onChange={handleChange}
        />
      </div>
      {errors.search && <p className="error-msg">{errors.search.message}</p>}
    </form>
  );
};

export default SearchComponent;
