import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import replayIco from "../../assets/images/replayIco.png";
import ButtonAr from "./ButtonAr";
import BackDropTransparent from "./BackDropTransparent";
import styles from "./ProjectModelView.module.css";
import "../../index.css";
import animationLottie from "../../assets/lottie/THBTOEkYcN.json";
import Lottie from "react-lottie-player";
import hdr from "../../assets/hdr/brown_photostudio_04_1k.hdr";
import { isMobile } from "react-device-detect";
import { getHdr, hdrMap } from './HDRConstant.js';  // Aggiusta il percorso del file se necessario
import { useLocation } from 'react-router-dom';

function ProjectModelViewer(props) {
  const project = useSelector((state) => state.brandViewer);
  // const model = useSelector((state) => state.glassesModel);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const mirror = queryParams?.get('mirror');
  const selected = props.initialModel;
  const isEditModeOn = props.isEditModeOn

  const [isWebView, setIsWebView] = useState(false);

  const modelViewerRef = useRef();

  useEffect(() => {
    // console.log("PROJECT",project)
  })

  // ERRORE IN CONSOLE
  // async function loadModel(url) {
  //   setUrl(modelInView?.urlGlbComplete);

  //   if(isEditModeOn){
  //     return;
  //   }
  //   const response = await fetch(url);
  //   const blob = await response.blob();
  //   const urlBlob = URL.createObjectURL(blob);
  //   const modelViewer = modelViewerRef.current;
  //   modelViewer.src = urlBlob;
  //   // console.log("prima",project[0]?.cameraOrbit);

  //   function handleLoad() {
  //     // modelViewer.cameraOrbit = isWebView? "20.26deg 87.4deg 0.1959m" : project[0]?.cameraOrbit;
  //     // console.log(project[0]?.cameraOrbit);
  //     // rimuovere l'event listener dopo che è stato gestito
  //     modelViewer.removeEventListener('load', handleLoad);
  //   }

  //   modelViewer.addEventListener('load', handleLoad);


  // }

  // FIX ERRORE IN CONSOLE
  async function loadModel(url) {
    try {
      // Verifica che l'URL non sia vuoto, ma senza loggare errori se non è fornito
      if (!url) {
        return; // Esci dalla funzione senza fare nulla
      }

      const response = await fetch(url);

      // Controlla se la risposta è un errore 404 o simile
      if (!response.ok) {
        console.error(`Errore nel caricamento del modello. Status: ${response.status}`);
        const errorText = await response.text();
        console.error('Corpo della risposta:', errorText);
        return;
      }

      // Controlla il tipo di contenuto
      const contentType = response.headers.get('Content-Type');
      if (!contentType || (!contentType.includes('model/gltf-binary') && !contentType.includes('application/octet-stream'))) {
        // Se il contenuto è HTML, ignora e non loggare errori specifici
        if (contentType.includes('text/html')) {
          console.warn('Contenuto HTML ricevuto invece di un modello');
          return;
        }

        console.error('Tipo di contenuto inaspettato:', contentType);
        const responseText = await response.text();
        console.error('Corpo della risposta:', responseText);
        return;
      }

      const blob = await response.blob();
      const urlBlob = URL.createObjectURL(blob);
      const modelViewer = modelViewerRef.current;
      if (modelViewer) {
        modelViewer.src = urlBlob;
      }
    } catch (error) {
      console.error('Errore nel caricamento del modello:', error);
    }
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isWebView = /\bwv\b/.test(userAgent);
    // console.log("MIRROR", mirror)
    if (isWebView || mirror) {
      setIsWebView(true);
    }
  });

  const modelInView = useSelector((state) =>
    state.modelGlasses.find((model) => model.id === selected)
  );

  const [positionMenu, setPositionMenu] = useState("bottom");
  useEffect(() => {
    // if (window.innerWidth <= 700) {
    //   setPositionMenu("bottom");
    // } else {
    setPositionMenu(project[0]?.menu_position);
    // }
  }, []);

  // const [modalQR, setModalQR] = useState(false);

  const [url, setUrl] = useState("");

  const [fieldOfView, setFieldOfView] = useState(
    project[0]?.fieldOfView ?? "22deg"
  );

  const urlVista = props.urlView;

  // console.log('vista' , urlVista);
  useEffect(() => {
    if (window.innerWidth <= 700) {
      setFieldOfView("32deg");
    }
  }, []);

  useEffect(() => {

    // async function loadModel(url) {
    //   setUrl(modelInView?.urlGlbComplete);

    //   if(isEditModeOn){
    //     console.log("ON EDIT MODAL")
    //     return;
    //   }
    //   const response = await fetch(url);
    //   const blob = await response.blob();
    //   const urlBlob = URL.createObjectURL(blob);
    //   const modelViewer = modelViewerRef.current;
    //   modelViewer.src = urlBlob;

    //   function handleLoad() {
    //     modelViewer.cameraOrbit = isWebView ? "20.26deg 87.4deg 0.1959m" : project[0]?.cameraOrbit;
    //     console.log(project[0]?.cameraOrbit);
    //     modelViewer.removeEventListener('load', handleLoad);
    //   }

    //   modelViewer.addEventListener('load', handleLoad);
    // }

    if (!props.singleView) {
      if (modelInView?.urlGlbComplete) {

        loadModel(modelInView?.urlGlbComplete)

        // loadModel(modelInView?.urlGlbComplete)
        // setUrl(modelInView?.urlGlbComplete);
        // console.log(modelInView?.urlGlbComplete);
      }
    }


    if (project && project[0]) {
      loadModel(project[0].url);
    }
  }, [project]);


  useEffect(() => {
    if (props.singleView) {
      loadModel(props.singleSrc)
      setUrl(props.singleSrc);
    }
  }, [props.singleSrc]);


  const hdrNum = project[0]?.hdr;
  // console.log("hdrNum",hdrNum);
  const hdrPath = getHdr(hdrNum);

  const MODEL_INSTANCE = {
    src: isEditModeOn ? url : "",
    alt: project[0]?.alt,
    cameraControls: isWebView ? true : project[0]?.cameraControls,
    autoRotate: isWebView ? false : project[0]?.autoRotate,
    disableZoom: isWebView ? false : project[0]?.disableZoom,
    lightExposition: project[0]?.lightExposition,
    shadowSmooth: project[0]?.shadowSmooth,
    shadowIntensity: project[0]?.shadowIntensity,
    poster: modelInView?.poster,
    ios: modelInView?.ios,
    hdr: hdrPath,
    // ar: project[0]?.ar,
    ar: "true",
    background_image: project[0]?.background_image,
    skybox: project[0]?.transparent ? "" : project[0]?.skybox,
    fieldOfView: project[0]?.fieldOfView,
    backgroundColor: (!project[0]?.transparent && !isWebView)
      ? project[0]?.background_color ?? "#ffffff00"
      : "#ffffff00",
    background_boolean_image: isWebView ? false :
      project[0]?.background_boolean_image === "true" ? true : false,
    title: project[0]?.modelTitle ?? false,
    exposure: project[0]?.lightExposition ?? "1.0",
    ref: modelViewerRef,
    cameraOrbit: project[0]?.cameraOrbit ?? ""
  };



  function castValues(value, type) {
    // console.log("VALUE CAST", value, type)
    return !value ? null : value;
  }

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      :root {
        --poster-color: rgba(255, 255, 255, 0);
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   function onProgress(e) {
  //     setProgress(Math.ceil(e.detail.totalProgress * 100));
  //   }
  //   document
  //     .querySelector("model-viewer")
  //     .addEventListener("progress", onProgress);
  // }, [document.querySelector("model-viewer")]);

  /// LOGICA PER IL CARICAMENTO ///
  useEffect(() => {
    function onProgress(e) {
      setProgress(Math.ceil(e.detail.totalProgress * 100));
    }

    function onLoad() {
      setIsLoaded(true);
    }

    const modelViewer = document.querySelector("model-viewer");
    modelViewer.addEventListener("progress", onProgress);
    modelViewer.addEventListener("load", onLoad);

    return () => {
      modelViewer.removeEventListener("progress", onProgress);
      modelViewer.removeEventListener("load", onLoad);
    };
  }, []);

  /// SERVE PER VISUALIZZARE OCCHIALE IN BASE AI 3 DATI /// 
  useEffect(() => {
    const modelViewer = modelViewerRef.current;
  
    const handleLoad = () => {
      if (modelViewer) {
        // Imposta cameraOrbit solo se è definito
        modelViewer.cameraOrbit = project[0]?.cameraOrbit || "auto auto 120deg";
        modelViewer.cameraControls = true;
      }
    };
  
    if (modelViewer) {
      modelViewer.addEventListener('load', handleLoad);
  
      return () => modelViewer.removeEventListener('load', handleLoad);
    }
  }, [project[0]?.cameraOrbit]);

  return (
    /// Mostra la scritta "Loading..." solo se il caricamento non è completato ///
    <>
      {progress < 100 && !isLoaded && (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <div className={styles.message}>Loading...</div>
        </div>
      )}

      {progress < 100 && <BackDropTransparent white />}
      {window.innerWidth < 700 && (
        <model-viewer
          src={isEditModeOn ? MODEL_INSTANCE.src : ""}
          disable-tap
          disable-pan
          // src={MODEL_INSTANCE.src}
          alt={MODEL_INSTANCE.alt}
          ref={MODEL_INSTANCE.ref}
          camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-control")}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
          // exposure={MODEL_INSTANCE.lightExposition}
          exposure={MODEL_INSTANCE.exposure}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-softness={MODEL_INSTANCE.shadowSmooth}
          // camera-orbit={isEditModeOn ? MODEL_INSTANCE.cameraOrbit: ""}
          field-of-view={MODEL_INSTANCE.fieldOfView}
          min-camera-orbit='auto auto 200%'
          max-camera-orbit='auto auto 200%'
          min-field-of-view='24deg'
          max-field-of-view='30deg'
          // poster={MODEL_INSTANCE.poster}
          ar={isWebView}
          ar-modes={"webxr quick-look scene-viewer"}
          // animation-crossfade-duration="0"
          //  environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : undefined}
          environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}

          //  xr-environment
          skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
          allow="xr-spatial-tracking"
          progress
          style={
            isMobile && !props.singleView
              ? {
                height: "83%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundPosition: 'bottom center',
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",
                backgroundSize: 'cover',
                backgroundPosition: 'bottom center',
              }
              : {
                height: "100%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundPosition: 'bottom center',
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",

              }
          }
          id={
            props?.project?.menu_position === "left" ||
              props?.project?.menu_position === "right"
              ? "projectTwo"
              : "project"
          }
        >
          {/* <button slot="ar-button" style={{with:"20px",padding:"10px",color:"white", backgroundColor:"black", borderRadius:"4px", border:"none", bottom:"10px", left:"calc(50% - 30px)",position:"absolute"}}>
          AR
         </button> */}

          {/* {  !iframe && (
          <button className={styles["return-on-website"]}> Ritorna al sito Web</button>
        )
} */}
          <div id="progress-bar-id" slot="progress-bar"></div>
          <div id="lazy-load-poster" slot="poster"></div>

          {/* {progress < 100 && <p className={styles.progress}>{progress}</p>} */}

          {props.multiViewer && MODEL_INSTANCE.title && (
            <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
          )}
          {/* {props.singleView && (
           <button className={styles.replayBtn} disabled={lock} onClick={replay}>
             <img src={replayIco} style={{ width: "25px", height: "25px" }} />
           </button>
         )} */}
          {/* {props.viewerPage && arBtn && (
           <ButtonAr onClick={modalHandler} isModal={true} project={project} />
         )}
         {false && <ButtonAr onClick={modalHandler} isModal={false} />} */}

          <ButtonAr project={project} urlView={urlVista} />

        </model-viewer>
      )}
      {window.innerWidth > 700 && (


        <model-viewer
          disable-tap
          src={isEditModeOn ? MODEL_INSTANCE.src : ""}
          // src={url}
          disable-pan
          alt={MODEL_INSTANCE.alt}
          ref={MODEL_INSTANCE.ref}
          camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-controls")}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
          // exposure={MODEL_INSTANCE.lightExposition}
          exposure={MODEL_INSTANCE.exposure}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-softness={MODEL_INSTANCE.shadowSmooth}
          camera-orbit={isEditModeOn ? MODEL_INSTANCE.cameraOrbit : ""}
          field-of-view={MODEL_INSTANCE.fieldOfView}
          min-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
          max-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
          min-field-of-view='6deg'
          max-field-of-view='32deg'
          // poster={MODEL_INSTANCE.poster}
          ar={isWebView}
          ar-modes={"webxr quick-look scene-viewer"}
          // animation-crossfade-duration="0"
          environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}
          // environment-image="../../assets/hdr/brown_photostudio_04_1k.hdr"
          xr-environment
          skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
          allow="xr-spatial-tracking"
          progress
          style={
            props.singleView ||
              props?.project?.menu_position === "left" ||
              props?.project?.menu_position === "right"
              ? {
                height: "100%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",

              }
              : {
                height: "85%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",

              }
          }
          id={
            props?.project?.menu_position === "left" ||
              props?.project?.menu_position === "right"
              ? "projectTwo"
              : "project"
          }
        >
          {/* <button slot="ar-button" style={{with:"20px",padding:"10px",color:"white", backgroundColor:"black", borderRadius:"4px", border:"none", bottom:"10px", left:"calc(50% - 30px)",position:"absolute"}}>
         AR
        </button> */}

          <div id="progress-bar-id" slot="progress-bar"></div>

          {/* {progress < 100 && <p className={styles.progress}>{progress}</p>} */}

          {props.multiViewer && MODEL_INSTANCE.title && (
            <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
          )}
          {/* {props.singleView && (
          <button className={styles.replayBtn} disabled={lock} onClick={replay}>
            <img src={replayIco} style={{ width: "25px", height: "25px" }} />
          </button>
        )} */}
          {/* {props.viewerPage && arBtn && (
          <ButtonAr onClick={modalHandler} isModal={true} project={project} />
        )}
        {false && <ButtonAr onClick={modalHandler} isModal={false} />} */}

          <ButtonAr project={project} urlView={urlVista} />


        </model-viewer>


      )}
    </>
  );
}

export default ProjectModelViewer;