// URL STAGING
export const BASE_URL_STAGING = "https://stagingdashboard.arshades.it";

// URL SPAARKLY
export const BASE_URL_SPAARKLY = "https://arshades.spaarkly.it";

// URL STUDIO
export const BASE_URL_STUDIO = "https://studio.arshades.it";


/// URL PRINCIPALE ///
export const ACTIVE_BASE_URL = BASE_URL_SPAARKLY;
/////////////////////