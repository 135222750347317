import React, { useEffect, useReducer, useState } from 'react';
import { db } from '../../data/base';
import {
    Button,
    Spinner,
    FormGroup,
    Collapse,
    Card,
    CardBody,
} from 'reactstrap';
import 'firebase/firestore';
import { aggregateDataForAllBrands, aggregateDataForBrand, endDate, startDate } from '../../component/general/dashboard/analyticsAggrgator/Helper';
import { format } from 'date-fns';
import { toast } from "react-toastify";
import styles from './AdminStyling.module.css';
import { initial } from 'lodash';

const initialState = {
    taglia: { loading: false, msg: "", list: {} },
    glass: { loading: false, msg: "", list: {} },
    model: { loading: false, msg: "", list: {} },
    sizeIsOrphan: { loading: false, msg: "", list: [] },

}
function reducer(state, action) {
    switch (action.type) {
        case 'SIZ': //for size testing
            return { ...state, taglia: { ...state.taglia, ...action.value } };
        case 'GLA': ///for glasses
            return { ...state, glass: { ...state.glass, ...action.value } };
        case 'MOD': //for models
            state.model = { ...state.model, ...action.value };
            return { ...state };
        case 'SIO': //for sizes that are orphans
            return { ...state, sizeIsOrphan: { ...state.sizeIsOrphan, ...action.value } };
        //   case 'SET_DUPLICATE_MODELLO':
        //     return { ...state, duplicateModello: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
const DbTestComponent = () => {
    const [isOpen, setOpen] = useState(false);
    const [executing, setExecute] = useState(false);
    const [executing2, setExecute2] = useState(false);
    const [executing3, setExecute3] = useState(false);
    const [clickedIndex, setClickedIndex] = useState(100)
    const [msg, setMsg] = useState("");
    const [msg2, setMsg2] = useState("");
    const [msg3, setMsg3] = useState("");
    const [duplicateList, setDuplicate] = useState([])
    const [duplicateSize, setDuplicateSize] = useState([])
    const [duplicateModello, setDuplicateModello] = useState([])
    const [state, dispatch] = useReducer(reducer, initialState)
    const testOcchiale = async () => {
        dispatch({ type: 'GLA', value: { loading: true } })
        const occhiales = await db.collection("Occhiale").get();
        const allList = [];
        const duplicates = []
        //identify the duplicates
        for (let i = 0; i < occhiales.size; i++) {
            const glass = occhiales.docs[i].data();
            glass.id = occhiales.docs[i].id;
            dispatch({ type: 'GLA', value: { msg: `${i} of ${allList.length} processing` } })
            //if a duplciate is found, save the duplicate for reference
            const findDuplicate = allList.find((el) => el.codiceOcchiale == glass.codiceOcchiale);

            if (findDuplicate != undefined && findDuplicate != -1) {
                if (duplicates.find((el) => el.codiceOcchiale == findDuplicate.codiceOcchiale) == undefined) duplicates.push(findDuplicate);
                duplicates.push(glass);
            } else {
                allList.push(glass);
            }
        }
        if (duplicates.length == 0) toast.success("Test passed")
        else toast.error("Test failed! Duplicate codes detected");
        dispatch({ type: 'GLA', value: { list: groupByCode(duplicates, 'codiceOcchiale'), loading: false, msg: `${duplicates.length} size duplicate found` } });

    }
    const testTaglia = async () => {
        try {
            dispatch({ type: 'SIZ', value: { loading: true } })
            const taglias = await db.collection("Taglia").get();
            const allList = [];
            const duplicates = []
            //identify the duplicates
            for (let i = 0; i < taglias.size; i++) {
                const taglia = taglias.docs[i].data();
                taglia.id = taglias.docs[i].id;
                dispatch({ type: 'SIZ', value: { msg: `${i} of ${allList.length} processing` } })
                //if a duplciate is found, save the duplicate for reference
                const findDuplicate = allList.find((el) => el.codiceTaglia == taglia.codiceTaglia);

                if (findDuplicate != undefined && findDuplicate != -1) {
                    if (duplicates.find((el) => el.codiceTaglia == findDuplicate.codiceTaglia) == undefined) duplicates.push(findDuplicate);
                    duplicates.push(taglia);
                } else {
                    allList.push(taglia);
                }
            }

            if (duplicates.length == 0) toast.success("Test passed")
            else toast.error("Test Failed! Duplicate codes detected");

            dispatch({ type: 'SIZ', value: { list: groupByCode(duplicates, 'codiceTaglia'), loading: false, msg: `${duplicates.length} size duplicate found` } });

        } catch (e) {
            console.log(e);
        }
    }
    const testModello = async () => {
        try {
            dispatch({ type: 'MOD', value: { loading: true } })
            const modellos = await db.collection("Modello").get();
            const allList = [];
            const duplicates = []
            //identify the duplicates
            for (let i = 0; i < modellos.size; i++) {
                const modello = modellos.docs[i].data();
                modello.id = modellos.docs[i].id;
                dispatch({ type: 'MOD', value: { msg: `${i} of ${allList.length} processing` } })
                const findDuplicate = allList.find((el) => el.codiceVariante == modello.codiceVariante);

                //if a duplciate is found, save the duplicate for reference
                if (findDuplicate != undefined && findDuplicate != -1) {
                    if (duplicates.find((el) => el.codiceVariante == findDuplicate.codiceVariante) == undefined) duplicates.push(findDuplicate);
                    duplicates.push(modello);
                } else {
                    allList.push(modello);
                }
            }
            if (duplicates.length == 0) toast.success("Test passed")
            else toast.error("Test Failed! Duplicate codes detected");
            dispatch({ type: 'MOD', value: { msg: `${duplicates.length} variant duplicate found`, list: groupByCode(duplicates, 'codiceVariante'), loading: false } });
        } catch (e) {
            console.log(e);
            dispatch({ type: 'MOD', value: { loading: false } })
        }
    }


    const isTagliaOrphan = async () => {
        try{
        dispatch({ type: 'SIO', value: { loading: true } })
        const sizeArray = await db.collection("Taglia").get();
        const glassArray = await db.collection("Occhiale").get();
        const referencedSet = new Set();
        glassArray.forEach((glass) => {
            glass.data().lista_taglie.forEach((taglia) => {
                referencedSet.add(taglia.id);
            })
        })

        const unReferencedList = sizeArray.docs.filter((size) => !referencedSet.has(size.id));
        //identify the duplicates
        dispatch({ type: 'SIO', value: { msg: `${unReferencedList.length} unreferenced size  found`, list: unReferencedList, loading: false }, });
        if (unReferencedList.length == 0) toast.success("Test passed")
        else toast.error("Test Failed! Unreferenced sizes detected");
    }catch(e){
        console.log(e);
    }
    }

    const openCloseContents = (index) => {
        if (index != clickedIndex) return setClickedIndex(index)
        setOpen(!isOpen);
    }

    const groupByCode = (list = [], category = 'codiceVariant') => {
        const newObject = list.reduce((acc, item) => {
            const itemCode = item[category];
            if (!acc[itemCode]) {
                acc[itemCode] = []
            }
            acc[itemCode].push(item);
            return acc;
        }, {})

        return newObject;
    }

    return (<>

        <div className="client-list-container ">
            <div className="chart-header">
                <p className="chart-header-text">Database Test</p>
            </div>


            <div className="p-10">
                <div className={`row p-3  ${styles.row}`} onMouseEnter={() => { }} onClick={() => openCloseContents(0)}>
                    <div className="col text-strong h5" data-toggle="tooltip" data-placement="top" title="Ensures Each Brands is either a main brand or not">
                        Test Glass Code
                    </div>
                    <div className='col'>
                        {state.glass.msg}
                    </div>
                    <div className="col">
                        <Button
                            onClick={() => testOcchiale()}
                            disabled={state.glass.loading}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                        >
                            {state.glass.loading && <Spinner size="sm" />}
                            Test
                        </Button>
                    </div>
                </div>
                <Collapse isOpen={isOpen && clickedIndex == 0} >
                    <Card>
                        <CardBody>
                            {Object.keys(state.glass.list).map((keyId) => (
                                <div className="row p-3" key={keyId}>
                                    <div className='col-2 text-strong '>
                                        {keyId}:
                                    </div>
                                    <div className='col'>
                                        {state.glass.list[keyId].map(element =>
                                        (
                                            <div className="row" key={element.id}>
                                                <div className="col text-strong h5" data-toggle="tooltip" data-placement="top">
                                                    {element != null ? element.nome_modello : ''}
                                                </div>
                                                <div className="col">
                                                    {element != null ? element.id : ''}
                                                </div> </div>)
                                        )}
                                    </div>
                                </div>

                            )
                            )}
                        </CardBody>
                    </Card>
                </Collapse>

                <div className={`row p-3  ${styles.row}`} onClick={() => openCloseContents(1)}>
                    <div className="col h5" data-toggle="tooltip" data-placement="top" title="Test if duplicate size code exists">
                        Test Size Code
                    </div>
                    <div className='col'>
                        {state.taglia.msg}
                    </div>
                    <div className="col">
                        <Button
                            onClick={() => testTaglia()}
                            disabled={state.taglia.loading}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                        >
                            {state.taglia.loading && <Spinner size="sm" />}
                            Test
                        </Button>
                    </div>
                </div>
                <Collapse isOpen={isOpen && clickedIndex == 1} >
                    <Card>
                        <CardBody>
                            {Object.keys(state.taglia.list).map((keyId) => (
                                <div className="row p-3" key={keyId}>
                                    <div className='col-2 text-strong '>
                                        {keyId}:
                                    </div>
                                    <div className='col'>
                                        {state.taglia.list[keyId].map(element =>
                                        (
                                            <div className="row" key={element.id}>
                                                <div className="col text-strong h5" data-toggle="tooltip" data-placement="top">
                                                    {element != null ? element.size : ''}
                                                </div>
                                                <div className="col">
                                                    {element != null ? element.id : ''}
                                                </div> </div>)
                                        )}
                                    </div>
                                </div>

                            )
                            )}
                        </CardBody>
                    </Card>
                </Collapse>

                <div className={`row p-3  ${styles.row}`} onClick={() => openCloseContents(2)}>
                    <div className='col h5' data-toggle="tooltip" data-placement="top" title='Test if duplicate variant code exists'>Test Variants</div>
                    <div className='col'>
                        {state.model.msg}
                    </div>
                    <div className='col'>
                        <Button
                            onClick={() => testModello()}
                            disabled={state.model.loading}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "8px",
                            }}
                        >
                            {state.model.loading ? <Spinner size="sm" /> : null}
                            Test
                        </Button>
                    </div>
                </div>
                <Collapse isOpen={isOpen && clickedIndex == 2} >
                    <Card>
                        <CardBody>

                            {Object.keys(state.model.list).map((keyId) => (
                                <div className="row p-3" key={keyId}>
                                    <div className='col-2 text-strong '>
                                        {keyId}:
                                    </div>
                                    <div className='col'>
                                        {state.model.list[keyId].map(element =>
                                        (
                                            <div className="row" key={element.id}>
                                                <div className="col text-strong h5" data-toggle="tooltip" data-placement="top">
                                                    {element != null ? element.nomeOcchiale : ''}
                                                </div>
                                                <div className="col">
                                                    {element != null ? element.id : ''}
                                                </div> </div>)
                                        )}
                                    </div>
                                </div>

                            )
                            )}
                        </CardBody>
                    </Card>
                </Collapse>

                <div className={`row p-3  ${styles.row}`} onClick={() => openCloseContents(3)}>
                    <div className='col h5' data-toggle="tooltip" data-placement="top" title='Test if unreferenced size exists'>Check Variant Reference</div>
                    <div className='col'>
                        {state.sizeIsOrphan.msg}
                    </div>
                    <div className='col'>
                        <Button
                            onClick={() => isTagliaOrphan()}
                            disabled={state.sizeIsOrphan.loading}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "8px",
                            }}
                        >
                            {state.sizeIsOrphan.loading ? <Spinner size="sm" /> : null}
                            Test
                        </Button>
                    </div>
                </div>
                <Collapse isOpen={isOpen && clickedIndex == 3} >
                    <Card>
                        <CardBody>
                            {state.sizeIsOrphan.list.map((element,keyId) => (

                                <div className="row p-3" key={keyId}>
                                    <div className='col-2 text-strong '>
                                        {keyId}:
                                    </div>

                                    <div className="col text-strong h5" data-toggle="tooltip" data-placement="top">
                                        {element != null ? element.codiceTaglia ?? element.size ?? 'No Data': 'NO DATA'}
                                    </div>
                                    <div className="col">
                                        {element != null ? element.id : ''}
                                    </div>

                                </div>

                            )
                            )}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        </div>
    </>)
}


export default DbTestComponent;