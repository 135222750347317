import React, { useEffect, useState } from "react";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";

import { db } from "../../../data/base";
import { setSelectedBrand } from "../../../redux/brand/action";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

const BrandSelector = ({ pathname }) => {
  const [brandList, setBrandList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const dispatch = useDispatch();
  const { selectedBrand } = useSelector(state => state);

  const rxBrands = useSelector(state => state.config.listaBrand);
  const location = useLocation();
  const [displayNone, setDisplayNone] = useState(false)
  // useEffect(() => {
  //   // Esegui le azioni desiderate in base alla pagina corrente
  //   // console.log('Pagina corrente:', location.pathname);
  //   // if(location.pathname === "/dataConsumption"){
  //   //   setDisplayNone(true)
  //   // }else {
  //   //   setDisplayNone(false)
  //   // }
  //   // Puoi anche fare altre operazioni come inviare dati al server, aggiornare lo stato, ecc.
  // }, [location]);
  
  const handleBrandChange = e => {
    dispatch(
      setSelectedBrand(brandList.find(brand => brand.id === e.target.value)),
    );
  };
  const getBrands = async () => {
    try {
      setIsLoad(false);
      
      const result = await Promise.all(
        rxBrands.map(async brand =>
          db
            .collection("Brand")
            .doc(brand)
            .get()
            .then(data => {
                return { id: data.id, ...data.data() };
            }),
        ),
      );
      
      setBrandList([{ id: "all", nome_brand: "All Brand" }, ...result]);
    } catch {
      toast.error("Something went wrong while fetching brands");
    } finally {
      setIsLoad(true);
    }
  };

  useEffect(() => {
    getBrands();
  }, [rxBrands]);

  useEffect(() => {
    handleBrandChange({ target: { value: "all" } });
  }, [brandList?.length]);

  return (
    <>
    {!displayNone && (
       <Input
       className="select-input-container"
       type="select"
       placeholder="Select a brand"
       value={selectedBrand.id}
       onChange={handleBrandChange}
       disabled={!isLoad}
     >
       <option value="">Select a brand</option>
       {brandList.map(brand => (
         <option key={brand.id} value={brand.id}>
           {brand.nome_brand}
         </option>
       ))}
     </Input>
    )}
    {displayNone && (
 <Input
 className="select-input-container"
 type="select"
 placeholder="Select a brand test"
 value="all"
 onChange={handleBrandChange}
 disabled={!isLoad}
>
<option value="All">All Brand</option>

</Input>
    )}
   
    </>
  );
};

export default BrandSelector;
