import React from "react";
import { Line } from "react-chartjs-2";

const SummaryGraphs = ({ data }) => {
  if (!data) {
    return (
      <div
        style={{
          border: "1px solid",
          padding: "1rem",
        }}
      >
        <div>No Data Found</div>
      </div>
    );
  }
  return (
    <Line
      options={{
        legend: {
          display: false,
        },
      }}
      data={data}
      height={73}
    />
  );
};

export default SummaryGraphs;
