// import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Input, Table } from "reactstrap";
import { ActionButtons } from "../../common/ActionButtons";
import { toast } from "react-toastify";
import app, { db } from "../../../data/base";
import { Accordion } from '../../cards/Accordion';

export const AssociatedBrands = ({ userInfo }) => {
  const [loading, setLoading] = useState(false);
  const [associatedBrands, setAssociatedBrands] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [editRow, setEditRow] = useState(-1);
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const getData = async () => {
    try {
      fetchSubs();
      //     app.auth().onAuthStateChanged(async (user) => {
      //       const accountRef = await db
      //         .collection("Profile")
      //         .doc(user?.email)
      //         .get();


      //  const lista_brand = accountRef.data()?.lista_brand;

      //       const res = await db.collection("Brand").get();
      //       let listBrandMenoRev = [];
      //       const brandInfo = res.docs.map((doc) => {
      //         const temp = doc.data();
      //           if(!doc.data().nome_brand.includes('REV')){
      //             listBrandMenoRev.push({id:doc.id, ...temp})
      //           }
      //           return {
      //             id: doc.id,
      //             ...temp,
      //           };

      //       });

      //       const brandsData = listBrandMenoRev.filter((brand) =>
      //         lista_brand?.includes(brand?.id)
      //       );
      //       setAssociatedBrands(brandsData);
      //     });
    } catch {
      // toast.error("Error while fetching data!");
    }
  };

  const fetchSubs = async () => {
    const refClient = localStorage.getItem('ref_cliente');
    const refEmail = localStorage.getItem('email');
    const profileCatalogsRef = localStorage.getItem('brands');

    try {
      const profileRef = await db.collection("Profile").doc(refEmail.toLowerCase()).get();
      const profileData = profileRef.data();
      let subsSnapshot = null;
      // console.log("ref", refEmail, refClient);
      if (profileData.role === "Admin") {
        subsSnapshot = await db.collection("ARShades_Subscription")
          .where("isRefactored", "==", true)
          .get();
      } else {
        subsSnapshot = await db.collection("ARShades_Subscription")
          .where("isRefactored", "==", true)
          .where("refClient", "==", refClient)
          .get();
      }
    
      const clientRef = await db.collection("Client").doc(refClient).get();
      const clientData = clientRef.data();
      const subs = subsSnapshot.docs.map(doc => {
        const data = doc.data();
    
        //  console.log("Dati del documento:", clientData.mainProfileList, refEmail);
    
        const found = clientData.mainProfileList.includes(refEmail.toLowerCase());
    
        if (found) {
          return {
            id: doc.id,
            subscriptionType: data.subscriptionType || "N/A",
            startDate: data.startDate || null,
            endDate: data.endDate || null,
            store: data.store || null,
            status: data.status || "Attivo"
          };
        } else {
          // Verifica se catalogList e catalogRefList esistono e non sono vuoti
          const hasMatchingCatalog = data.catalogList && data.catalogList.catalogRefList && data.catalogList.catalogRefList.length > 0 && 
            data.catalogList.catalogRefList.some(catalogRef => profileCatalogsRef.includes(catalogRef));
    
          if (hasMatchingCatalog) {
            return {
              id: doc.id,
              subscriptionType: data.subscriptionType || "N/A",
              startDate: data.startDate || null,
              endDate: data.endDate || null,
              store: data.store || null,
              status: data.status || "Attivo"
            };
          } else {
            return null; // Altrimenti ritorna null
          }
        }
      }).filter(sub => sub !== null);
    
      setSubscriptions(subs);
      // console.log("Abbonamenti filtrati:", subs);
    } catch (error) {
      console.error("Errore durante il recupero degli abbonamenti:", error);
    }
    
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteRow = async ({ id }) => {
    // try {
    //   setLoading(true);
    //   const docRef = await db.collection("Brand").doc(id);
    //   await docRef.delete();

    //   const filtered = associatedBrands?.filter((item) => item.id !== id);
    //   setAssociatedBrands(filtered);

    //   toast.success("Successfully deleted!");
    // } catch {
    //   toast.error("Error while deleting!");
    // } finally {
    //   setLoading(false);
    // }
  };

  const onEdit = async () => {
    try {
      setLoading(true);
      const rowIndex = associatedBrands?.findIndex(
        (row) => row?.id === editRow
      );
      const temp = [...associatedBrands];
      temp[rowIndex] = editedData;

      const isEdited = Object.keys(associatedBrands[rowIndex]).some(
        (key) => associatedBrands[rowIndex][key] !== editedData[key]
      );
      if (isEdited) {
        const docRef = await db.collection("Brand").doc(editedData?.id);
        await docRef.update(editedData);

        setAssociatedBrands(temp);
        toast.success("Updated Successfully!");
      }
      setEditRow(-1);
      setEditedData({});
    } catch {
      toast.error("Error while updating!");
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = userInfo?.role === "Admin";

  // console.log("Subscriptions", subscriptions);

  return (
    <>
      <div className="shadow-sm m-t-20">
        <div
          className="p-20 rounded text-white"
          style={{ backgroundColor: "#42B1AC" }}
        >
          <h5>Licence</h5>
        </div>
      </div>

      <Container className="p-20">
        <Table>
          <thead>
            <tr>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Name</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Start Date</th>
              <th style={{ backgroundColor: 'white', color: 'black' }} >End Date</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Store</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Status</th>
              {/* {isAdmin ? <th>Action</th> : null} */}
            </tr>
          </thead>
          <tbody>

            {subscriptions.map((subscription, index) => (
              <Accordion key={index} subscription={subscription} />
            ))}

            {/* {!associatedBrands?.length ? (
              <tr>
                <td>No Results</td>
              </tr>
            ) : null}



            {associatedBrands?.map((item) => {
              const isRowEditing = item?.id === editRow;
              return (
                <tr key={item?.id}>
                  <td>{item?.nome_brand}</td>

                  <td>
                    {isRowEditing ? (
                      <Input
                        type="date"
                        value={moment(editedData?.startDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          const temp = { ...editedData };
                          temp.startDate = e.target.value;
                          setEditedData(temp);
                        }}
                        disabled={loading}
                      />
                    ) : item?.startDate ? (
                      moment(item.startDate).format("DD/MM/YYYY")
                    ) : (
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    {isRowEditing ? (
                      <Input
                        type="date"
                        value={moment(editedData?.endDate).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          const temp = { ...editedData };
                          temp.endDate = e.target.value;
                          setEditedData(temp);
                        }}
                        disabled={loading}
                      />
                    ) : item?.endDate ? (
                      moment(item.endDate).format("DD/MM/YYYY")
                    ) : (
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    {isAdmin ? (
                      <ActionButtons
                        disabled={loading}
                        isEditing={isRowEditing}
                        onClickEdit={() => {
                          setEditedData(item);
                          setEditRow(item?.id);
                        }}
                        onClickDelete={() => deleteRow({ id: item.id })}
                        onCancelEdit={() => setEditRow(-1)}
                        onSubmit={onEdit}
                      />
                    ) : null}
                  </td>
                </tr>
               
              );
            })} */}
          </tbody>
        </Table>
      </Container>
    </>
  );
};