import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const brandViewer = createSlice({
  name: "brandViewer",
  initialState,
  reducers: {
    setSelectedProject(state, action) {
      const value = action.payload;
      const newProject = { ...value };
      state[0] = newProject;
    },
    updateFirstIndexProject(state, action) {
      return action.payload;
    },
  },
});

export const brandViewerActions = brandViewer.actions;

export default brandViewer.reducer;
