import React from "react";
import { Row } from "reactstrap";

import { MdCheck, MdClose, MdDelete, MdModeEdit } from "react-icons/md";

export const ActionButtons = ({
  disabled,
  isEditing,
  onCancelEdit,
  onSubmit,

  onClickEdit,
  onClickDelete,
  hideDelete,
}) => {
  if (isEditing) {
    return (
      <Row>
        <MdCheck
          size={24}
          onClick={() => {
            if (!disabled) {
              onSubmit();
            }
          }}
          className={`${disabled ? "disabled-link" : "cursor-pointer"}`}
        />
        <MdClose
          size={24}
          onClick={() => {
            if (!disabled) {
              onCancelEdit();
            }
          }}
          className={`ml-3 ${disabled ? "disabled-link" : "cursor-pointer"}`}
        />
      </Row>
    );
  }
  return (
    <Row>
      {!hideDelete ? (
        <MdDelete
          size={24}
          className={`text-danger ${
            disabled ? "disabled-link" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!disabled) {
              onClickDelete();
            }
          }}
        />
      ) : null}
      <MdModeEdit
        size={24}
        className={`'text-dark ml-3 ${
          disabled ? "disabled-link" : "cursor-pointer"
        }'`}
        onClick={() => {
          if (!disabled) {
            onClickEdit();
          }
        }}
      />
    </Row>
  );
};
