import UserProfile from "../component/appnew/users/userprofile";
import Analytics from "../component/general/dashboard/analytics";
import ButtonArshades from "../pages/ButtonArshades";
import CardBrandPage from "../pages/CardBrandPage";
import GlassList from "../pages/glasses/glassList";
import LineList from "../pages/lines/lineList";
import DataConsumption from "../pages/DataConsumption";
import EditARShadesViewer from "../pages/ARShades3dViewer/EditARShades3dsViewer";
import ModelViewerRender from "../pages/ARShades3dViewer/ModelViewerRender";
import Home from "../pages/home/home";
import ARShadesValidationPage from "../pages/ArshadesValidationPage";
import CardBrandPageRender from "../pages/CardBrandPageRender";
import AdminView from "../pages/Admin";
import OrderPage from "../pages/OrderPage";

// Funzione per ottenere le rotte dinamicamente
const getRoutes = () => {
  // Ottieni il valore di 'canPlaceOrders' dalla localStorage
  const canPlaceOrders = localStorage.getItem('canPlaceOrders') === 'true';

  // Definisci le rotte
  const routes = [
    { path: "/home", Component: Home },
    { path: "/analytics", Component: Analytics },
    { path: "/lines/:id", Component: GlassList },
    { path: "/lines", Component: LineList },
    { path: "/ArshadesButton/:TokenArshades", Component: ButtonArshades },
    { path: "/3dviewers", Component: CardBrandPage },
    { path: "/validation", Component: ARShadesValidationPage },
    { path: "/ARShadesRender", Component: CardBrandPageRender },
    { path: "/profile", Component: UserProfile },
    { path: "/dataConsumption", Component: DataConsumption },
    {
      path: "/editARShadesViewer/:TokenBrand/glasses/:TokenGlasses",
      Component: EditARShadesViewer,
    },
    {
      path: "/modelViewerRender/:modelUrl",
      Component: ModelViewerRender,
    },
    {
      path: "/admin/configureDb",
      Component: AdminView,
    }
  ];

  // Aggiungi la rotta "Order Page" solo se il permesso è vero
  if (canPlaceOrders) {
    routes.push({ path: "/orderPage", Component: OrderPage });
  }

  return routes;
};

// Usa la funzione per ottenere le rotte
export const routes = getRoutes();