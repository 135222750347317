import React from "react";
import styles from "./ColumnSubTab.module.css";

function ColumnSubTab(props) {
  return (
    <div className={styles["colum-element"]} key={props.id}>
      {props.children}
    </div>
  );
}

export default ColumnSubTab;
