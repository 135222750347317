import clsx from 'clsx'
import React from 'react'

const ColorIndicator = ({
  primary,
  secondary,
  type = 'circle',
  className,
  size
}) => {
  return (
    <div
      className={clsx({
        [type]: true,
        [className]: className,
        [`${type}-${size}`]: size
      })}
    >
      <div className="half-color-block" style={{ background: primary }} />
      <div className="half-color-block" style={{ background: secondary }} />
    </div>
  )
}

export default ColorIndicator
