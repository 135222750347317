import { db } from "../data/base";
import { format } from "date-fns";

export const pieData = {
  labels: ["Number of users"],
  datasets: [
    {
      label: "# of user",
      data: [],
      backgroundColor: ["#241F45"],
      borderWidth: 1,
    },
  ],
};

export const ListAllBrands = (rxBrands, brands, setBrandList) => {
  rxBrands.forEach((brand) => {
    db.collection("Brand")
      .doc(brand)
      .get()
      .then((data) => {

        brands.push({ id: data.id, ...data.data() });
        setBrandList(brands);

      });
  });
};
export const getBrandsByRef = async (rxBrands) => {
  const brands = []
  const userBrands = localStorage.getItem("brands");
  for (const brand of rxBrands) {
    if (userBrands.includes(brand)) {
      const bdSnapshot = await db.collection("Brand").doc(brand).get();
      brands.push({ id: brand, ...bdSnapshot.data() });
    }
  }
  return brands;
};

export const pieOptions = {
  circumference: Math.PI,
  rotation: Math.PI,
  plugins: {
    legend: {
      labels: {
        generateLabels: false,
      },
    },
    doughnutCenterText: {
      font: {
        size: "40",
      },
      text: "Total",
    },
  },
};

export const lineData = {
  labels: [],
  datasets: [
    {
      label: "Number of sessions",
      data: [],
      borderColor: "#2D5BFF",
      backgroundColor: "rgba(229, 234, 252, 0.4)",
      pointStyle: "circle",
      pointRadius: 5,
      pointBackgroundColor: "#2D5BFF",
      pointBorderColor: "#2D5BFF",
      pointBorderWidth: 2,
      tension: 0,
    },
  ],
};

export const lineOptions = {
  title: {
    text: "",
  },
  subTitle: {
    text: "Number of Sessions",
  },
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
      suggestedMin: 0,
    },
    yAxes: [{
      ticks: {
        beginAtZero: true,
        min: 0
      },

    }],
  },
  elements: {
    line: {
      fill: true,
      borderWidth: 2,
    },
  },
};

export const dashboardData = [
  {
    leftTitle: "0",
    leftSubtitle: "Main Models",
  },
  {
    leftTitle: "0",
    leftSubtitle: "Active Models",
  },
  {
    leftTitle: "0",
    leftSubtitle: "Active Brands",
  },
  {
    leftTitle: "0",
    leftSubtitle: "Published 3D AR Viewers",
  },
  {
    leftTitle: "0",
    leftSubtitle: "Variants",
    // rightTitle: "0",
    // rightSubtitle: "Variants",
  },
];

export const getModelsFromFirebase = async (selectedBrand, rxBrands) => {
  //the variable stores either brand data or list of brand - when selectedBrannd is all
  const brands = selectedBrand.id !== "all" ? await db.collection("Brand").doc(selectedBrand.id).get() : await db.collection("Brand").get();

  let brandCodes = [];
  let userModels;
  //brands specific to user
  if (brands && brands.size) {
    //only user brand should be used
    // eslint-disable-next-line
    brands.docs.map((brand) => {
      if (rxBrands.includes(brand.id)) {
        brandCodes.push(brand.data().code_path);
      }
    })
  } else {

    brandCodes.push(brands.data().code_path);
  }
  //get modesl specific to brand
  const modelsRef = await db.collection("Modello").get();
  userModels = modelsRef.docs.filter((model) => {
    if (!model.data().codiceVariante) return false;
    const mBrandCode = model.data().codiceVariante.substring(0, 2);
    if (selectedBrand.id === "all") {
      return brandCodes.includes(mBrandCode);
    } else {

      return brandCodes[0] === mBrandCode;
    }
  });

  return userModels.map((el) => { return { id: el.id, ...el.data() } });
};

export const getGlassesInfoFromFirebase = async (selectedBrand, rxBrands) => {
  const listGlasses = [];
  if (selectedBrand.id) {

    // console.log(selectedBrand, "Brand Selected")
    const glasses = await db.collection("Occhiale").get();
    glasses.docs.forEach(async (doc) => {
      const data = doc.data();
      if (
        selectedBrand.id === "all" &&
        data.id &&
        rxBrands.includes(data.brand)
      ) {
        const glassesRef = await db
          .collection("Visualizzatori3D")
          .doc(data.id)
          .collection("Glasses")
          .get();
        // eslint-disable-next-line
        glassesRef.docs.map((g) => {
          // console.log("Data",g.data())
          listGlasses.push(g.data());
        });
      } else if (data.brand === selectedBrand.id && data.id) {
        const glassesRef = await db
          .collection("Visualizzatori3D")
          .doc(data.id)
          .collection("Glasses")
          .get();
        // eslint-disable-next-line
        glassesRef.docs.map((g) => {
          // console.log("Data", g.data())
          listGlasses.push(g.data());
        });
      }
    });
  }
  return listGlasses;
};

export const NumberOfUsers = async (setUsersCount) => {
  const collRef = db.collection("Profile");
  collRef
    .get()
    .then((querySnapshot) => {
      const count = querySnapshot.size;
      setUsersCount(count);
    })
    .catch((error) => {
      // console.error("Error getting document count:", error);
    });
};

export const returnDashboardData = (
  models,
  data,
  config,
) => {
  return [
    {
      leftTitle: `${models.filter((model) => model.main_model === true)?.length}`,
      leftSubtitle: data[0].leftSubtitle,
    },
    {
      leftTitle: `${models?.filter((model) => (model?.stato === "Pubblicato") || (model?.stato === "In Pubblicazione"))?.length
        }`,
      leftSubtitle: data[1].leftSubtitle,
    },
    {
      leftTitle: `${config?.listaBrand?.length}`,
      leftSubtitle: data[2].leftSubtitle,
    },
    {
      leftTitle: `${models?.filter((model) => model?.stato === "Pubblicato")?.length
        }`,
      leftSubtitle: data[3].leftSubtitle,
    },
    {
      leftTitle: `${models.length}`,
      leftSubtitle: 'Variants',
    },
  ];
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const incrementTotalSession = (total) => {
  return total + 1;
};

export const sessionWeekWiseCountFunction = (sessionWeekWiseCount, data) => {
  const timestamp = new Date(
    data.data_inizio_sessione.replace(/\//g, "-")
  ).getTime();
  const now = Date.now();
  const diff = now - timestamp;
  const dayIndex = 6 - Math.floor(diff / (24 * 60 * 60 * 1000));
  if (dayIndex >= 0 && dayIndex <= 6) {
    sessionWeekWiseCount[dayIndex] = sessionWeekWiseCount[dayIndex] + 1;
  }
};

export const sessionWeekWiseAggregateFunc = (sessionWeekWiseCount, data, endDate) => {
  const timestamp = new Date(
    data.date
  ).getTime();
  const now = new Date(endDate).getTime();
  const diff = now - timestamp;
  const dayIndex = 7 - Math.floor(diff / (24 * 60 * 60 * 1000));

  if (dayIndex >= 0 && dayIndex <= 6) {
    sessionWeekWiseCount[dayIndex] = sessionWeekWiseCount[dayIndex] + data.totalSessions;
  }
  // console.log("sessionWeekWiseCount",sessionWeekWiseCount);
};

export const fetchData = async (
  selectedBrand,
  setNumberOfUsers,
  setNumberOfSession,
  setIsLoading,
  setSessionWeekWiseCount,
  rxBrands
) => {
  const customStartDate = new Date();

  const customEndDate = new Date();
  customEndDate.setHours(23, 59, 59);  // Imposta le ore a 23:59:59.999
  customEndDate.setDate(customEndDate.getDate() - 1);
  customStartDate.setDate(customEndDate.getDate() - 7);
  const sessionWeekWiseCount = Array(7).fill(0);

  const startDate = formatDate(customStartDate);
  const endDate = formatDate(customEndDate);
  const deviceIds = [];
  let totalSession = 0;

  setIsLoading(true);
  try {
    if (selectedBrand.id === "all") {
      const brandsRef = db.collection("Brand");
      const brandSnaps = await brandsRef.get();

      const brandList = brandSnaps.docs.filter((doc) => {
        if (rxBrands.includes(doc.id)) {
          return { ...doc.data(), id: doc.id };
        }
      });

      // Loop through each brand
      for (const brand of brandList) {
        const sessioneRef = db
          .collection("Sessione")
          .where("data_inizio_sessione", ">=", startDate)
          .where("data_inizio_sessione", "<=", endDate);
        const sessioneSnaps = await sessioneRef.get();

        sessioneSnaps.forEach((doc) => {
          const data = doc.data();
          if (
            data.device_id &&
            !deviceIds.includes(data.device_id) &&
            data?.ref_catalogo?.id === brand?.id
          ) {
            deviceIds.push(data.device_id);
          }
          if (data?.ref_catalogo?.id === brand?.id) {
            totalSession++;
            sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
          }
        });

        // Repeat the same for Sessione_Visualizzatori3d collection for current brand
        const visualizzatori3dRef = db
          .collection("Sessione_Visualizzatori3d")
          .where("data_inizio_sessione", ">=", startDate)
          .where("data_inizio_sessione", "<=", endDate);
        const visualizzatori3dSnaps = await visualizzatori3dRef.get();

        visualizzatori3dSnaps.forEach((doc) => {
          const data = doc.data();
          if (
            data.device_id &&
            !deviceIds.includes(data.device_id) &&
            data?.ref_catalogo?.id === brand?.id
          ) {
            deviceIds.push(data.device_id);
          }
          if (data?.ref_catalogo?.id === brand?.id) {
            totalSession++;
            sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
          }
        });

        // Repeat the same for Sessione_WebVto collection for current brand
        const webVtoRef = db
          .collection("Sessione_WebVto")
          .where("data_inizio_sessione", ">=", startDate)
          .where("data_inizio_sessione", "<=", endDate);
        const webVtoSnaps = await webVtoRef.get();
        webVtoSnaps.forEach((doc) => {
          const data = doc.data();
          if (
            data.device_id &&
            !deviceIds.includes(data.device_id) &&
            data?.ref_catalogo?.id === brand?.id
          ) {
            deviceIds.push(data.device_id);
          }
          if (data?.ref_catalogo?.id === brand?.id) {
            totalSession++;
            sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
          }
        });
      }
      setNumberOfUsers(deviceIds.length);
      setNumberOfSession(totalSession);
    } else {
      // If selectedBrand.id is not "all", fetch data for the selected brand only
      const sessioneRef = db
        .collection("Sessione")
        .where("data_inizio_sessione", ">=", startDate)
        .where("data_inizio_sessione", "<=", endDate);
      const sessioneSnaps = await sessioneRef.get();

      sessioneSnaps.forEach((doc) => {
        const data = doc.data();
        if (
          data.device_id &&
          !deviceIds.includes(data.device_id) &&
          data?.ref_catalogo?.id === selectedBrand?.id
        ) {
          deviceIds.push(data.device_id);
        }
        if (data?.ref_catalogo?.id === selectedBrand?.id) {
          totalSession++;
          sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
        }
      });

      // Repeat the same for Sessione_Visualizzatori3d collection for current brand
      const visualizzatori3dRef = db
        .collection("Sessione_Visualizzatori3d")
        .where("data_inizio_sessione", ">=", startDate)
        .where("data_inizio_sessione", "<=", endDate);
      const visualizzatori3dSnaps = await visualizzatori3dRef.get();

      visualizzatori3dSnaps.forEach((doc) => {
        const data = doc.data();
        if (
          data.device_id &&
          !deviceIds.includes(data.device_id) &&
          data?.ref_catalogo?.id === selectedBrand?.id
        ) {
          deviceIds.push(data.device_id);
        }
        if (data?.ref_catalogo?.id === selectedBrand?.id) {
          totalSession++;
          sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
        }
      });

      // Repeat the same for Sessione_WebVto collection for current brand
      const webVtoRef = db
        .collection("Sessione_WebVto")
        .where("data_inizio_sessione", ">=", startDate)
        .where("data_inizio_sessione", "<=", endDate);
      const webVtoSnaps = await webVtoRef.get();
      webVtoSnaps.forEach((doc) => {
        const data = doc.data();
        if (
          data.device_id &&
          !deviceIds.includes(data.device_id) &&
          data?.ref_catalogo?.id === selectedBrand?.id
        ) {
          deviceIds.push(data.device_id);
        }
        if (data?.ref_catalogo?.id === selectedBrand?.id) {
          totalSession++;
          sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
        }
      });

      setNumberOfUsers(deviceIds.length);
      setNumberOfSession(totalSession);
    }
    setSessionWeekWiseCount(sessionWeekWiseCount);
    setIsLoading(false);
  }
  catch (e) {
    // console.log("e", e);
  }
};

export const fetchAggregateData = async (
  selectedBrand,
  setNumberOfUsers,
  setNumberOfSession,
  setIsLoading,
  setSessionWeekWiseCount,
  rxBrands
) => {
  const customStartDate = new Date();

  const customEndDate = new Date();

  customStartDate.setTime(customEndDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  const sessionWeekWiseCount = Array(7).fill(0);
  const startDate = format(new Date(customStartDate), "yyyy/MM/dd")
  const endDate = format(new Date(customEndDate), "yyyy/MM/dd")

  let totalSession = 0;
  let totalUsers = 0;

  setIsLoading(true);
  try {
    if (selectedBrand.id === "all") {
      const brandsRef = db.collection("Brand");
      const brandSnaps = await brandsRef.get();

      const brandList = brandSnaps.docs.filter((doc) => {
        if (rxBrands.includes(doc.id)) {
          return { ...doc.data(), id: doc.id };
        }
      });


      // Loop through each brand
      for (const brand of brandList) {
        const sessioneRef = db
          .collection("Aggregate_Session")
          .where("date", ">=", startDate)
          .where("date", "<", endDate);
        const sessioneSnaps = await sessioneRef.get();

        sessioneSnaps.forEach((doc) => {
          const data = doc.data();

          if (data?.brand === brand?.id) {
            totalSession += data.totalSessions ?? 0;
            totalUsers += data.totalUniqueUsers ?? 0;
            sessionWeekWiseAggregateFunc(sessionWeekWiseCount, data, endDate);
          }
        });

        // Repeat the same for Sessione_Visualizzatori3d collection for current brand

      }
      setNumberOfUsers(totalUsers);
      setNumberOfSession(totalSession);
    } else {
      // If selectedBrand.id is not "all", fetch data for the selected brand only
      const sessioneRef = db
        .collection("Aggregate_Session")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate);
      const sessioneSnaps = await sessioneRef.get();

      sessioneSnaps.forEach((doc) => {
        const data = doc.data();

        if (data?.brand === selectedBrand?.id) {
          totalSession += data.totalSessions ?? 0;
          totalUsers += data.totalUniqueUsers ?? 0;
          sessionWeekWiseAggregateFunc(sessionWeekWiseCount, data, endDate);
        }
      });


      setNumberOfUsers(totalUsers);
      setNumberOfSession(totalSession);
    }

    setSessionWeekWiseCount(sessionWeekWiseCount);
    setIsLoading(false);
  }
  catch (e) {
    // console.log("e", e);
  }
};
