import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { db } from "../../data/base";

const LineEditorModal = ({ line, setLineList, toggleLineModal }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});

  const handleChange = ({ target: { value, name } }) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { id, descrizione_en, nome_linea } = state;
    setLoading(true);

    try {
      await db.collection("Linea").doc(id).update({
        nome_linea,
        descrizione_en,
      });

      //   update current glass in glass list
      setLineList((lineList) =>
        lineList.map((glass) =>
          glass.id === id ? { ...glass, ...state } : glass
        )
      );
      toast.success("Updated successfully");
      toggleLineModal(null)();
    } catch (e) {
      toast.error("Error updating");
      toast.error(e.message);
      // console.log(e.message, e.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    setState(line);
  }, [line]);

  return (
    <Modal
      className="modal-dialog modal-lg modal-dialog-centered"
      isOpen={Boolean(line)}
      toggle={toggleLineModal(null)}
    >
      <ModalHeader toggle={toggleLineModal(null)}>
        {line ? "Edit" : "Add"} Line
      </ModalHeader>
      <ModalBody>
        <FormGroup className="mb-3">
          <Label>Line Name</Label>
          <Input
            className="d-block"
            type="text"
            name="nome_linea"
            value={state?.nome_linea || ""}
            placeholder="https://www.example.com"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            className="d-block mb-3"
            rows={8}
            type="textarea"
            name="descrizione_en"
            value={state?.descrizione_en || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          color="primary"
          className="w-100 d-flex justify-content-center align-items-center"
          disabled={loading}
          onClick={handleSubmit}
        >
          Save {loading && <Spinner size="sm" className="ml-2" />}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default LineEditorModal;
