import { combineReducers } from "redux";
import analytics from "./analytics/reducer";
import Brand from "./brand/reducer";
import Cart from "./cart/reducer";
import ChatApp from "./chat-app/reducer";
import Customizer from "./customizer/reducer";
import EmailApp from "./email-app/reducer";
import Filters from "./filter/reducer";
import Ecommerce from "./product/reducer";
import Todoapp from "./todo/todo.reducer";
import Wishlist from "./wishlist/reducer";
import config from "./config/config";
import generalConfig from "./config/generalConfig";
import occhiale from "./occhiale/occhiale";
import taglia from "./occhiale/taglia";
import modello from "./occhiale/modello";
import brandViewer from "./brandViewer/brandViewer";
import modelGlasses from "./brandViewer/modelGlasses";
import profile from "./profile/reducer";

const reducers = combineReducers({
  data: Ecommerce,
  Wishlistdata: Wishlist,
  Cartdata: Cart,
  filters: Filters,
  Todoapp,
  ChatApp,
  EmailApp,
  Customizer,
  selectedBrand: Brand,
  analytics,
  config,
  occhiale,
  taglia,
  modello,
  brandViewer,
  generalConfig,
  modelGlasses,
  profile,
});

export default reducers;
