import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { getDurationFromSecond } from "../../../utils";
import "./analitycsModule.css";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { db } from "../../../data/base";
import AnalyticsOverviewCard from "./analyticsOverviewCard";
import AnalyticsTable from "./analyticsTable";
import DeviceAnalytics from "./DeviceAnalytics";
import * as XLSX from "xlsx";
import Filters from "../../appnew/analytics/Filters";
import { setAnalyticsFilterOptions } from "../../../redux/analytics/actions";
import { format } from "date-fns";
import { getAggregatedData, enrichDataWithSubcollections, generateReport, getMinSecObject } from "./analyticsAggrgator/Helper";

const endDate = new Date();
endDate.setHours(23, 59, 59); // Imposta le ore a 23:59:59.999
const startDate = new Date(endDate);

// console.log("END DATE", endDate)
startDate.setDate(endDate.getDate() - 7);
const defaultDateRange = {
  startDate,
  endDate,
  key: "selection",
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Analytics = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const { selectedBrand } = useSelector((state) => state);
  const [downloadLoading, setDownloadLoading] = useState(false);
  
  const [tableOverview, setTableOverview] = useState({});
  const [reportData, setReportData] = useState({}); //
  // const [sessionListApp, setSessionListApp] = useState([]);
  // const [sessionList3D, setSessionList3D] = useState([]);
  // const [sessionListWeb, setSessionListWeb] = useState([]);
  const [filteredSessionList, setFilteredSessionList] = useState([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [typeData, setTypeData] = useState("all");
  // const glassArray = useSelector((state) => state.analytics.glassArray);
  // const variantArray = useSelector((state) => state.analytics.variantArray);

  const { id } = useSelector((state) => state.selectedBrand);
  const filterOptions = useSelector((state) => state.analytics.filterOptions);
  const { line, glass, variant } = filterOptions;
  const lineList = useSelector((state) => state.analytics.lineList);
  const glassList = useSelector((state) => state.analytics.glassList);

  const [aggregateList, setAggregateList] = useState([]);
  const [filteredAggList, setFilteredAggList] = useState([]);

  const [selectedRange, setSelectedRange] = useState(defaultDateRange);

  ///DOWNLOAD REPORT///
  // const [lastDoc, setLastDoc] = useState(null);
  // const [modelsBrandSelected, setModelsBrandSelected] = useState([]);
  // const [model, setModel] = useState([]);
  // const [glasses, setGlasses] = useState([]);
  // const [glassesIdList, setGlassesIdList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [brandProfileData, setBrandProfileData] = useState([]);

  useEffect(() => {
    // console.log("Selected Brand:", selectedBrand);
    // setModel([]);
    // setModelsBrandSelected([]);
    // setLastDoc(null);
    // handleOnChangeBrandId();
  }, [selectedBrand?.id]);

  const handleDownload = async () => {
    generateReport(startDateReport, endDateReport, selectedBrands, selectedOption);
  };

  const handleDownloadReportProva = async () => {
    getAllBrandIdsData();
    setShowModal(true);
  };

  const handleCloseModalProva = () => {
    setShowModal(false);
  };

  const [selectedOption, setSelectedOption] = useState([]);

  const handleOptionChange = (e) => {
    const value = e.target.value;

    if (value === 'all') {
      // Se "ALL SERVICES" è selezionato, seleziona o deseleziona tutte le opzioni
      if (selectedOption.includes('all')) {
        setSelectedOption([]);
      } else {
        setSelectedOption(['all', '3D VIEWER', 'WEB VTO', 'APP']);
      }
    } else {
      let newSelectedOptions;
      if (selectedOption.includes(value)) {
        // Rimuovi l'opzione deselezionata
        newSelectedOptions = selectedOption.filter(option => option !== value);
      } else {
        // Aggiungi l'opzione selezionata
        newSelectedOptions = [...selectedOption, value];
      }

      // Controlla se tutte le opzioni sono selezionate per attivare "ALL SERVICES"
      if (newSelectedOptions.length === 3 && !newSelectedOptions.includes('all')) {
        newSelectedOptions.push('all');
      } else if (newSelectedOptions.includes('all') && newSelectedOptions.length !== 4) {
        // Rimuovi "ALL SERVICES" se non tutte le opzioni sono selezionate
        newSelectedOptions = newSelectedOptions.filter(option => option !== 'all');
      }

      setSelectedOption(newSelectedOptions);
    }
  };

  // const [selectedBrands, setSelectedBrands] = useState([]);
  // const [allBrandsSelected, setAllBrandsSelected] = useState(false);

  // const handleBrandChange = (e) => {
  //   const { value, checked } = e.target;
  //   setSelectedBrands((prevSelectedBrands) => {
  //     const updatedSelectedBrands = checked
  //       ? [...prevSelectedBrands, value]
  //       : prevSelectedBrands.filter((brand) => brand !== value);

  //     // Log della lista aggiornata dei brand selezionati
  //     // console.log("Selected Brands:", updatedSelectedBrands);

  //     return updatedSelectedBrands;
  //   });
  // };

  // const handleSelectAllChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setAllBrandsSelected(isChecked);
  //   if (isChecked) {
  //     const allBrands = brandProfileData.map(brand => brand.id);
  //     setSelectedBrands(allBrands);
  //     // Log della lista dei brand selezionati quando si seleziona tutto
  //     // console.log("All Brands Selected:", allBrands);
  //   } else {
  //     setSelectedBrands([]);
  //     // Log quando si deseleziona tutto
  //     // console.log("No Brands Selected");
  //   }
  // };

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [allBrandsSelected, setAllBrandsSelected] = useState(false);

  useEffect(() => {
    // Attiva "SELECT ALL" se tutti i brand sono selezionati
    setAllBrandsSelected(selectedBrands.length === brandProfileData.length);
  }, [selectedBrands, brandProfileData]);

  const handleBrandChange = (e) => {
    const { value, checked } = e.target;
    setSelectedBrands((prevSelectedBrands) => {
      const updatedSelectedBrands = checked
        ? [...prevSelectedBrands, value]
        : prevSelectedBrands.filter((brand) => brand !== value);

      return updatedSelectedBrands;
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setAllBrandsSelected(isChecked);
    if (isChecked) {
      const allBrands = brandProfileData.map(brand => brand.id);
      setSelectedBrands(allBrands);
    } else {
      setSelectedBrands([]);
    }
  };

  const [startDateReport, setStartDateReport] = useState('');
  const [endDateReport, setEndDateReport] = useState('');
  const [error, setError] = useState('');



  // Funzione per confrontare due date in formato YYYY-MM-DD
  const compareDates = (date1, date2) => {
    if (!date1 || !date2) return null;
    return date1.localeCompare(date2);
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;

    if (endDateReport && compareDates(value, endDateReport) > 0) {
      setError('Start date cannot be later than end date.');
    } else {
      setStartDateReport(value);
      setError('');
    }
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;

    if (startDateReport && compareDates(value, startDateReport) < 0) {
      setError('End date cannot be earlier than start date.');
    } else {
      setEndDateReport(value);
      setError('');
    }
  };

  // Verifica se le date sono valide e se non ci sono errori
  const isFormValid = () => {
    return startDateReport && endDateReport && !error;
  };

  ///FINE DOWNLOAD REPORT///

  const handleApply = () => {
    // Applica il range di date selezionato quando l'utente preme 'Apply'
    setDateRange(selectedRange);
    toggleDateModal();
  };

  const handleApplyReport = () => {
    // Applica il range di date selezionato quando l'utente preme 'Apply'
    setDateRange(selectedRange);
    // toggleDateModal();
  };

  useEffect(() => {
    let selectedLine = "";
    let selectedModel = "";
    if (line) {
      selectedLine = lineList?.find((linea) => linea.id === line);
    }
    if (glass) {
      selectedModel = glassList?.find((modello) => modello.id === glass);
    }
    setReportData({
      line: selectedLine?.nome_linea ?? "All",
      model: selectedModel?.nome_modello ?? "All",
    });
  }, [line, glass, filterOptions]);


  // const getSessionsReport = () => {
  //   const sessions = filteredSessionList?.map(
  //     ({
  //       data_fine_user_session,
  //       data_inizio_sessione,
  //       device,
  //       device_id,
  //       device_os,
  //       id,
  //       total_time,
  //     }) => ({
  //       id,
  //       device_id,
  //       device_os,
  //       device,
  //       data_inizio_sessione,
  //       data_fine_user_session,
  //       total_time,
  //     })
  //   );
  //   const userMetrics = filteredSessionList
  //     ?.map((session) => {
  //       const metric = session?.user_metrics?.listaMetric ?? [];
  //       const userMetric = metric.reduce((acc, curr) => {
  //         const keyPrefix = curr?.metricTitle;
  //         acc[`${keyPrefix}Points`] = [curr?.point1, curr?.point2];
  //         acc[`${keyPrefix}totalMetric`] = curr?.totalMetric;
  //         return acc;
  //       }, {});
  //       return userMetric;
  //     })
  //     .filter((userMetric) => Object.keys(userMetric).length !== 0);

  //   return {
  //     userMetrics,
  //     sessions,
  //   };
  // };

  const toggleDateModal = () => {
    setIsDateModalOpen(!isDateModalOpen);
  };

  const handleDateRangeChange = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const handleClearFilter = () => {
    dispatch(
      setAnalyticsFilterOptions({
        line: "",
        glass: "",
        variant: "",
      })
    );
    setDateRange(defaultDateRange);
    setTypeData("all");
   
    setRefresh((r) => !r)
  };

  const getAllBrandIdsData = async () => {
    let brandsList = [];

    const brands = localStorage.getItem("brands");
    if (!brands) {
      console.error("No brands found in localStorage");
      return;
    }

    const brands_list = brands.split(',');
    // console.log("ID", brands);
    try {
      // Mappa ogni ID brand in una promessa che risolve in un oggetto brand
      const brandPromises = brands_list.map(async (item) => {
        const brandDoc = await db.collection("Brand").doc(item).get();
        return { id: brandDoc.id, ...brandDoc.data() };
      });

      // Aspetta che tutte le promesse siano risolte
      brandsList = await Promise.all(brandPromises);

      // console.log("Brand List", brandsList);
      setBrandProfileData(brandsList);
    } catch (error) {
      console.error("Error fetching brand data", error);
    }
  }

  const getAllBrandIds = async () => {
    try {
      const brands = localStorage.getItem("brands");


      const brandRef = await db.collection("Brand").get();
      const filteredBrands = brandRef.docs
        .filter(doc => brands.includes(doc.id))  // Filtra prima i documenti
        .map(doc => {
          // console.log("HEY", doc.id, brands);
          return doc.id;
        });

      return filteredBrands;
    } catch (e) {
      // throw new Error(e?.message ?? "Error while fetching brands");
    }
  };


  const [nomeModello, setNomeModello] = useState();
  const [overviewData, setOverviewData] = useState();
  const [sessionData, setSessionData] = useState();
  const [dateFormated, setDateFormated] = useState([]);

  function getModelNameTable(model) {
    setNomeModello(model?.nome_modello);
  }

  function getDataAnalyticsOverview(data) {

    const {
      totalSession,
      totalUser,
      totalNewUser,
      totalAvgTime,
      totalAvgEndTime,
      totalAvgSpU,
      ..._data
    } = data;
    setReportData({
      ...reportData,
      brand: selectedBrand.nome_brand,
      typeData,
      startDate: format(new Date(dateRange.startDate), "yyyy/MM/dd 23:59:59"),
      endDate: format(new Date(dateRange.endDate), "yyyy/MM/dd 23:59:59"),
      totalSession,
      totalUser,
      totalNewUser,
      totalAvgEndTime,
      totalAvgSpU,
    });
    setOverviewData(_data);
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const dateSet = new Set();
    setDateFormated([]);
    if (typeData === "all" && overviewData?.dates) {
      overviewData.dates.forEach((date) => {
        // if (!date) {
        //   return;
        // }
        dateSet.add(date);
      });
      if (sessionData?.dates) {
        sessionData.dates.forEach((date) => {
          // if (!date) {
          //   return;
          // }
          dateSet.add(date);
        });
      }
      const dateList = [...dateSet];

      const listDateObj = [];
      dateList.forEach((date) => {
        listDateObj.push(new Date(date));
      });

      const dateSorted = listDateObj.sort((a, b) => a - b);
      const dateSortedFormated = [];
      dateSorted.forEach((date) => {
        dateSortedFormated.push(
          `${date.getDate()}/${months[date.getMonth()]}/${date.getFullYear()}`
        );
      });
      setDateFormated(dateSortedFormated);
      const newSessionData = {
        user: [],
        session: [],
        newUser: [],
        avgTime: [],
        avgEngTime: [],
        avgSpU: [],
      };
      const newOverviewData = {
        user: [],
        session: [],
        newUser: [],
        avgTime: [],
        avgEngTime: [],
        avgSpU: [],
      };

      dateSortedFormated.forEach((date, i) => {
        if (date === sessionData?.dates[i]) {
          newSessionData.user.push(sessionData?.user[i]);
          newSessionData.session.push(sessionData?.session[i]);
          newSessionData.newUser.push(sessionData?.newUser[i]);
          newSessionData.avgTime.push(sessionData?.avgTime[i]);
          newSessionData.avgEngTime.push(sessionData?.avgEngTime[i]);
          newSessionData.avgSpU.push(sessionData?.avgSpU[i]);
        } else {
          newSessionData.user.push(0);
          newSessionData.session.push(0);
          newSessionData.newUser.push(0);
          newSessionData.avgTime.push(0);
          newSessionData.avgEngTime.push(0);
          newSessionData.avgSpU.push(0);
        }
        if (date === overviewData.dates[i]) {
          newOverviewData.user.push(sessionData?.user[i]);
          newOverviewData.session.push(sessionData?.session[i]);
          newOverviewData.newUser.push(sessionData?.newUser[i]);
          newOverviewData.avgTime.push(sessionData?.avgTime[i]);
          newOverviewData.avgEngTime.push(sessionData?.avgEngTime[i]);
          newOverviewData.avgSpU.push(sessionData?.avgSpU[i]);
        } else {
          newOverviewData.user.push(0);
          newOverviewData.session.push(0);
          newOverviewData.newUser.push(0);
          newOverviewData.avgTime.push(0);
          newOverviewData.avgEngTime.push(0);
          newOverviewData.avgSpU.push(0);
        }
      });
      setOverviewData(newOverviewData);
    }
  }, [typeData]);

  /** NEW IMPLEMENTATION FUNCTION */
  const getServiceType = (service)=>{
    switch (service) {
      case "app":
        return "APP";
      case "3d":
        return "3D VIEWER";
      case "WebVto":
        return "WEB VTO";
      case "all":
        return "ALL"
      default: 
      return  "";
    }
  }
  useEffect(() => {
    //   // filter data based on filter options
    let filteredAggs =
      aggregateList?.filter((session) => {
        //
        if(typeData != 'all' && session.service != getServiceType(typeData)){
          return false;
        }
        let result = true;
        if (session.lineArray?.findIndex((value) => value.lineRef === line) != -1) {
          result = true;
        }
        const gData = session.glassesArray;
        const vData = session.variantsArray;

        if (!!line && !session.lineArray.some((value) => {
          //update the session with selected line data
          if (value.lineRef == line) {
            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
            session.averageSessionsPerUser = value.averageSessionsPerUser;
            session.newUsersCount = value.newUsersCount;
            session.totalSessions = value.numberOfSessions;
            session.totalUniqueUsers = value.numberOfUniqueUsers;
            // updatedSession.avgSessionTime = exactSession.time;
          }
          return value.lineRef == line
        })) result = false;
        if (!!glass && gData != null && !gData.some((value) => {
          //update the session with selected glass data
          if(value.glassesRef == glass){
            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
            session.averageSessionsPerUser = value.averageSessionsPerUser;
            session.newUsersCount = value.newUsersCount;
            session.totalSessions = value.numberOfSessions;
            session.totalUniqueUsers = value.numberOfUniqueUsers;
          }
        return  value.glassesRef == glass
        })) result = false;
        if (!!variant && vData != null && !vData.some((value) => {
          //update the session with selected model data
          if(value.modelRef == variant){
            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
            session.averageSessionsPerUser = value.averageSessionsPerUser;
            session.newUsersCount = value.newUsersCount;
            session.totalSessions = value.numberOfSessions;
            session.totalUniqueUsers = value.numberOfUniqueUsers;
          }
          return value.modelRef == variant})) result = false;
        return result;
      }) ?? [];

    filteredAggs =
      filteredAggs?.map((session) => {
        const updatedSession = { ...session };
        if (!!line) {
          updatedSession.lineaRefs = [line];
        }
        if (!!glass) updatedSession.glassesRefs = [glass];
        if (!!variant) updatedSession.modelRef = [variant];
        return updatedSession;
      }) ?? [];
    //if service not all
    setFilteredAggList(filteredAggs);
  // if(service != 'all') handleServiceChanged(service)

  }, [glass, line, variant,typeData, aggregateList]);


  const fetchAggregate = async () => {
    let allBrandIds;
    setLoading(true);
    if (id?.toLowerCase() === "all") {
      allBrandIds = await getAllBrandIds();
    } else {
      allBrandIds = [id];
    }
    const startDate = format(new Date(dateRange.startDate), "yyyy/MM/dd");
    const endDate = format(new Date(dateRange.endDate), "yyyy/MM/dd");
    const aggregateData = await getAggregatedData(allBrandIds, startDate, endDate);

    if (aggregateData) {
      setAggregateList(aggregateData);
    } else {
      setAggregateList([]);
    }
    setLoading(false);
    setLoadingDataTable(true)
    // Avvia l'arricchimento dei dati
    const enrichedData = await enrichDataWithSubcollections(aggregateData, setLoadingDataTable);
    // console.log("ENRICHED", enrichedData);
    setAggregateList(enrichedData);
  };


  const handleServiceChanged = (e) => {
    const value = e;
    let filteredAggregate = [];
    // setService(value)
    // switch (value) {
    //   case "app":
    //     filteredAggregate = aggregateList.filter((el) => el.service == "APP");
    //     break;
    //   case "3d":
    //     filteredAggregate = aggregateList.filter((el) => el.service == "3D VIEWER");
    //     break;
    //   case "WebVto":
    //     filteredAggregate = aggregateList.filter((el) => el.service == "WEB VTO");
    //     break;
    //   case "all":
    //     filteredAggregate = aggregateList;
    //     break;
    //   default:
    //     filteredAggregate = aggregateList;
    //     break;
    // }
    setTypeData(value);
    // setFilteredAggList(filteredAggregate)
  }

  useEffect(() => {
    fetchAggregate()
  }, [id, dateRange, refresh])
  /** END OF NEW IMPLEMENTATION FUNCTION */

  return (
    <Container fluid={true} id="analytics">
      <Row className="justify-content-between">
        <Col
          lg={3}
          sm={2}
          xs={12}
          style={{
            padding: "10px",
          }}
        >
          <Button
            onClick={handleDownloadReportProva}
            disabled={loading || downloadLoading}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {downloadLoading ? <Spinner size="sm" /> : null}
            Download report
          </Button>
        </Col>

      </Row>
      <Filters
        typeData={typeData}
        dateRange={dateRange}
        onChangeService={handleServiceChanged}
        onChangeDate={toggleDateModal}
        onClearFilter={handleClearFilter}
      />
      <Row>
        <Col xs={12}>
          <AnalyticsOverviewCard
            loading={loading}
            aggregateList={filteredAggList}
            getData={getDataAnalyticsOverview}
          />
        </Col>
        <Col xs={12}>
          <AnalyticsTable
            setTableOverview={setTableOverview}
            loading={loadingDataTable}
            modelName={getModelNameTable}
            aggregateList={filteredAggList}
          />
        </Col>
        <Col xs={12}>
          <DeviceAnalytics
            loading={loading}
            sessionList={filteredSessionList}
            aggregateList={filteredAggList}
          />
        </Col>
      </Row>

      <Modal
        isOpen={isDateModalOpen}
        centered
        size="lg"
        toggle={toggleDateModal}
      >
        <ModalHeader
          className="text-primary"
          charCode="&times;"
          toggle={toggleDateModal}
        >
          Select Date Range
        </ModalHeader>
        <ModalBody>
          <DateRangePicker
            className="d-flex"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#41b1ac", "#41b1ac"]}
            ranges={[selectedRange]}
            direction="horizontal"
            maxDate={new Date()}
            onChange={handleDateRangeChange}
          />
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button
                color="primary"
                onClick={handleApply}
                style={{ width: '100%' }}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal isOpen={showModal} centered size="lg" toggle={handleCloseModalProva}>

        <ModalHeader toggle={handleCloseModalProva}>
          Report Download
        </ModalHeader>

        <ModalBody>
          <Row>

            <Col md={4} style={{ padding: '0 2rem', textAlign: 'left' }}>
              <h5 className="text-primary">Select Service</h5>
              <FormGroup style={{ marginBottom: '2rem' }}>
                <Label style={{ fontSize: '0.85rem' }}>
                  <Input
                    type="checkbox"
                    value="all"
                    checked={selectedOption.includes('all')}
                    onChange={handleOptionChange}
                  />
                  ALL SERVICES
                </Label>
              </FormGroup>
              <FormGroup style={{ marginBottom: '0.5rem' }}>
                <Label style={{ fontSize: '0.85rem' }}>
                  <Input
                    type="checkbox"
                    value="3D VIEWER"
                    checked={selectedOption.includes('3D VIEWER')}
                    onChange={handleOptionChange}
                    disabled={selectedOption.includes('all')}
                  />
                  3D/AR VIEWER
                </Label>
              </FormGroup>
              <FormGroup style={{ marginBottom: '0.5rem' }}>
                <Label style={{ fontSize: '0.85rem' }}>
                  <Input
                    type="checkbox"
                    value="WEB VTO"
                    checked={selectedOption.includes('WEB VTO')}
                    onChange={handleOptionChange}
                    disabled={selectedOption.includes('all')}
                  />
                  WEB VTO
                </Label>
              </FormGroup>
              <FormGroup style={{ marginBottom: '0.5rem' }}>
                <Label style={{ fontSize: '0.85rem' }}>
                  <Input
                    type="checkbox"
                    value="APP"
                    checked={selectedOption.includes('APP')}
                    onChange={handleOptionChange}
                    disabled={selectedOption.includes('all')}
                  />
                  VTO APPS
                </Label>
              </FormGroup>
            </Col>

            <Col md={4} style={{ padding: '0 2rem', textAlign: 'left' }}>
              <h5 className="text-primary">Select Brand(s)</h5>
              <FormGroup check style={{ marginBottom: '2rem' }}>
                <Label check style={{ fontSize: '0.85rem' }}>
                  <Input
                    type="checkbox"
                    name="selectAll"
                    checked={allBrandsSelected}
                    onChange={handleSelectAllChange}
                  />
                  SELECT ALL
                </Label>
              </FormGroup>
              {brandProfileData
                .sort((a, b) => a.nome_brand.localeCompare(b.nome_brand))
                .map((brand, index) => (
                  <FormGroup check key={brand.id} style={{ marginTop: index === 0 ? '0.5rem' : '0.25rem' }}>
                    <Label check style={{ fontSize: '0.85rem' }}>
                      <Input
                        type="checkbox"
                        name="brands"
                        value={brand.id}
                        checked={selectedBrands.includes(brand.id)}
                        onChange={handleBrandChange}
                        disabled={allBrandsSelected}
                      />
                      <span style={{ fontSize: '0.85rem', textTransform: 'uppercase', color: 'black' }}>
                        {brand.nome_brand}
                      </span>
                    </Label>
                  </FormGroup>
                ))}
            </Col>

            <Col md={4} style={{ padding: '0 2rem', textAlign: 'left' }}>
              <h5 className="text-primary">Select Date Range</h5>
              <FormGroup>
                <Label for="startDateReport" style={{ fontSize: '0.85rem' }}>START DATE</Label>
                <Input
                  type="date"
                  id="startDateReport"
                  name="startDateReport"
                  value={startDateReport}
                  onChange={handleStartDateChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="endDateReport" style={{ fontSize: '0.85rem' }}>END DATE</Label>
                <Input
                  type="date"
                  id="endDateReport"
                  name="endDateReport"
                  value={endDateReport}
                  onChange={handleEndDateChange}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
            </Col>

          </Row>

          <div className="text-center mt-3">
            <Button
              color="primary"
              onClick={handleDownload}
              disabled={!isFormValid()}
            >
              Procedi al Download
            </Button>
          </div>
        </ModalBody>

      </Modal>

    </Container>
  );
};

export default Analytics;