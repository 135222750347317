import React from "react";
import { useSelector } from "react-redux";
import styles from "./ModelContainer.module.css";
import { useState, useEffect, useRef  } from 'react';


function CardModelView(props) {

  const model = useSelector((state) =>
  state.modelGlasses.find(
    (model) => model.id === props.modelId)
  );
    
  const [cardStyle, setCardStyle] = useState(props.cardStyle);

  // useEffect(() => {
  //   if (window.innerWidth <= 700) {
  //     setCardStyle("circle");
  //   }
  // }, []);

  const cardRef = useRef(null);

  useEffect(() => {
    if (props.modelId === props.selected && cardRef.current) {
      // console.log("in to smooth")
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [props.modelId, props.selected]);

  return (
    <>
      {cardStyle === "circle" && (
        <div
          className={
            props.modelId === props.selected
              ? styles["itemDotSelected"]
              : styles["itemDot"]
          }
          onClick={props.selectModel}
          style={{
            // backgroundImage: `url(${model?.poster})`,
            width: `${
              props.modelId === props.selected
                ? props.project.items_with_selected
                : props.project.items_with
            }`,
            height: `${
              props.modelId === props.selected
                ? props.project.items_with_selected
                : props.project.items_with
            }`,
            background: `linear-gradient(90deg, ${model?.esaColorFramePrimary} 49%, ${model?.esaColorLensesPrimary} 51%)`,
            border: "1px solid black"
          }}
        ></div>
      )}
      {cardStyle === "plane" && (
        <div
        ref={cardRef}
          className={
            props.modelId === props.selected
              ? styles["itemClassicSelected"]
              : styles["itemClassic"]
          }
          onClick={props.selectModel}
        >
          <img
            src={model?.poster}
            alt="card model viewer"
            className={styles["image_plane"]}
          ></img>
          <p style={{fontSize: '10px', textAlign: 'center', marginBottom:'5px',}}>{model?.coloreFrame}</p>
          {props.modelId === props.selected && (
            <p className={styles["selected-indicator"]}></p>
          )}
        </div>
      )}

      {cardStyle === "card" && (
        <div
          className={
            props.modelId === props.selected
              ? styles["itemSelected"]
              : styles["item"]
          }
          onClick={props.selectModel}
          style={{ backgroundImage: `url(${model?.poster})` }}
        ></div>
      )}
    </>
  );
}

export default CardModelView;
