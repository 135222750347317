import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import AddClientModal from './AddClientModal';
import AddSubscriptionModal from './AddSubscriptionModal';
import AddProfileForm from './AddProfileForm';
import AddBrandForm from './AddBrandForm';
import { addNewSubscription, addNewProfile, addNewBrand, removeCatalogFromProfile, addClientCatalogToProfile } from '../../services/OnBordingUtils';
import { MdEdit, MdAdd, MdRemove } from 'react-icons/md';
import {
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../data/base';
import { aggregateDataForAllBrands, aggregateDataForBrand, endDate, startDate } from '../../component/general/dashboard/analyticsAggrgator/Helper';
import { format } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import DbTestComponent from './DbTest';

const AdminView = () => {
  const [loading, setLoading] = useState(false);
  const [executing, setExecute] = useState(false);
  const [executing2, setExecute2] = useState(false);
  const [executing3, setExecute3] = useState(false);
  const [executing4, setExecute4] = useState(false);
  const [clients, setClients] = useState([]);
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [allProfileList, setAllProfileList] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  // Percentuale di progresso
  const [downloadLoading2, setDownloadLoading2] = useState(false);
  const [totalOperations2, setTotalOperations2] = useState(0);
  const [completedOperations2, setCompletedOperations2] = useState(0);
  const [isDateModalOpenMetrics, setIsDateModalOpenMetrics] = useState(false);
  const defaultDateRangeMetrics = {
    startDate,
    endDate,
    key: "selection",
  };
  const [dateRangeMetrics, setDateRangeMetrics] = useState(defaultDateRangeMetrics);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [clientToAddProfile, setClientToAddProfile] = useState(null);
  const [clientToAddBrand, setClientToAddBrand] = useState(null);
  const [isModalOpenSubscription, setIsModalOpenSubscription] = useState(false);
  const [clientToAddSubscription, setClientToAddSubscription] = useState(null);
  const [brandDetails, setBrandDetails] = useState({});
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
  const [expandedLicense, setExpandedLicense] = useState(null);
  const [riferimentoTipo, setRiferimentoTipo] = useState('');
  const [licenseId, setLicenseId] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [clientId, setClientId] = useState('');
  const [addingProfile, setAddingProfile] = useState(false);
  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);
  const [isModalOpenBrand, setIsModalOpenBrand] = useState(false);
  const [isModalOpenProfileCatalogs, setIsModalOpenProfileCatalogs] = useState(false);
  const [brandNames, setBrandNames] = useState({});


  const fetchBrandNames = async (catalogsList) => {
    try {
      const brandPromises = catalogsList.map(async (catalogId) => {
        const brandDoc = await db.collection('Brand').doc(catalogId).get();
        if (brandDoc.exists) {
          return { catalogId, brandName: brandDoc.data().nome_brand };
        } else {
          return { catalogId, brandName: 'Unknown' };
        }
      });

      const brandData = await Promise.all(brandPromises);
      const brandNameMap = {};
      brandData.forEach(({ catalogId, brandName }) => {
        brandNameMap[catalogId] = brandName;
      });

      setBrandNames((prevBrandNames) => ({
        ...prevBrandNames,
        ...brandNameMap,
      }));
    } catch (error) {
      console.error('Error fetching brand names:', error);
    }
  };

  useEffect(() => {
    if (allProfileList.length > 0) {
      const catalogsList = allProfileList.flatMap(profile => profile.catalogsList || []);
      fetchBrandNames(catalogsList);
    }
  }, [allProfileList]);

  useEffect(() => {
    if (clientToAddProfile) {
      const catalogsList = clientToAddProfile.catalogsList || [];
      fetchBrandNames(catalogsList);
    }
  }, [clientToAddProfile]);

  const toggleProfileModal = (profile, client) => {
    setClientToAddProfile(client);
    setAllProfileList([profile]);
    setIsModalOpenProfileCatalogs(true);
  };

  const handleAddCatalogToProfile = async (profileEmail, catalog) => {
    await addClientCatalogToProfile(profileEmail, catalog, allProfileList, setAllProfileList);
  };

  const handleRemoveCatalogFromProfile = async (profileEmail, catalog) => {
    await removeCatalogFromProfile(profileEmail, catalog, allProfileList, setAllProfileList);
  };

  const [loadingMessages, setLoadingMessage] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const defaultDateRange = {
    startDate,
    endDate,
    key: "selection",
  };
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [totalOperations, setTotalOperations] = useState(0);
  const [completedOperations, setCompletedOperations] = useState(0);
  const incrementCompletedOperations = () => {
    setCompletedOperations(prevCompleted => prevCompleted + 1);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const handleDateRangeChangeMetrics = (ranges) => {
    setDateRangeMetrics(ranges.selection);
  };

  const handleCloseAddClientModal = () => {
    setIsModalOpen(false);
    setModalType('');
  };

  const toggleDateModal = () => {
    setIsDateModalOpen(!isDateModalOpen);
  };

  const toggleDateModalMetrics = () => {
    setIsDateModalOpenMetrics(!isDateModalOpenMetrics);
  };

  const handleCloseAddSubsModal = () => {
    setIsModalOpenSubscription(false);
    setModalType('');
  };

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const clientSnapshot = await db.collection('Client').get();
        const clientsList = clientSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        await Promise.all(
          clientsList.map(async client => {
            if (client.licenseList && Array.isArray(client.licenseList)) {
              const licensePromises = client.licenseList.map(async licenseId => {
                const licenseDoc = await db.collection('ARShades_Subscription').doc(licenseId).get();
                const licenseData = licenseDoc.data();
                return { id: licenseId, ...licenseData };
              });
              client.licenseDataList = await Promise.all(licensePromises);
            } else {
              client.licenseDataList = [];
            }
            return client;
          })
        );

        setClients(clientsList);
      } catch (err) {
        console.error('Error fetching clients:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const fetchProfileList = async client => {
    try {
      if (!client || !client.allProfileList) {
        setAllProfileList([]);
        return;
      }

      const promises = client.allProfileList.map(async profileRef => {
        const profileDoc = await db.collection('Profile').doc(profileRef.toLowerCase()).get();
        return profileDoc.data();
      });

      const profiles = await Promise.all(promises);
      setAllProfileList(profiles);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setAllProfileList([]);
    }
  };

  const toggleModal = (client, type, isMainSubscription, licenseId, subscriptionType) => {

    setModalType(type);
    setIsModalOpen(type === 'addClient');
    setIsModalOpenSubscription(type === 'addSubscription');
    if (type === 'addProfile') {
      setClientToAddProfile(client);
    } else if (type === 'addSubscription') {
      setClientToAddSubscription(client);
      setRiferimentoTipo(isMainSubscription ? 'main' : 'additional');
      setLicenseId(licenseId);
      setClientId(client ? client.id : '');
      setSubscriptionType(subscriptionType);
    }
  };

  const fetchBrandDetails = async license => {
    try {
      const brandDoc = await db.collection('ARShades_Subscription').doc(license).get();
      if (brandDoc.exists) {
        setBrandDetails(brandDoc.data());
      } else {
        console.error(`Brand document not found for license: ${license}`);
        setBrandDetails({});
      }
    } catch (error) {
      console.error('Error fetching brand details:', error);
      setBrandDetails({});
    }
  };

  const toggleModal2 = (client) => {
    setIsModalOpenProfile(!isModalOpenProfile);
    setClientToAddProfile(client);
  };

  const toggleModal3 = (client) => {
    setIsModalOpenBrand(!isModalOpenBrand);
    setClientToAddBrand(client);
  };

  const toggleExpand = async (clientId) => {
    if (expandedClientId === clientId) {
      setExpandedClientId(null);
      setAllProfileList([]);
    } else {
      setExpandedClientId(clientId);
      await fetchProfileList(clients.find(client => client.id === clientId));
    }
  };

  const handleAddButtonClick = async (client, license) => {
    await fetchBrandDetails(license);
    toggleModalDetails();
  };

  const toggleModalDetails = () => {
    setIsModalOpenDetails(!isModalOpenDetails);
  };

  const toggleLicenseDetails = async license => {
    if (expandedLicense === license) {
      setExpandedLicense(null);
    } else {
      setExpandedLicense(license);
      await fetchBrandDetails(license);
    }
  };

  const handleAddNewMainSubscription = (data) => {
    addNewSubscription(data);
    // console.log("CALL NEW SUBSCRIPTION", data);
  };

  const handleAddProfile = async (profileData) => {
    try {
      setAddingProfile(true);
      const success = await addNewProfile(profileData);
      if (success) {
        // console.log('Profile added successfully:', profileData);
      }
      return success;
    } catch (error) {
      console.error('Error adding profile:', error);
      return false;
    } finally {
      setAddingProfile(false);
    }
  }

  // deleteUserMetrics, handleClickMetrics e sleep SERVONO PER ELIMINARE USER_METRICS
  const deleteUserMetrics = async (startDate, endDate) => {
    // console.log('Inizio cancellazione user_metrics.');

    const sessionCollection = db.collection('Sessione');
    let successCount = 0;
    let lastDoc = null;
    const batchSize = 100;

    try {
      // Conta il numero totale di documenti
      let totalDocsCount = 0;
      let query = sessionCollection
        .where('data_inizio_sessione', '>=', startDate)
        .where('data_inizio_sessione', '<=', endDate);
      const snapshot = await query.get();
      totalDocsCount = snapshot.docs.length;

      // Imposta totalOperations2
      setTotalOperations2(totalDocsCount);

      while (true) {
        let query = sessionCollection
          .where('data_inizio_sessione', '>=', startDate)
          .where('data_inizio_sessione', '<=', endDate) // Aggiunta della clausola per endDate
          .orderBy('data_inizio_sessione')
          .limit(batchSize);

        if (lastDoc) {
          query = query.startAfter(lastDoc);
        }

        const snapshot = await query.get();
        const totalDocs = snapshot.docs.length;

        if (totalDocs === 0) {
          break;
        }

        // console.log(`Totale documenti da cancellare in questo batch: ${totalDocs}`);

        for (let i = 0; i < totalDocs; i++) {
          const doc = snapshot.docs[i];
          const docRef = sessionCollection.doc(doc.id);

          // Controllo sulla data_inizio_sessione per non superare endDate
          const dataInizioSessione = doc.data().data_inizio_sessione;
          if (dataInizioSessione > endDate) {
            // console.log(`Documento con ID: ${doc.id} ha data_inizio_sessione oltre la data di fine.`);
            break; // Interrompi il ciclo for, nessun altro documento è valido
          }

          // Verifica se la proprietà 'user_metrics' esiste
          if (!doc.data().hasOwnProperty('user_metrics')) {
            // console.log(`Il documento con ID: ${doc.id} non ha la proprietà 'user_metrics'.`);
            continue; // Salta al prossimo documento
          }

          try {
            await docRef.update({
              user_metrics: firebase.firestore.FieldValue.delete()
            });
            successCount++;

            // Log per l'ID del documento
            // console.log(`Cancellata la proprietà 'user_metrics' dal documento con ID: ${doc.id}`);

            // Aggiorna il contatore delle operazioni completate
            setCompletedOperations2(successCount);

          } catch (updateError) {
            console.error(`Errore durante l'aggiornamento del documento ${doc.id}:`, updateError);
          }

          // Aggiungi un ritardo per evitare sovraccarichi di Firestore
          await sleep(200); // Puoi regolare il ritardo in base alle tue esigenze
        }

        // Imposta lastDoc all'ultimo documento del batch corrente
        lastDoc = snapshot.docs[snapshot.docs.length - 1];
      }

      // console.log(`Operazioni completate. Successo: ${successCount}`);

    } catch (error) {
      console.error('Errore durante la cancellazione della proprietà user_metrics:', error);
    }

    // console.log('Fine cancellazione user_metrics.');

    return successCount; // Restituisci il conteggio di successo
  };
  const handleClickMetrics = async () => {

    const formattedDateStart = format(dateRangeMetrics.startDate, "yyyy/MM/dd");
    const formattedDateEnd = format(dateRangeMetrics.endDate, "yyyy/MM/dd");

    const startDate = new Date(formattedDateStart);
    startDate.setHours(0, 0, 1);

    const endDate = new Date(formattedDateEnd);
    endDate.setHours(23, 59, 59);

    const startDateTwo = format(startDate, "yyyy/MM/dd 00:00:00");
    const endDateTwo = format(endDate, "yyyy/MM/dd 23:59:59");

    // console.log("Start Date", startDateTwo)
    // console.log("End Date", endDateTwo)

    setDownloadLoading2(true);
    setCompletedOperations2(0); // Resetta il contatore delle operazioni completate

    try {
      // Format the dates
      const formattedStartDate = format(startDate, 'yyyy/MM/dd HH:mm:ss');
      const formattedEndDate = format(endDate, 'yyyy/MM/dd HH:mm:ss');

      let successCount = await deleteUserMetrics(formattedStartDate, formattedEndDate);

      // Imposta totalOperations2 su successCount dopo che deleteUserMetrics è completato
      setTotalOperations2(successCount);

    } catch (error) {
      console.error('Errore durante l\'esecuzione delle operazioni:', error);
    }

    setDownloadLoading2(false);
  };
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  // Questa funzione trasferisce dati dalla collezione "Coll_Account" alla collezione
  // "Profile" nel database. Per ogni documento in "Coll_Account", cerca e aggiorna informazioni correlate 
  // in altre collezioni come Dashboard_Notification, Lista_Notification, e Log_Loading_Models

  async function transferProfileCollection() {
    const collectionsForModification = ['Dashboard_Notification', 'Lista_Notification', 'Log_Loading_Models'];
    const collectionsProps = ['account', 'accRef', 'refModeller'];
    setExecute3(true);

    // console.log("Inizio trasferimento profili...");

    const coll = await db.collection("Coll_Account").get();

    coll.docs.forEach((doc) => {
      const data = doc.data();
      // console.log(`Elaborazione documento ${doc.id}...`);

      db.collection('Profile')
        .doc(doc.id)
        .get().then((res) => {
          // console.log(`Profilo trovato per documento ${doc.id}.`);

          collectionsForModification.forEach((collection, index) => {
            if (data != null) {
              // console.log(`Aggiornamento collezione ${collection} con proprietà ${collectionsProps[index]}...`);

              db.collection(collection)
                .where(collectionsProps[index], '==', doc.ref)
                .get()
                .then(async (acc) => {
                  acc.docs.forEach(async (notif) => {
                    // console.log(`Documento trovato in collezione ${collection}: ${notif.id}`);

                    if (collection == 'Log_Loading_Models') {
                      // console.log(`Modifica log per ${collection}...`);

                      const logGlassList = notif.data().lista_log_Glasses;
                      const logVarList = notif.data().lista_log_variant;
                      const newGlassList = [];
                      const newVarList = [];

                      for (let i = 0; i < logGlassList.length; i++) {
                        const glass = logGlassList[i];
                        const email = extractEmailFromReference(glass.account.path);
                        const accRef = await db.collection('Profile')
                          .doc(email)
                          .get();
                        glass.account = accRef.ref;
                        newGlassList.push(glass);
                      }

                      for (let i = 0; i < logVarList.length; i++) {
                        const glass = logVarList[i];
                        const email = extractEmailFromReference(glass.account.path);
                        const accRef = await db.collection('Profile')
                          .doc(email)
                          .get();
                        glass.account = accRef.ref;
                        newVarList.push(glass);
                      }

                      notif.ref.set({
                        [`${collectionsProps[index]}`]: res.ref,
                        lista_log_Glasses: newGlassList,
                        lista_log_variant: newVarList,
                      });

                      // console.log(`Documento ${notif.id} aggiornato per ${collection}.`);
                    } else {
                      notif.ref.update({
                        [`${collectionsProps[index]}`]: res.ref
                      });

                      // console.log(`Documento ${notif.id} aggiornato per ${collection}.`);
                    }
                  });

                  setExecute3(false);
                  // console.log("Operazione completata.");
                });
            }
          });
        });
    });
  }

  async function checkIfModelExistAndCount(url, props, type) {

    if (url != null || url != "") {
      props.modelSize = await calculateModelSize(url);
      props.sum += props.modelSize;
      props.totalModellos += 1;
      props[`${type}Size`] = props.modelSize;
    }
    return props
  }

  async function calculateModelSize(imageUrl) {
    const CONVERTER_CONST = 1000000;
    try {
      // Fetch the image
      // console.log(imageUrl);
      let imageSize = 0;
      const response = await fetch(imageUrl, { method: 'HEAD' });
      if (response.ok) {
        // Get the content length from the headers
        const contentLength = response.headers.get('content-length');
        imageSize = contentLength ? parseInt(contentLength) : 0;
      } else {
        // Handle the case when the request was not successful
        console.error('Error fetching image:', response.statusText);

      }
      // Calculate the size of the image

      return imageSize / CONVERTER_CONST;
    } catch (error) {
      // console.log(error)
      return false;
    }
  };

  async function transferProfileCollection() {
    const collectionsForModification = ['Dashboard_Notification', 'Lista_Notification', 'Log_Loading_Models']
    const collectionsProps = ['account', 'accRef', 'refModeller']
    const coll = await db.collection("Coll_Account").get();
    coll.docs.forEach((doc) => {
      const data = doc.data();
      db.collection('Profile')
        .doc(doc.id)
        .get().then((res) => {
          collectionsForModification.forEach((collection, index) => {

            if (data != null) {
              db.collection(collection).
                where(collectionsProps[index], '==', doc.ref)
                .get()
                .then((acc) => {

                  acc.docs.forEach((notif) => {

                    notif.ref.update({
                      [`${collectionsProps[index]}`]: res.ref
                    });

                  })
                });
            }
          })

        });
    })
  }

  function extractEmailFromReference(referenceString) {
    // Split the reference string using the forward slash (/) delimiter
    const parts = referenceString.split('/');

    // Assuming the email is the last part after the collection name
    if (parts.length > 1) {
      return parts[parts.length - 1];
    } else {
      // Handle cases where the reference string might not be in the expected format
      console.warn("Invalid reference format:", referenceString);
      return null; // Or return an empty string or any default value
    }
  }

  async function updateAccountReference(docRef, accountId) {
    try {
      // Extract the current account ID (assuming it's part of the 'data.account' value)


      // Replace 'Coll_Account' with 'Profile' (assuming your logic constructs the reference based on ID)
      const newAccountRef = db.collection('Profile').doc(accountId);

      await docRef.update({
        account: newAccountRef,
      });
      // console.log("Account reference updated successfully for document:", docRef.id);
    } catch (error) {
      console.error("Error updating account reference:", error);
    }
  }

  function extractEmailFromReference(referenceString) {
    // Split the reference string using the forward slash (/) delimiter
    const parts = referenceString.split('/');

    // Assuming the email is the last part after the collection name
    if (parts.length > 1) {
      return parts[parts.length - 1];
    } else {
      // Handle cases where the reference string might not be in the expected format
      console.warn("Invalid reference format:", referenceString);
      return null; // Or return an empty string or any default value
    }
  }

  async function updateAccountReference(docRef, accountId) {
    try {
      // Extract the current account ID (assuming it's part of the 'data.account' value)


      // Replace 'Coll_Account' with 'Profile' (assuming your logic constructs the reference based on ID)
      const newAccountRef = db.collection('Profile').doc(accountId);

      await docRef.update({
        account: newAccountRef,
      });
      // console.log("Account reference updated successfully for document:", docRef.id);
    } catch (error) {
      console.error("Error updating account reference:", error);
    }
  }

  // Per ogni documento nella collezione "Brand" con main_brand true, il codice ottiene
  // i modelli dalla collezione "Modello" basati sul campo codiceVariante del marchio, 
  // conta e aggiorna le URL dei file 3D dei modelli, e aggiorna i consumi di storage nel database per il marchio.
  const runDbUpdate = async () => {
    setExecute2(true);

    const brand_list = await db.collection("Brand").get();
    const date = new Date();
    // console.log(brand_list.docs);
    try {
      //loop through brand list
      for (let i = 0; i < brand_list.size; i++) {

        const brandSnapshot = brand_list.docs[i];
        const brandRef = db.collection('Brand').doc(brandSnapshot.id);
        const brand = brandSnapshot.data();

        //asign main brand
        // brand.mainBrand  = !revKeyWords.some(keyword => brand.nome_brand.toLowerCase().includes(keyword.toLowerCase()));
        let props = { sum: 0, totalModellos: 0, modelSize: 0 }
        // console.log(brand.nome_brand);
        // console.log(brand.mainBrand);
        if (brand.main_brand) {
          const modellos = await db.collection("Modello")
            .where('codiceVariante', '>=', brand.code_path)
            .where('codiceVariante', '<=', brand.code_path + '\uf8ff')
            .get();

          //loop through each modello
          for (let i = 0; i < modellos.size; i++) {
            const modelShot = modellos.docs[i];
            const model = modelShot.data();
            const modelRef = db.collection('Modello').doc(modelShot.id);

            await checkIfModelExistAndCount(model.urlGlbComplete, props, 'urlGlbComplete');
            await checkIfModelExistAndCount(model.urlGlb, props, 'urlGlb');
            await checkIfModelExistAndCount(model.urlUsdz, props, 'urlUsdz');
            await checkIfModelExistAndCount(model.urlUsdzComplete, props, 'urlUsdzComplete');

            await updateModel(model, modelRef, props);
          }
          // console.log(sum, totalModellos);

          await brandRef.update({ "storage_consumption": { "consumption": parseFloat(props.sum.toFixed(2)), "modelsNumber": props.totalModellos } });
        }
        setExecute2(false)
      };

    } catch (err) {
      // console.log(err);
    }
  }


  // Per ogni documento nella collezione "Brand", ottiene il documento e il suo riferimento,
  // verifica se il campo nome_brand contiene una delle parole chiave (revKeyWords). Se il campo
  // non contiene nessuna delle parole chiave, imposta main_brand a true; 
  // altrimenti, lo imposta a false e aggiorna il documento nel database.
  const runMainBrandDbUpdate = async () => {

    setExecute(true)
    const brand_list = await db.collection("Brand").get();
    const date = new Date();
    // console.log(brand_list.docs);
    try {
      //used to ensure codepath is not repeated
      const revKeyWords = ["SHADOW", "REV", "DUCATI"];
      //loop through brand list
      for (let i = 0; i < brand_list.size; i++) {

        const brandSnapshot = brand_list.docs[i];
        const brandRef = db.collection('Brand').doc(brandSnapshot.id);
        const brand = brandSnapshot.data();

        //asign main brand
        brand.main_brand = !revKeyWords.some(keyword => brand.nome_brand.toLowerCase().includes(keyword.toLowerCase()));

        await brandRef.update(brand);

      };
      // console.log("Job completed");
      setExecute(false)
    } catch (err) {
      // console.log(err);
    }
  }

  //stores & update model on firebase
  const updateModel = async (model, modelRef, props) => {
    model.urlGlbSize = props.urlGlbSize;
    model.urlGlbCompleteSize = props.urlGlbCompleteSize;
    model.urlUsdzSize = props.urlUsdzSize;
    model.urlUsdzCompleteSize = props.urlUsdzCompleteSize;
    await modelRef.update(model);
    //  
  }

  async function checkIfModelExistAndCount(url, props, type) {

    if (url != null || url != "") {
      props.modelSize = await calculateModelSize(url);
      props.sum += props.modelSize;
      props.totalModellos += 1;
      props[`${type}Size`] = props.modelSize;
    }
    return props
  }

  async function calculateModelSize(imageUrl) {
    const CONVERTER_CONST = 1000000;
    try {
      console.log('Fetching image:', imageUrl);
      let imageSize = 0;
      const response = await fetch(imageUrl, { method: 'HEAD' });

      if (response.ok) {
        const contentLength = response.headers.get('content-length');
        imageSize = contentLength ? parseInt(contentLength) : 0;
      } else {
        console.error('Error fetching image:', response.status, response.statusText);
        return false; // Early return on fetch error
      }

      // Calculate the size of the image
      return imageSize / CONVERTER_CONST;
    } catch (error) {
      console.error('Exception occurred:', error);
      return false;
    }
  }

  return (
    <>
      <div className="chart-header">
        <p className="chart-header-text">ADMIN CONFIGURATION</p>
      </div>
      <div className="p-10">
        <div className="row p-3">
          <div className="col text-strong h5" data-toggle="tooltip" data-placement="top" title="Ensures Each Brands is either a main brand or not">
            Execute Main Brand
          </div>
          <div className='col'>
          </div>
          <div className="col">
            <Button
              onClick={runMainBrandDbUpdate}
              disabled={executing}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {executing && <Spinner size="sm" />}
              Execute
            </Button>
          </div>
        </div>
        <div className="row p-3">
          <div className="col h5" data-toggle="tooltip" data-placement="top" title="Execute Data Consumption For Existing Data Consumption">
            Update Data Storage
          </div>
          <div className='col'>
          </div>
          <div className="col">
            <Button
              onClick={runDbUpdate}
              disabled={executing2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {executing2 && <Spinner size="sm" />}
              Execute
            </Button>
          </div>
        </div>

        <div className='row p-3'>
          <div className='col h5' data-toggle="tooltip" data-placement="top" title='Migrates the DB from Account Collection to Profile Collection'>Move Col_Account to Profile</div>
          <div className='col'>
          </div>
          <div className='col'>
            <Button
              onClick={() => transferProfileCollection()}
              disabled={executing3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              {executing3 ? <Spinner size="sm" /> : null}
              Execute
            </Button>
          </div>
        </div>

        <div className='row p-3'>
          <div className='col h5' data-toggle="tooltip" data-placement="top" title='Migrates the DB from Account Collection to Profile Collection'>
            Delete User Metrics
          </div>
          <div className='col'>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <FormGroup style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Button color="primary" onClick={() => toggleDateModalMetrics()} style={{ width: "100%" }}>
                    {dateRangeMetrics
                      ? format(new Date(dateRangeMetrics.startDate), 'dd MMM, yyyy') +
                      ' - ' +
                      format(new Date(dateRangeMetrics.endDate), 'dd MMM, yyyy')
                      : 'Select Date Range'}
                  </Button>
                </FormGroup>
              </div>
            </div>
          </div>
          <div className='col'>
            <Button
              onClick={handleClickMetrics}
              disabled={downloadLoading2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              {downloadLoading2 ? <Spinner size="sm" /> : null}
              {downloadLoading2 ? `Executing ${totalOperations2 > 0 ? Math.round((completedOperations2 / totalOperations2) * 100) : 0}%` : 'Execute'}
            </Button>
          </div>
        </div>

        <div className='row p-3'>
          <div className='col h5' data-toggle="tooltip" data-placement="top" title='Run Aggregate Data for Session'>Session to Aggregate Data</div>
          <div className='col'>
            {/* <Label for="selectorInput">Select Date Range</Label> */}
            <FormGroup style={{ display: "flex", flexDirection: "column" }}>
              <Button color="primary" onClick={() => toggleDateModal()}>
                {dateRange
                  ? format(new Date(dateRange.startDate), 'dd MMM, yyyy') +
                  ' - ' +
                  format(new Date(dateRange.endDate), 'dd MMM, yyyy')
                  : 'Select Date Range'}
              </Button>
            </FormGroup>
          </div>
          <div className='col'>
            <Button
              onClick={() => aggregateDataForAllBrands(dateRange.startDate, dateRange.endDate, setLoadingMessage, () => { }, incrementCompletedOperations, {}, setTotalOperations, setExecute4)}
              disabled={executing4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              {executing4 ? <Spinner size="sm" /> : null}
              Execute {totalOperations > 0 ? Math.round((completedOperations / totalOperations) * 100) + '%' : ''}
            </Button>
          </div>
        </div>

      </div>
      <div className="client-list-container">
        <div className="chart-header">
          <p className="chart-header-text">CLIENT LIST</p>
        </div>
        <div className="client-table">
          {loading ? (
            <Spinner />
          ) : (
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: 'white' }}>
                  <th style={{ backgroundColor: 'white', color: 'black' }}>Client ID</th>
                  <th style={{ backgroundColor: 'white', color: 'black' }}>Company Name</th>
                  <th style={{ backgroundColor: 'white', color: 'black' }} className="text-right">Subscription</th>
                </tr>
              </thead>
              <tbody>
                {clients.map(client => (
                  <React.Fragment key={client.id}>
                    <tr onClick={() => toggleExpand(client.id)}>
                      <td>{client.id}</td>
                      <td>{client.companyName}</td>
                      <td className="text-right">
                        <Button
                          color="primary"
                          onClick={() => toggleModal(client, 'addSubscription', true)}
                        >
                          Add Main Subscription
                        </Button>
                      </td>
                    </tr>
                    {/* EXPANDED WINDOW */}
                    {expandedClientId === client.id && (
                      <tr key={`${client.id}-details`}>
                        <td colSpan="1">
                          <h5 style={{ fontStyle: 'italic' }}>Client Details</h5>
                          <p>Client ID: <strong>{client.id}</strong></p>
                          <p>Company Name: <strong>{client.companyName}</strong></p>
                          <h5 style={{ fontStyle: 'italic' }}>Profile List</h5>

                          {/* ADD & DELETE CATALOGS IN PROFILE */}
                          {allProfileList.length > 0 ? (
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              {allProfileList.map(profile => (
                                <li key={profile._email} style={{ marginBottom: '16px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <p style={{ marginRight: '8px' }}>Profile: <strong>{profile._email}</strong></p>
                                    <MdEdit
                                      style={{
                                        fontSize: '1.2em',
                                        cursor: 'pointer',
                                        color: '#007bff'
                                      }}
                                      onClick={() => toggleProfileModal(profile, client)}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p>No profiles found for this client.</p>
                          )}

                          <Button color="primary" onClick={() => toggleModal2(client)} disabled={addingProfile}>
                            Add Profile
                          </Button>
                        </td>
                        <td colSpan="3">
                          <h5 style={{ fontStyle: 'italic' }}>License List</h5>
                          {client.licenseDataList && client.licenseDataList.length > 0 ? (
                            <ul>
                              {client.licenseDataList.map(license => (
                                <li key={license.id}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p>License: {license.subscriptionType ? license.subscriptionType : 'N/A'} - <strong>({license.id})</strong></p>
                                    <Button
                                      color="secondary"
                                      onClick={() => toggleModal(client, 'addSubscription', false, license.id, license.subscriptionType)}
                                    >
                                      Add Additional Subs
                                    </Button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p>No licenses found for this client.</p>
                          )}
                          {/* Condizione per mostrare/nascondere il pulsante "Add Brand" */}
                          {client.licenseDataList && client.licenseDataList.length > 0 && (
                            <>
                              <h5 style={{ fontStyle: 'italic' }}>Add Brand</h5>
                              <Button color="secondary" onClick={() => toggleModal3(client)}>
                                Add Brand
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <div className="add-client-button" style={{ display: 'flex', justifyContent: 'center',marginTop:10, marginBottom:20 }}>
          <Button color="primary" onClick={() => toggleModal(null, 'addClient')}>Add New Client & Profile</Button>
        </div>
      </div>
      <DbTestComponent />
      {/* modal to add client */}
      <Modal isOpen={isModalOpen && modalType === 'addClient'} toggle={handleCloseAddClientModal}>
        <ModalHeader toggle={handleCloseAddClientModal}>Add New Client</ModalHeader>
        <ModalBody>
          <AddClientModal existingClient={null} handleCloseModal={handleCloseAddClientModal} />
        </ModalBody>
      </Modal>

      {/* modal to add subscription */}
      <Modal isOpen={isModalOpenSubscription && modalType === 'addSubscription'} toggle={handleCloseAddSubsModal}>
        <ModalHeader toggle={handleCloseAddSubsModal}>Add New Subscription</ModalHeader>
        <ModalBody>
          <AddSubscriptionModal
            addNewSubscription={handleAddNewMainSubscription}
            handleCloseModal={handleCloseAddSubsModal}
            riferimentoTipo={riferimentoTipo}
            licenseId={licenseId}
            clientId={clientId}
            subscriptionType={subscriptionType}
          />
        </ModalBody>
      </Modal>

      {/* modal to add profile */}
      <Modal isOpen={isModalOpenProfile} toggle={() => setIsModalOpenProfile(false)}>
        <ModalHeader toggle={() => setIsModalOpenProfile(false)}>Add Profile</ModalHeader>
        <ModalBody>
          {clientToAddProfile && (
            <AddProfileForm
              clientRef={clientToAddProfile.id}
              handleCloseModal={() => setIsModalOpenProfile(false)}
              handleAddProfile={handleAddProfile}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpenProfile(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Modal to add brand */}
      <Modal isOpen={isModalOpenBrand} toggle={() => setIsModalOpenBrand(false)}>
        <ModalHeader toggle={() => setIsModalOpenBrand(false)}>Add Brand</ModalHeader>
        <ModalBody>
          {clientToAddBrand && (
            <AddBrandForm
              client={clientToAddBrand}
              handleCloseModal={() => setIsModalOpenBrand(false)}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpenBrand(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Modal to add & delete profile catalogs */}
      <Modal isOpen={isModalOpenProfileCatalogs} toggle={() => setIsModalOpenProfileCatalogs(false)}>
        <ModalHeader toggle={() => setIsModalOpenProfileCatalogs(false)}>CATALOGS</ModalHeader>
        <ModalBody>
          {allProfileList.length > 0 && allProfileList.map(profile => (
            <div key={profile._email}>
              <h5>Profile Catalogs</h5>
              <ul style={{ listStyleType: 'disc', paddingLeft: '16px' }}>
                {profile.catalogsList.map((catalog, index) => (
                  <li key={index}>
                    {catalog} - {brandNames[catalog] || 'Loading...'}
                    <Button
                      color="link"
                      size="sm"
                      style={{ marginLeft: '8px' }}
                      onClick={() => handleRemoveCatalogFromProfile(profile._email, catalog)}
                    >
                      <MdRemove style={{ fontSize: '1.2em' }} />
                    </Button>
                  </li>
                ))}
              </ul>
              <hr style={{ margin: '8px 0' }} />
              <h5>Client Catalogs</h5>
              <ul style={{ listStyleType: 'disc', paddingLeft: '16px' }}>
                {clientToAddProfile && clientToAddProfile.catalogsList
                  .filter(catalog => !profile.catalogsList.includes(catalog))
                  .map((catalog, index) => (
                    <li key={index}>
                      {catalog} - {brandNames[catalog] || 'Loading...'}
                      <Button
                        color="link"
                        size="sm"
                        style={{ marginLeft: '8px' }}
                        onClick={() => handleAddCatalogToProfile(profile._email, catalog)}
                      >
                        <MdAdd style={{ fontSize: '1.2em' }} />
                      </Button>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpenProfileCatalogs(false)}>Close</Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isDateModalOpen}
        centered
        size="lg"
        toggle={toggleDateModal}
      >
        <ModalHeader
          className="text-primary"
          charCode="&times;"
          toggle={toggleDateModal}
        >
          Select Date Range
        </ModalHeader>
        <ModalBody>
          <DateRangePicker
            className="d-flex"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#41b1ac", "#41b1ac"]}
            ranges={[dateRange || defaultDateRange]}
            direction="horizontal"
            maxDate={new Date()}
            onChange={handleDateRangeChange}
          />
        </ModalBody>
      </Modal>
      {/* MODALE X ELIMINARE USER_METRICS */}
      <Modal
        isOpen={isDateModalOpenMetrics}
        centered
        size="lg"
        toggle={toggleDateModalMetrics}
      >
        <ModalHeader
          className="text-primary"
          charCode="&times;"
          toggle={toggleDateModalMetrics}
        >
          Select Date Range
        </ModalHeader>
        <ModalBody>
          <DateRangePicker
            className="d-flex"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#41b1ac", "#41b1ac"]}
            ranges={[dateRangeMetrics || defaultDateRangeMetrics]}
            direction="horizontal"
            maxDate={new Date()}
            onChange={handleDateRangeChangeMetrics}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default AdminView;