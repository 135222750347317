import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import app, { db } from "../../../data/base";
import avatar from "../../../assets/images/user/10.jpg";
import { UserInfoForm } from "./UserInfoForm";
import MFA from "../../../pages/MFA/MFA";

import firebase from 'firebase/app';
import 'firebase/auth';
import { FiCamera } from "react-icons/fi";
import { AssociatedBrands } from "./AssociatedBrands";
import { uploadImage } from "../../../services/profile";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setProfileImage } from "../../../redux/profile/actions";

const UserProfile = () => {
  const dispatch = useDispatch();
  const photoURL = useSelector((reducer) => reducer.profile.profileImage);

  const [brandReference, setBrandReference] = useState();
  const [brandData, setBrandData] = useState([]);
  const [dataConsumptionId, setDataConsumptionId] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  const [currentUser, setCurrentUser] = useState();

  const [isImageLoading, setImageLoading] = useState(false);

  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordFieldsValid, setPasswordFieldsValid] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const refClient = localStorage.getItem("ref_cliente");
        // console.log('Ref Client', refClient);

        const clientiRef = await db
          .collection("Client")
          .doc(localStorage.getItem("ref_cliente")?.trim())
          .get();
        const clientData = clientiRef.data();

        const profileId = localStorage.getItem("email").toLowerCase();
        const profileRef = await db.collection("Profile").doc(profileId).get();
        const profileData = profileRef.data();

        const temp = {
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          company: clientData.companyName,
          role: profileData.role,
          email: profileId,
          collectionId: clientData.id
        };

        setUserInfo(temp);
        setBrandReference(clientData.catalogsList);
        dispatch(setProfileImage(clientData.photoURL));

        app.auth().onAuthStateChanged((user) => {
          if (user) {
            setCurrentUser(user);
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  async function changeAvatar(e) {
    try {
      setImageLoading(true);

      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      const avtImg = URL.createObjectURL(e.target.files[0]);

      uploadImage(file)
        .then(async (downloadURL) => {
          const collectionRef = db?.collection("Client");
          const docRef = collectionRef?.doc(userInfo.collectionId);
          await docRef.update({ photoURL: downloadURL });
          dispatch(setProfileImage(avtImg));
          toast.success("Image Updated Successfully!");
        })
        .catch(() => {
          throw new Error();
        });
    } catch {
      toast.error("Error while updating profile image!");
    } finally {
      setImageLoading(false);
    }
  }

  useEffect(() => {
    const isPasswordValid = currentPassword !== '' && newPassword !== '' && repeatPassword !== '';
    setPasswordFieldsValid(isPasswordValid);
  }, [currentPassword, newPassword, repeatPassword]);

  async function changePassword() {
    try {
      if (newPassword !== repeatPassword) {
        throw new Error("Le nuove password non corrispondono");
      }

      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await user.reauthenticateWithCredential(credential);
      await user.updatePassword(newPassword);

      setCurrentPassword("");
      setNewPassword("");
      setRepeatPassword("");

      toast.success("Password changed successfully!");
    } catch (error) {
      toast.error(error.message);
      console.error("Error changing password:", error);
    }
  }

  function triggerAvatarChange() {
    document.getElementById("avt-change").click();
  }

  return (
    <Fragment>
      <Container>
        {/* Codice per l'immagine del profilo omesso per brevità */}

        {/* Personal Information */}
        <div className="shadow-sm m-t-20">
          <div
            className="p-20 rounded text-white"
            style={{ backgroundColor: "#42B1AC" }}
          >
            <h5>Personal Information</h5>
          </div>
          <UserInfoForm {...{ userInfo, setUserInfo }} />

          <div style={{ marginTop: "40px" }} />

          {/* Change Password */}
          <div style={{ maxWidth: "auto", marginLeft: "20px" }}>
            <div className="form-group row align-items-center">
              <label className="col-sm-2 col-form-label text-left">Current Password:</label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  type="password"
                  style={{ width: "60%", background: "#f2f2f2" }}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-sm-2 col-form-label text-left">New Password:</label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  style={{ width: "60%", background: "#f2f2f2" }}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-sm-2 col-form-label text-left">Repeat New Password:</label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  placeholder="Repeat New Password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  type="password"
                  style={{ width: "60%", background: "#f2f2f2" }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12 d-flex justify-content-center">
                <button
                  className="btn btn-primary mb-3"
                  onClick={changePassword}
                  disabled={!passwordFieldsValid}
                  style={{ marginRight: '300px' }}
                >
                  Save Password
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Associated Brands */}
        <AssociatedBrands userInfo={userInfo} />

        {/* MFA */}
        <MFA />

      </Container>
    </Fragment>
  );
};

export default UserProfile;