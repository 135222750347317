import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const modelGlasses = createSlice({
  name: "modelGlasses",
  initialState,
  reducers: {
    setModel(state, action) {
        const {data, id} = action.payload;
        const newProject = {...data, id};
        state.push(newProject);
    },
  },
});

export const modelGlassesActions = modelGlasses.actions;

export default modelGlasses.reducer;
