import React, { useEffect, useState } from "react";
import { format, parseISO, eachDayOfInterval } from "date-fns";
import { getAggregatedData } from "./Helper";

const brandIds = [
  "1oMUuJ4GHSvmIFjgflqr",
  "4IWjEk506qLdof6Pg0Kz",
  "BMgDR0wjvt0h3ECwmAuc",
  "FEX8MmzGOFadGcO2JHSf",
  "ItAD8DChVjJ7V02qAMSn",
  "Nqyd5EKyrBb3fufpJyKS",
  "UPgshXfGThBLfqZyrcxZ",
  "USamwu7Ywd2oNAU5eL50",
  "dIMv4ZLU5123eroWzVmE",
  "qmDTd8ciaSs31akT3Eqq",
  "rBf3geYISXtkue0WGgFT",
  "rxnBLnp736gqjFBNLfdS"
];

const AggregatedDataTable = () => {
  const [data, setData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("2024-04-01");
  const [endDate, setEndDate] = useState("2024-04-01");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [totals, setTotals] = useState({
    totalUniqueUsers: 0,
    totalSessions: 0,
    newUsersCount: 0,
    avgSessionTimeMinutes: 0,
    avgSessionTimeSeconds: 0,
    avgEngagementTimeMinutes: 0,
    avgEngagementTimeSeconds: 0,
    avgSessionsPerUser: 0
  });
  const [deviceData, setDeviceData] = useState([]);

  const fetchData = async (startDate, endDate, brandIds) => {
    setLoading(true);
    setError(null);

    try {
      const aggregatedData = await getAggregatedData(brandIds, startDate, endDate);
      setData(aggregatedData);
      calculateTotals(aggregatedData);
      calculateDailyData(aggregatedData);
      calculateLineData(aggregatedData);
      calculateDeviceData(aggregatedData); // Nuova funzione per calcolare deviceData

    } catch (err) {
      setError("Errore nel recupero dei dati aggregati");
      console.error(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData(startDate, endDate, brandIds);
  }, []);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
  };

  const handleSubmit = () => {
    const brandsToQuery = selectedBrand ? [selectedBrand] : brandIds;
    fetchData(format(new Date(startDate), "yyyy/MM/dd"), format(new Date(endDate), "yyyy/MM/dd"), brandsToQuery);
  };

  const calculateDeviceData = (data) => {
    const deviceDataMap = data.reduce((acc, entry) => {
        entry.deviceData.forEach(device => {
            if (!acc[device.type]) {
                acc[device.type] = {
                    count: 0,
                    uniqueDevices: 0,
                    visualizations: 0
                };
            }
            acc[device.type].count += device.count;
            acc[device.type].uniqueDevices += device.uniqueDevices;
            acc[device.type].visualizations += device.visualizations;
        });
        return acc;
    }, {});

    const deviceDataArray = Object.keys(deviceDataMap).map(type => ({
        type,
        ...deviceDataMap[type]
    }));

    // Calcola i totali
    const totalDeviceData = deviceDataArray.reduce((acc, device) => {
        acc.count += device.count;
        acc.uniqueDevices += device.uniqueDevices;
        acc.visualizations += device.visualizations;
        return acc;
    }, {
        type: 'Totale',
        count: 0,
        uniqueDevices: 0,
        visualizations: 0
    });

    setDeviceData([...deviceDataArray, totalDeviceData]);
};


  const calculateTotals = (data) => {
    const totals = data.reduce((acc, entry) => {
        acc.totalUniqueUsers += entry.totalUniqueUsers;
        acc.totalSessions += entry.totalSessions;
        acc.newUsersCount += entry.newUsersCount;
        acc.totalSessionTimeSeconds += (entry.avgSessionTime.minutes * 60 + entry.avgSessionTime.seconds) * entry.totalSessions;
        return acc;
    }, {
        totalUniqueUsers: 0,
        totalSessions: 0,
        newUsersCount: 0,
        totalSessionTimeSeconds: 0
    });

    // Calcola le medie globali
    if (totals.totalSessions > 0) {
        const avgSessionTimeSeconds = totals.totalSessionTimeSeconds / totals.totalSessions;
        totals.avgSessionTimeMinutes = Math.floor(avgSessionTimeSeconds / 60);
        totals.avgSessionTimeSeconds = Math.floor(avgSessionTimeSeconds % 60);
    } else {
        totals.avgSessionTimeMinutes = 0;
        totals.avgSessionTimeSeconds = 0;
    }

    if (totals.totalUniqueUsers > 0) {
        const avgEngagementTimeSeconds = totals.totalSessionTimeSeconds / totals.totalUniqueUsers;
        totals.avgEngagementTimeMinutes = Math.floor(avgEngagementTimeSeconds / 60);
        totals.avgEngagementTimeSeconds = Math.floor(avgEngagementTimeSeconds % 60);
        totals.avgSessionsPerUser = (totals.totalSessions / totals.totalUniqueUsers).toFixed(2);
    } else {
        totals.avgEngagementTimeMinutes = 0;
        totals.avgEngagementTimeSeconds = 0;
        totals.avgSessionsPerUser = 0;
    }

    setTotals(totals);
  };

  const calculateDailyData = (data) => {
    const days = eachDayOfInterval({
      start: parseISO(startDate),
      end: parseISO(endDate)
    });

    const dailyData = days.map(day => {
      const dayString = format(day, "yyyy/MM/dd");
      const dayData = data.filter(entry => entry.date === dayString);

      const totals = dayData.reduce((acc, entry) => {
        acc.totalUniqueUsers += entry.totalUniqueUsers;
        acc.totalSessions += entry.totalSessions;
        acc.newUsersCount += entry.newUsersCount;
        acc.totalSessionTimeSeconds += (entry.avgSessionTime.minutes * 60 + entry.avgSessionTime.seconds) * entry.totalSessions;
        return acc;
      }, {
        totalUniqueUsers: 0,
        totalSessions: 0,
        newUsersCount: 0,
        totalSessionTimeSeconds: 0
      });

      // Calcola le medie giornaliere
      if (totals.totalSessions > 0) {
        const avgSessionTimeSeconds = totals.totalSessionTimeSeconds / totals.totalSessions;
        totals.avgSessionTimeMinutes = Math.floor(avgSessionTimeSeconds / 60);
        totals.avgSessionTimeSeconds = Math.floor(avgSessionTimeSeconds % 60);
      } else {
        totals.avgSessionTimeMinutes = 0;
        totals.avgSessionTimeSeconds = 0;
      }

      if (totals.totalUniqueUsers > 0) {
        const avgEngagementTimeSeconds = totals.totalSessionTimeSeconds / totals.totalUniqueUsers;
        totals.avgEngagementTimeMinutes = Math.floor(avgEngagementTimeSeconds / 60);
        totals.avgEngagementTimeSeconds = Math.floor(avgEngagementTimeSeconds % 60);
        totals.avgSessionsPerUser = (totals.totalSessions / totals.totalUniqueUsers).toFixed(2);
      } else {
        totals.avgEngagementTimeMinutes = 0;
        totals.avgEngagementTimeSeconds = 0;
        totals.avgSessionsPerUser = 0;
      }

      return {
        date: dayString,
        ...totals
      };
    });

    setDailyData(dailyData);
  };

  const calculateLineData = (data) => {
    const lineDataMap = data.reduce((acc, entry) => {
      entry.lineArray.forEach(line => {
        if (!acc[line.lineRef]) {
          acc[line.lineRef] = {
            nomeLinea: line.nome_linea,
            visualization: 0,
            time: 0,
            totalUniqueUsers: 0,
            totalSessions: 0,
            newUsersCount: 0
          };
        }
        acc[line.lineRef].visualization += line.visualization;
        acc[line.lineRef].time += line.time;
        acc[line.lineRef].totalUniqueUsers += line.numberOfUniqueUsers;
        acc[line.lineRef].totalSessions += line.numberOfSessions;
        acc[line.lineRef].newUsersCount += line.newUsersCount;
      });
      return acc;
    }, {});

    const lineDataArray = Object.values(lineDataMap).map(line => {
      const avgSessionTimeSeconds = line.time / line.totalSessions;
      const avgEngagementTimeSeconds = line.time / line.totalUniqueUsers;
      return {
        nomeLinea: line.nomeLinea,
        visualization: line.visualization,
        time: line.time,
        totalUniqueUsers: line.totalUniqueUsers,
        totalSessions: line.totalSessions,
        newUsersCount: line.newUsersCount,
        avgSessionTimeMinutes: Math.floor(avgSessionTimeSeconds / 60),
        avgSessionTimeSeconds: Math.floor(avgSessionTimeSeconds % 60),
        avgEngagementTimeMinutes: Math.floor(avgEngagementTimeSeconds / 60),
        avgEngagementTimeSeconds: Math.floor(avgEngagementTimeSeconds % 60),
        avgSessionsPerUser: (line.totalSessions / line.totalUniqueUsers).toFixed(2)
      };
    });

    setLineData(lineDataArray);
  };

  const filteredDailyData = selectedLine
    ? dailyData.filter(day => day.lineRef === selectedLine)
    : dailyData;

  if (loading) {
    return <p>Caricamento...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <div>
        <label>
          Data Inizio: 
          <input 
            type="date" 
            value={startDate} 
            onChange={handleStartDateChange} 
          />
        </label>
        <label>
          Data Fine: 
          <input 
            type="date" 
            value={endDate} 
            onChange={handleEndDateChange} 
          />
        </label>
        <label>
          Seleziona Brand: 
          <select value={selectedBrand} onChange={handleBrandChange}>
            <option value="">Tutti i Brand</option>
            {brandIds.map(brandId => (
              <option key={brandId} value={brandId}>{brandId}</option>
            ))}
          </select>
        </label>
        <label>
          Seleziona Linea: 
          <select value={selectedLine} onChange={handleLineChange}>
            <option value="">Tutte le Linee</option>
            {lineData.map(line => (
              <option key={line.nomeLinea} value={line.nomeLinea}>{line.nomeLinea}</option>
            ))}
          </select>
        </label>
        <button onClick={handleSubmit}>Visualizza Dati</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Numero Utenti</th>
            <th>Numero Sessioni</th>
            <th>Nuovi Utenti</th>
            <th>AVG Time</th>
            <th>AVG Engagement Time</th>
            <th>AVG Sessions/Utente</th>
          </tr>
        </thead>
        <tbody>
          {filteredDailyData.map(day => (
            <tr key={day.date}>
              <td>{day.date}</td>
              <td>{day.totalUniqueUsers}</td>
              <td>{day.totalSessions}</td>
              <td>{day.newUsersCount}</td>
              <td>{`${day.avgSessionTimeMinutes}m ${day.avgSessionTimeSeconds}s`}</td>
              <td>{`${day.avgEngagementTimeMinutes}m ${day.avgEngagementTimeSeconds}s`}</td>
              <td>{day.avgSessionsPerUser}</td>
            </tr>
          ))}
          <tr>
            <td>Totale</td>
            <td>{totals.totalUniqueUsers}</td>
            <td>{totals.totalSessions}</td>
            <td>{totals.newUsersCount}</td>
            <td>{`${totals.avgSessionTimeMinutes}m ${totals.avgSessionTimeSeconds}s`}</td>
            <td>{`${totals.avgEngagementTimeMinutes}m ${totals.avgEngagementTimeSeconds}s`}</td>
            <td>{totals.avgSessionsPerUser}</td>
          </tr>
        </tbody>
      </table>
      <h2>Dati della Linea</h2>
      <table>
        <thead>
          <tr>
            <th>Nome Linea</th>
            <th>visualization</th>
            <th>Tempo Totale</th>
            <th>Numero Utenti</th>
            <th>Numero Sessioni</th>
            <th>Nuovi Utenti</th>
            <th>AVG Time</th>
            <th>AVG Engagement Time</th>
            <th>AVG Sessions/Utente</th>
          </tr>
        </thead>
        <tbody>
          {lineData.map(line => (
            <tr key={line.nomeLinea}>
              <td>{line.nomeLinea}</td>
              <td>{line.visualization}</td>
              <td>{`${Math.floor(line.time / 60)}m ${Math.floor(line.time % 60)}s`}</td>
              <td>{line.totalUniqueUsers}</td>
              <td>{line.totalSessions}</td>
              <td>{line.newUsersCount}</td>
              <td>{`${line.avgSessionTimeMinutes}m ${line.avgSessionTimeSeconds}s`}</td>
              <td>{`${line.avgEngagementTimeMinutes}m ${line.avgEngagementTimeSeconds}s`}</td>
              <td>{line.avgSessionsPerUser}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Dati per Dispositivo</h2>
        <table>
            <thead>
                <tr>
                    <th>Tipo Dispositivo</th>
                    <th>Conteggio</th>
                    <th>Dispositivi Unici</th>
                    <th>Visualizzazioni</th>
                </tr>
            </thead>
            <tbody>
                {deviceData.map(device => (
                    <tr key={device.type}>
                        <td>{device.type}</td>
                        <td>{device.count}</td>
                        <td>{device.uniqueDevices}</td>
                        <td>{device.visualizations}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
  );
};

export default AggregatedDataTable;
