import React, { useState, useRef , useEffect} from "react";
import styles from "./ModelContainer.module.css";

function MenuContainer(props) {
 
 
  let topBottomItemWidth = 200 * props.childCount;

  if (props.menu === "circle") {
    topBottomItemWidth = 45 * (props.childCount - 1) + 50;
  }

  const containerRef = useRef(null);
  const [selectedId, setSelectedId] = useState(props.selectedId); // Inizializza selectedId con props.selectedId
  const [scrollPosition, setScrollPosition] = useState(0); // Aggiungi questa riga
  const [containerWidth, setContainerWidth] = useState(0); // Aggiungi questa riga
  

  function selectNext() {
    // console.log("select next")
    const index = props.children.findIndex(
      (child) => child.props.modelId === selectedId
    );
  
    setScrollPosition(1);
    // console.log(scrollPosition)
    // console.log(containerWidth)
    
    if (index < props.children.length - 1) {
      props.setSelectedId(props.children[index + 1].props.modelId);
      setSelectedId(props.children[index + 1].props.modelId);
    }
  };

  function selectPrevious() {
    const index = props.children.findIndex(
      (child) => child.props.modelId === selectedId
    );
    if (index > 0) {
      props.setSelectedId(props.children[index -1].props.modelId);
      setSelectedId(props.children[index - 1].props.modelId);
    }
  }


  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        // console.log("WIDTH",containerRef.current.offsetWidth, topBottomItemWidth)
          setContainerWidth(containerRef.current.offsetWidth);
      }
    };
  
    window.addEventListener("resize", updateContainerWidth);
  
    updateContainerWidth(); // Inizializza la larghezza del contenitore
  
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  useEffect(() => {
    // console.log("USE EFFECT");
    const handleScroll = () => {
      if (containerRef.current) {
        // console.log("SET")
        setScrollPosition(containerRef.current.scrollLeft);
      }
    };

    if(containerRef.current){
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if(containerRef.current){
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef.current]);

  
  useEffect(() => {
    // Aggiorna selectedId quando cambia props.selectedId
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  useEffect(() => {
    // console.log("SCROLL",scrollPosition);
  }, [scrollPosition]);

  return (
    <div
      key={props.cardStyle}
      ref={containerRef}
      className={
        props.style === "TOP" || props.style === "BOTTOM"
          ? "menu-container-horizontal"
          : "menu-container-vertical"
      }
    >

            {/* Aggiungi la freccia sinistra solo se la posizione dello scroll è maggiore di 0 */}
            {scrollPosition > 0 && (
        <div onClick={selectPrevious} className="scroll-arrow left">
          &lt;
        </div>
      )}


      {props.style === "TOP" && (
        <div className={styles["wrapper-classic-parent"]}>
          <div
            className={styles["wrapper-classic"]}
            style={{ width: (containerWidth >= topBottomItemWidth) ? topBottomItemWidth : "100%" }}
            // style={{ width: topBottomItemWidth }}
          >
            {props.children}
          </div>
        </div>
      )}

      {props.style === "BOTTOM" && (
        <div className={styles["wrapper-classic-parent"]}>
          <div
            className={styles["wrapper-classic"]}
            style={{ width: (containerWidth >= topBottomItemWidth) ? topBottomItemWidth : "100%" }}
            // style={{overflow: "hidden"}}
          >
                     

            {props.children}
          </div>
        </div>
      )}

      {props.style === "LEFT" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic-lateral"]
              : styles["row-wrapp-lateral"]
          }
          // style={{overflow: "hidden"}}
        >
          {props.children}
        </div>
      )}
      {props.style === "RIGHT" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic-lateral"]
              : styles["row-wrapp-lateral"]
          }
          // style={{overflow: "hidden"}}
        >
          {props.children}
        </div>
      )}

       {/* Aggiungi la freccia destra solo se la larghezza dello scroll è maggiore della posizione dello scroll */}
       {/* {scrollPosition < topBottomItemWidth - containerWidth && (
        <div onClick={selectNext} className="scroll-arrow right">
          &gt; 
        </div>
      )} */}
    </div>
  );
}

export default MenuContainer;
