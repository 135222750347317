import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import avatar from "../../../assets/images/user/10.jpg";
import { useSelector } from "react-redux";

const AvatarDropdown = ({ logOut }) => {
  const photoURL = useSelector((reducer) => reducer.profile.profileImage);
  return (
    <Dropdown className="avatar-dropdown">
      <div className="dropdown-toggle">
        <Dropdown.Toggle as={CustomToggle}>
          <Image
            src={photoURL || avatar}
            className="avatar-image"
            roundedCircle
          />

          <MdOutlineKeyboardArrowDown className="avatar-icon" />
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu align="right">
        <Dropdown.Item onClick={logOut}>Sign Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default AvatarDropdown;
