export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "default",
      },
    },
    color: {
      layout_version: 'light', 
      color: 'color-2', 
      primary_color: '#ff4c3b', 
      secondary_color: '#26c6da', 
      mix_layout: 'dark-only',
    },

    router_animation: "fade",
  };
}

export default ConfigDB;
