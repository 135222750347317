import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Chart from "./charts";
import HomeCard from "../../component/cards/homecard";
import {
  pieData,
  pieOptions,
  lineData,
  lineOptions,
  dashboardData,
  getModelsFromFirebase,
  // getGlassesInfoFromFirebase,
  returnDashboardData,
  // fetchData,
  fetchAggregateData,
} from "../../services/home";

const Home = () => {
  const [models, setModels] = useState([]);
  const [numberOfUsers, setNumberOfUsers] = useState([]);
  const [numberofSession, setNumberOfSession] = useState([]);
  const [sessionWeekWiseCount, setSessionWeekWiseCount] = useState([]);
  const [data, setData] = useState(dashboardData);
  const [isLoading, setIsLoading] = useState(false);

  const rxBrands = useSelector((state) => state.config.listaBrand);

  const { selectedBrand, config } = useSelector((state) => state);
  const sumOfVariants = (glassesArray) => {
    return glassesArray?.reduce((sum, glass) => {
      return sum + glass.lista_varianti?.length || 0;
    }, 0);
  };

  // const getGlassesInfo = async () => {
  //   const data = await getGlassesInfoFromFirebase(selectedBrand, rxBrands);
  //   setGlasses(data);
  // };

  const getModels = async () => {
    const data = await getModelsFromFirebase(selectedBrand, rxBrands);
    setModels(data);
  };

  useEffect(() => {
    // Generate labels for the last 7 days
    if (!lineData?.labels?.length) {
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        const label = date.toLocaleDateString("en-US", { weekday: "long" });
        lineData.labels.push(label);
      }

      // Assuming `numberofSession` is an array of the corresponding data for the last 7 days
      // Update the `data` property of the `datasets` array
      lineData.datasets[0].data = numberofSession;
    }
  }, []);

  useEffect(() => {
    if (selectedBrand.id) {
      getModels();
      fetchAggregateData(
        selectedBrand,
        setNumberOfUsers,
        setNumberOfSession,
        setIsLoading,
        setSessionWeekWiseCount,
        rxBrands
      );
    }
  }, [selectedBrand.id]);

  // useEffect(() => {
  //   getModels();
  // }, [glasses]);

  useEffect(() => {
    if (models?.length) {
      setData(returnDashboardData(models, data, config));
    }
  }, [models]);
  return (
    <div>
      <div className="card-container">
        {data.map((data, index) => (
          <HomeCard
            key={index}
            leftTitle={data.leftTitle}
            leftSubtitle={data.leftSubtitle}
            rightTitle={data.rightTitle}
            rightSubtitle={data.rightSubtitle}
          />
        ))}
      </div>

      <div className="home_container">
        <div className="pie_chart_container">
          <Chart
            chartType="pie"
            data={{
              ...pieData,
              datasets: [
                {
                  ...pieData.datasets[0],
                  data:
                    numberOfUsers === 0 ? [numberOfUsers, 1] : [numberOfUsers],
                },
              ],
            }}
            options={pieOptions}
            title="Number of users (last 7 days)"
            totalCount={numberOfUsers}
            isLoading={isLoading}
          />
        </div>
        <div className="pie_chart_container">
          <Chart
            chartType="line"
            data={{
              ...lineData,
              datasets: [
                {
                  ...lineData.datasets[0],
                  data: sessionWeekWiseCount,
                },
              ],
            }}
            options={{
              ...lineOptions,
             
              title: {
                text: numberofSession,
              },
            }}
            className="line_chart"
            title="Number of sessions (last 7 days)"
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
