import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { brandViewerActions } from "../../redux/brandViewer/brandViewer";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../data/base";
import ModelContainerFrontEnd from "../../component/modelViewer/ModelContainerFrontEnd";
import ProjectModelViewer from "../../component/modelViewer/ProjectModelView";
import CardModelView from "../../component/modelViewer/CardModelView";
import MenuContainer from "../../component/modelViewer/MenuContainer";
import { modelGlassesActions } from "../../redux/brandViewer/modelGlasses";
import Password from "../../component/modelViewer/Password";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';

function ARShades3dsViewer({
  isEditModeOn,
  containerHeight,
  containerWidth,
  setCurrentVariant = () => { },
  _setListVariant = () => { },
}) {
  const params = useParams();
  const dispatch = useDispatch();

  const [idViewer, setIdViewer] = useState();

  const [listVariant, setListVariant] = useState([]);
  const [lock, setLock] = useState(false);
  const [initialModel, setInitialModel] = useState();
  // eslint-disable-next-line
  const [modelHistory, setModelHistory] = useState([]);
  const [pwd, setPwd] = useState("");
  // eslint-disable-next-line
  const [initialTime, setInitialTime] = useState();

  const brand = params.TokenBrand;
  const glasses = params.TokenGlasses;
  const project = useSelector((state) => state.brandViewer);

  const [glassesRef, setGlassesRef] = useState(null);
  const [lineRefs, setLineRefs] = useState(null);
  const [glassesSize, setGlassesSize] = useState(null);
  const modelInView = useSelector((state) =>
    state.modelGlasses.find((model) => model.id === initialModel)
  );

  const [initialModelTime, setInitiaModellTime] = useState(new Date());

  const viewer = document.querySelector("model-viewer");

  const [positionMenu, setPositionMenu] = useState("bottom");


  useEffect(() => {
    // if (window.innerWidth <= 700) {
    //   setPositionMenu("bottom");
    // } else {
    setPositionMenu(project[0]?.menu_position);
    // }
  }, [project[0]]);

  const [session, setSession] = useState({
    device_id: "",
    device: "",
    device_os: "",
    is_first_session: false,
    ref_catalogo: "",
    data_inizio_sessione: "",
    data_fine_user_session: "",
    glassesRefs: [],
    lista_occhiali_visualizzati: [],
    modelRefs: [],
    lineaRefs: [],
    glassesRefsDoc: null,
    total_time: 0,
    glassesSize: null,
  });

  const [refBrand, setRefBrand] = useState();

  useEffect(async () => {
    const visualizzatoreRef = await db
      .collection("Visualizzatori3D")
      ?.doc(brand)
      .get();
    const data = visualizzatoreRef.data();

    setIdViewer(data.id);
    dispatch(brandViewerActions.setSelectedProject(data));
    const date = new Date();
    setInitialTime(date);
  }, []);

  useEffect(async () => {
    if (idViewer) {
      const glassesRef = await db
        ?.collection("Visualizzatori3D")
        ?.doc(idViewer)
        .collection("Glasses")
        ?.doc(glasses)
        .get();

      setListVariant(glassesRef?.data()?.lista_varianti);
      setInitialModel(glassesRef?.data()?.initial_model);

      const modelRef = await db
        ?.collection("Modello")
        ?.doc(glassesRef?.data().initial_model)
        .get();
      // console.log("src", modelRef.data().urlGlbComplete);
      setModelHistory([...modelHistory, modelRef?.data().urlGlbComplete]);
    }
  }, [idViewer]);

  useEffect(async () => {
    const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
    const data = docRef.data();
    setLock(data.lock);
    if (data.lock) {
      setPwd(data.password);
    }
  }, []);

  useEffect(() => {
    if (listVariant) {
      listVariant.map(async (v) => {
        const variant = await db.collection("Modello").doc(v).get();
        dispatch(
          modelGlassesActions.setModel({ data: variant?.data(), id: v })
        );
      });
    }
  }, [listVariant]);

  useEffect(async () => {
    // Attempt to get device ID using IP
    let deviceID = uuidv4(); // Fallback to a UUID if fetching IP fails

    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      deviceID = res.data.ip;
    } catch (error) {
      console.error("Failed to fetch IP address:", error);
    }

    session.device_id = deviceID;

    let sameMonthYear = false;
    const padWithZero = (number) => number.toString().padStart(2, "0");

    const date = new Date(); // Usa il tuo oggetto date
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1); // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)
    const monthDataCons = date.getMonth() + 1; // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)

    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());

    const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
    const docBrand = docRef.data().brand;
    const brandRefDoc = db.collection("Brand").doc(docBrand);
    const brandRef = await brandRefDoc.get();
    const doc = brandRef?.data()?.data_consumption;
    const contatoriRef = await db.collection("Contatori_Brand")?.doc(doc).get();
    const data = contatoriRef.data();
    const glassesRefDoc = db.collection("Occhiale")?.doc(glasses);
    const glassesDoc = await glassesRefDoc.get();
    const sizeRefDoc = glassesDoc.data().lista_taglie[0];

    // console.log(glassesRefDoc.data().lineaRef);
    // console.log(glassesRefDoc)
    setGlassesRef(glassesRefDoc);
    setLineRefs(glassesDoc.data().lineaRef);
    setGlassesSize(sizeRefDoc);
    setRefBrand(brandRefDoc);

    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Apple Safari";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
    } else {
      browserName = "Unknown";
    }

    session.device_os = browserName;

    // Check if the user has visited the site before
    const isFirstSession = !Cookies.get("visited");

    if (!Cookies.get("visited")) {
      Cookies.set("visited", "true");
      // console.log(Cookies);
    }

    session.glassesSize = sizeRefDoc;
    session.is_first_session = isFirstSession;
    // console.log(isFirstSession);
    session.glassesRefsDoc = glassesRefDoc;
    session.lineaRefs.push(glassesDoc.data().lineaRef);
    session.glassesRefs.push(glassesDoc.id);
    session.ref_catalogo = brandRefDoc;
    session.data_inizio_sessione = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    data.bandwidth_3dviewer.map((bandw, i) => {
      if (bandw.month === monthDataCons && bandw.year === year) {
        sameMonthYear = true;
      }
    });
    const contatori = await db.collection("Contatori_Brand")?.doc(doc);
    if (!sameMonthYear) {
      contatori.update("bandwidth_3dviewer", [
        ...data.bandwidth_3dviewer,
        { bandwidth_month: 0, calls_number: 0, month: monthDataCons, year },
      ]);
    }
  }, []);

  useEffect(() => {
    if (viewer?.src) {
      setModelHistory([...modelHistory, viewer?.src]);
    }
  }, [viewer?.src]);

  const [listCreated, setListCreated] = useState(false);

  function getMeseCorrente() {
    const dataOdierna = new Date();
    const meseCorrente = dataOdierna.getMonth() + 1; // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return meseCorrente;
  }

  function getCurrentYear() {
    const dataOdierna = new Date();
    const annoCorrente = dataOdierna.getFullYear(); // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return annoCorrente;
  }

  // Velocizzato con map e non con il for (bandwidth_3dviewer)
  useEffect(() => {
    if (!initialModel || lock) return;

    const updateBandwidth = async () => {
      const variant = await db.collection("Modello")?.doc(initialModel).get();
      const src = variant.data().urlGlbComplete;
      const fileImg = await fetch(src).then((r) => r.blob());
      const sizeMb = fileImg.size / 1000000;
      const banda = parseFloat(sizeMb.toFixed(2)) + 0.5;

      const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
      const docBrand = docRef.data().brand;
      const brandRef = await db.collection("Brand")?.doc(docBrand).get();
      const doc = brandRef?.data()?.data_consumption;
      const contatoriRef = await db.collection("Contatori_Brand")?.doc(doc).get();

      const data = contatoriRef.data();
      const size = data.bandwidth_3dviewer.length - 1;
      const meseCorrente = getMeseCorrente();
      const year = getCurrentYear();

      const updatedData = data.bandwidth_3dviewer.map((ele) =>
        ele.month === meseCorrente && ele.year === year
          ? { ...ele, bandwidth_month: ele.bandwidth_month + banda, calls_number: ele.calls_number + 1 }
          : ele
      );

      await db.collection("Contatori_Brand")?.doc(doc).update({ bandwidth_3dviewer: updatedData });
    };

    const updateSession = async () => {
      const modelRefId = db.collection("Modello")?.doc(initialModel);
      const modelRef = await modelRefId.get();
      const modelData = modelRef.data();

      const date = new Date();
      const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);
      setInitiaModellTime(date);

      const url = modelData.urlGlbComplete;
      const blob = await fetch(url).then((r) => r.blob());
      const banda = parseFloat((blob.size / 1000000).toFixed(2));

      if (!listCreated) {
        session.modelRefs = listVariant;
        session.device = window.navigator.userAgent;
        session.lista_occhiali_visualizzati.push({
          nome_occhiale: modelData.nomeOcchiale,
          lineaRef: session.lineaRefs[0],
          glassesRef: session.glassesRefsDoc,
          listaModelliProvati: [{
            modelRef: modelRefId,
            timeStart: initialTime,
            totalSeconds: timeDiff,
            tagliaRef: session.glassesSize,
          }],
          timeStart: initialTime,
          banda_consumata: banda,
          date: initialTime,
          totalSeconds: timeDiff,
        });
        setListCreated(true);
      } else {
        const listModelTry = session.lista_occhiali_visualizzati[0].listaModelliProvati;
        let modelFound = false;

        for (let model of listModelTry) {
          if (model.modelRef.id === modelRefId.id) {
            modelFound = true;
            model.totalSeconds += timeDiff;
            session.total_time += timeDiff;
            break;
          }
        }

        if (!modelFound) {
          listModelTry.push({
            modelRef: modelRefId,
            timeStart: initialTime,
            totalSeconds: timeDiff,
            tagliaRef: session.glassesSize,
          });
          session.total_time += timeDiff;
        }

        session.lista_occhiali_visualizzati[0] = {
          ...session.lista_occhiali_visualizzati[0],
          nome_occhiale: modelData.nomeOcchiale,
          lineaRef: lineRefs,
          glassesRef: glassesRef,
          listaModelliProvati: listModelTry,
          timeStart: date,
          banda_consumata: session.lista_occhiali_visualizzati[0].banda_consumata + banda,
          totalSeconds: 0,
        };
      }
    };

    setTimeout(async () => {
      await updateBandwidth();
      await updateSession();
    }, 10);

  }, [initialModel, lock]);

  // useEffect(async () => {
  //   if (initialModel && !lock) {
  //     setTimeout(async () => {
  //       if (!modelHistory.includes(viewer?.src)) {
  //         //getting size from default variant

  //         const variant = await db
  //           .collection("Modello")
  //           ?.doc(initialModel)
  //           .get();
  //         const src = variant.data().urlGlbComplete;
  //         const fileImg = await fetch(src).then((r) => r.blob());
  //         const sizeMb = fileImg.size / 1000000;
  //         const b = sizeMb.toFixed(2);
  //         const banda = parseFloat(b) + 0.5;

  //         // const doc = "1lRTwIUrqYLewPBlC7sx";
  //         const docRef = await db
  //           .collection("Visualizzatori3D")
  //           ?.doc(brand)
  //           .get();
  //         const docBrand = docRef.data().brand;
  //         const brandRef = await db.collection("Brand")?.doc(docBrand).get();
  //         const doc = brandRef?.data()?.data_consumption;
  //         const contatoriRef = await db
  //           .collection("Contatori_Brand")
  //           ?.doc(doc)
  //           .get();

  //         const data = contatoriRef.data();
  //         const size = data.bandwidth_3dviewer.length - 1;



  //         const meseCorrente = getMeseCorrente();
  //         const year = getCurrentYear();
  //         // console.log("MESE CORRENTE", meseCorrente, year)
  //         data.bandwidth_3dviewer.map((ele, i) => {
  //           if (ele.month === meseCorrente && ele.year === year) {
  //             const bandwidth_month = ele.bandwidth_month;
  //             const calls_number = ele.calls_number;
  //             const month = parseInt(ele.month);
  //             const year = ele.year;
  //             data.bandwidth_3dviewer[i] = {
  //               bandwidth_month: bandwidth_month + banda,
  //               calls_number: calls_number + 1,
  //               month,
  //               year,
  //             };
  //           }
  //         });
  //         const contatori = db.collection("Contatori_Brand")?.doc(doc);
  //         contatori.update("bandwidth_3dviewer", data.bandwidth_3dviewer);
  //       }

  //       const modelRefId = db.collection("Modello")?.doc(initialModel);
  //       const modelRef = await modelRefId.get();
  //       const modelData = modelRef.data();

  //       if (!listCreated) {
  //         const date = new Date();
  //         const timeDiff = Math.floor(
  //           (date.getTime() - initialModelTime.getTime()) / 1000
  //         );
  //         setInitiaModellTime(new Date());
  //         const url = modelData.urlGlbComplete;
  //         const blob = await fetch(url).then((r) => r.blob());
  //         const b = blob.size / 1000000;
  //         const b1 = b.toFixed(2);
  //         const banda = parseFloat(b1);
  //         session.modelRefs = listVariant;
  //         session.device = window.navigator.userAgent;
  //         // session.modelRefs.push(data.id);
  //         session.lista_occhiali_visualizzati.push({
  //           nome_occhiale: modelData.nomeOcchiale,
  //           lineaRef: session.lineaRefs[0],
  //           glassesRef: session.glassesRefsDoc,
  //           listaModelliProvati: [
  //             {
  //               modelRef: modelRefId,
  //               timeStart: initialTime,
  //               totalSeconds: timeDiff,
  //               tagliaRef: session.glassesSize,
  //             },
  //           ],
  //           timeStart: initialTime,
  //           banda_consumata: banda,
  //           date: initialTime,
  //           totalSeconds: timeDiff,
  //         });
  //         setListCreated(true);
  //       } else {
  //         //write durata into previous array element
  //         const date = new Date();

  //         const timeDiff = Math.floor(
  //           (date.getTime() - initialModelTime.getTime()) / 1000
  //         );

  //         setInitiaModellTime(new Date());

  //         const listModelTry =
  //           session.lista_occhiali_visualizzati[0].listaModelliProvati;
  //         let checkModelExist = false;
  //         let totalTime = session.total_time;
  //         listModelTry.map((model) => {
  //           // console.log(model.modelRef, modelRefId);
  //           if (model.modelRef.id === modelRefId.id) {
  //             checkModelExist = true;
  //             // console.log(model.totalSeconds, timeDiff);
  //             model = { ...model, totalSeconds: model.totalSeconds + timeDiff };
  //             totalTime = totalTime + model.totalSeconds;
  //             // console.log(model);
  //           }
  //         });
  //         if (!checkModelExist) {
  //           listModelTry.push({
  //             modelRef: modelRefId,
  //             timeStart: initialTime,
  //             totalSeconds: timeDiff,
  //             tagliaRef: session.glassesSize,
  //           });
  //           totalTime = totalTime + timeDiff;
  //         }

  //         // console.log(totalTime);

  //         // session.lista_occhiali_visualizzati[0].totalSeconds = totalTime;

  //         //create new lista_occhiali_visualizzati element
  //         const url = modelData.urlGlbComplete;
  //         const blob = await fetch(url).then((r) => r.blob());
  //         const b = blob.size / 1000000;
  //         const b1 = b.toFixed(2);
  //         session.total_time =
  //           session.lista_occhiali_visualizzati[0].totalSeconds;
  //         const banda =
  //           session.lista_occhiali_visualizzati[0].banda_consumata +
  //           parseFloat(b1);
  //         session.lista_occhiali_visualizzati[0] = {
  //           ...session.lista_occhiali_visualizzati[0],
  //           nome_occhiale: modelData.nomeOcchiale,
  //           lineaRef: lineRefs,
  //           glassesRef: glassesRef,
  //           listaModelliProvati: [
  //             ...session.lista_occhiali_visualizzati[0].listaModelliProvati,
  //           ],
  //           timeStart: date,
  //           banda_consumata: banda,
  //           totalSeconds: 0,
  //         };
  //         // console.log(session);
  //       }
  //     }, 10);
  //   }
  // }, [initialModel, lock]);

  // console.log(session);

  useEffect(() => {
    window.addEventListener(
      "beforeunload",
      handleUnload);

    return () => {
      // rimuovi l'evento beforeunload quando il componente viene smontato
      window.removeEventListener("beforeunload", handleUnload);
    };

  }, [session]);

  function handleUnload() {


    const padWithZero = (number) => number.toString().padStart(2, "0");

    const date = new Date(); // Usa il tuo oggetto date
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1); // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)
    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());

    const i = session.lista_occhiali_visualizzati.length - 1;
    const timeDiff = Math.floor(
      (date.getTime() -
        session.lista_occhiali_visualizzati[i].date.getTime()) /
      1000
    );

    let totalTime = 0
    session.lista_occhiali_visualizzati.map((item) => {
      let second = 0;
      item.listaModelliProvati.map((model) => {
        second = second + model.totalSeconds
      })
      item.totalSeconds = second;
      totalTime = totalTime + second
    })

    session.total_time = totalTime;
    // session.lista_occhiali_visualizzati[i].totalSeconds = timeDiff;
    // session.total_time = timeDiff;
    session.data_fine_user_session = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    const sessioneRef = db.collection("Sessione_Visualizzatori3d");
    // const sessioneRef = db.collection("Sessione_WebVto");
    // console.log(session);

    sessioneRef.add(session);

  }

  function selecteModelInView(project, modelId) {
    setModelHistory([...modelHistory, viewer?.src]);
    setInitialModel(modelId);
  }

  function setSelectedId(modelId) {
    // console.log("selected");
    // console.log(modelId);
    selecteModelInView(project[0], modelId);
  }

  useEffect(() => {
    if (initialModel) {
      setCurrentVariant(initialModel);
      setVariantData(initialModel);
    }
  }, [initialModel]);

  useEffect(() => {
    if (listVariant && listVariant.length) {
      _setListVariant(listVariant);
    }
  }, [listVariant]);

  const setVariantData = async (initialModel) => {
    const variantRef = await db
      .collection("Visualizzatori3D")
      .doc(brand)
      .collection("Glasses")
      ?.doc(glasses)
      .collection("variants")
      .doc(initialModel)
      .get();

    const data = variantRef.data();
    if (data && data.shouldApply)
      return dispatch(brandViewerActions.setSelectedProject(data));

    const modelRef = await db
    .collection("Visualizzatori3D")
    .doc(brand)
    .collection("Glasses")
    ?.doc(params?.TokenGlasses)
    .collection("template")
    ?.doc("ARTemplate").get()

    const modelData = modelRef.data();

    if (modelData && modelData?.shouldApply)
      return dispatch(brandViewerActions.setSelectedProject(modelData));

    const visualizzatoreRef = await db
      .collection("Visualizzatori3D")
      ?.doc(brand)
      .get();
    const rootData = visualizzatoreRef.data();
    return dispatch(brandViewerActions.setSelectedProject(rootData));
  };

  function unlock() {
    setLock(false);
  }

  return (
    <div>
      {lock ? (
        <Password unlock={unlock} password={pwd} />
      ) : (
        <ModelContainerFrontEnd
          modelSelected={modelInView}
          project={project[0]}
          isEditModeOn={isEditModeOn}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
        >
          {positionMenu === "top" && (
            <MenuContainer
              style={"TOP"}
              menu={project[0]?.menu_type}
              childCount={listVariant.length}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
          {positionMenu === "left" && (
            <MenuContainer
              style={"LEFT"}
              menu={project[0]?.menu_type}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
          {!navigator.userAgent.match(/FBAV/i) && (
            <ProjectModelViewer
              multiViewer
              title={project.title}
              initialModel={initialModel}
              project={project[0]}
              paramsId={params.TokenGlasses}
              urlView={`https://staging.arshades.spaarkly.it/Arshades3ds/${project[0]?.id}/glasses/${params.TokenGlasses}`}
              viewerPage
              isEditModeOn={isEditModeOn}
            ></ProjectModelViewer>
          )}
          {navigator.userAgent.match(/FBAV/i) &&
            !navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (
              <div
                id={
                  project?.menu_position === "left" ||
                    project?.menu_position === "right"
                    ? "projectTwo"
                    : "project"
                }
              >
                <img
                  src={modelInView?.not_supported_image}
                  style={{ width: "100%" }}
                ></img>
                il broswer di facebook non supporta modelli 3D e realtà
                aumentata. prova con un altro broswer
              </div>
            )}
          {positionMenu === "bottom" && (
            <>
              <MenuContainer
                style={"BOTTOM"}
                menu={project[0]?.menu_type}
                key={project[0]?.menu_type}
                childCount={listVariant.length}
                selectedId={initialModel}
                setSelectedId={setSelectedId}
              >
                {listVariant?.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={initialModel}
                    project={project[0]}
                    selectModel={() =>
                      selecteModelInView(project[0], modelIdCard)
                    }
                    cardStyle={project[0]?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            </>
          )}

          {positionMenu === "right" && (
            <MenuContainer
              style={"RIGHT"}
              menu={project[0]?.menu_type}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
        </ModelContainerFrontEnd>
      )}
    </div>
  );
}

export default ARShades3dsViewer;