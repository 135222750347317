import React from "react";
import styles from "./ModalQR.module.css";
import { QRCodeSVG } from "qrcode.react";

function ModalQR(props) {
  return (
    <div>
      {props.isLarge ? (
        <div className={styles.modal}>
          <div className={styles["modal-dialog"]}>
            <div className={styles["modal-body"]}>{props.children}</div>
          </div>
        </div>
      ) : (
        <div
          className={
            props.project?.arPosition === "TOP"
              ? styles["modal-small"]
              : styles["modal-small-bottom"]
          }
        >
          <div className={styles["modal-dialog-small"]}>
            <div className={styles["modal-body-small"]}>
              <div>
                <div className={styles["container-model-ar"]}>
                  <div className={styles["text-modal-view"]}>
                    Try this experience in <br />
                    <span className={styles["try-AR-label"]}>Argumented Reality</span>
                  </div>
                </div>
                <div className={styles["container-qr"]}>
                  <QRCodeSVG
                    // value={`https://lab.spaarkly.it/${props.project?.id}`}
                    value={props.url}
                    bgColor={props.bgColor}
                    size={210}
                    fgColor={props.fgColor}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                <div className={styles["container-model-ar"]}>
                  <p className={styles["by-spaarkly"]}>
                    {" "}
                    by{" "}
                    <a
                      href="https://www.arshades.it/it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Arshades
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalQR;
