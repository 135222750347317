export const barChartData = {
  labels: ['Mon', 'Tue', 'Wen', 'Thus', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: 'y',
      lagend: 'y',
      data: [35, 59, 80, 81, 56, 55, 40],
      borderColor: '#7e37d8',
      backgroundColor: 'rgba(126,  55, 216, 0.6)',
      highlightFill: 'rgba(6, 181, 221, 1)',
      highlightStroke: '#06b5dd',
      borderWidth: 2
    },
    {
      label: 'z',
      lagend: 'z',
      data: [28, 48, 40, 19, 86, 27, 90],
      borderColor: '#06b5dd',
      backgroundColor: 'rgba(6, 181, 221, 0.8)',
      highlightFill: 'rgba(6, 181, 221, 0.8)',
      highlightStroke: '#06b5dd',
      borderWidth: 2
    }
  ],
  plugins: {
    datalabels: {
      display: false,
      color: 'white'
    }
  }
}
export const barChartOptions = {
  maintainAspectRatio: true,
  legend: {
    display: false
  },
  plugins: {
    datalabels: {
      display: false
    }
  }
}

export const getLinearGraphData = (label, record) => {
  const labels = Object.keys(record)
  const data = Object.values(record)

  return {
    labels,
    datasets: [
      {
        label,
        backgroundColor: 'transparent',
        borderColor: '#5388D8',
        pointColor: '#7e37d8',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#000',
        pointHighlightStroke: 'rgba(30, 166, 236, 1)',
        tension: 0,
        data
      }
    ],
    plugins: {
      datalabels: {
        display: true,
        color: 'white'
      }
    }
  }
}

export const linegraphData = {
  labels: [],
  datasets: [
    {
      label: 'Users',
      backgroundColor: 'rgba(126,  55, 216, 0.3)',
      borderColor: '#7e37d8',
      pointColor: '#7e37d8',
      pointStrokeColor: '#fff',
      pointHighlightFill: '#000',
      pointHighlightStroke: 'rgba(30, 166, 236, 1)',
      data: []
    }
  ],
  plugins: {
    datalabels: {
      display: false,
      color: 'white'
    }
  }
}
export const linegraphOptions = {
  maintainAspectRatio: true,

  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false
    }
  }
}

export const radargraphData = {
  labels: ['Ford', 'Chevy', 'Toyota', 'Honda', 'Mazda'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(6, 181, 221, 0.5)',
      borderColor: '#06b5dd',
      pointBackgroundColor: 'rgba(68, 102, 242, 0.4)',
      pointBorderColor: '#06b5dd',
      pointHoverBackgroundColor: '#06b5dd',
      pointHoverBorderColor: 'rgba(6, 181, 221, 0.4)',
      data: [12, 3, 5, 18, 7]
    }
  ]
}
export const radargraphOption = {
  scaleShowGridLines: true,
  scaleGridLineColor: 'rgba(0,0,0,.2)',
  scaleGridLineWidth: 1,
  scaleShowHorizontalLines: true,
  scaleShowVeryalLines: true,
  bezierCurve: true,
  bezierCurveTension: 0.4,
  pointDot: true,
  pointDotRadius: 3,
  pointDotStrokeWidth: 1,
  pointHitDetectionRadius: 20,
  datasetStroke: true,
  datasetStrokeWidth: 2,
  datasetFill: true
}

export const lineChartData = {
  labels: ['', '10', '20', '30', '40', '50', '60', '70', '80'],
  datasets: [
    {
      backgroundColor: '#41b1ac',
      borderColor: '#158df7',
      pointColor: '#158df7',
      data: [10, 20, 40, 30, 0, 20, 10, 30, 10],
      lineTension: 0
    },
    {
      backgroundColor: '#41b1ac',
      borderColor: '#7e37d8',
      pointColor: '#7e37d8',
      data: [20, 40, 10, 20, 40, 30, 40, 10, 20],
      lineTension: 0
    },
    {
      backgroundColor: '#41b1ac',
      borderColor: '#fd517d',
      pointColor: '#fd517d',
      data: [60, 10, 40, 30, 80, 30, 20, 90, 0],
      lineTension: 0
    }
  ]
}
export const lineChartoption = {
  maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  legend: {
    display: false
  },
  scaleShowVerticalLines: false,
  plugins: {
    datalabels: {
      display: false,
      color: 'white'
    }
  }
}

export const doughnutData = {
  labels: ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'],

  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ['#7e37d8', '#fe80b2', '#80cf00']
    }
  ]
}
export const doughnutOption = {
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        ticks: {
          fontSize: 10, // Regola la dimensione del testo delle etichette dell'asse x
          callback: function (value) {
            // truncate till '(', if not present, truncate this first 10 characters
            const index = value.indexOf("(");
            return index !== -1 ? value.slice(0, index - 1) : value.substr(0, 20);
          },
        },
      },
    ],
    yAxes:[{
      ticks: {
        beginAtZero: true,
        min: 0
      },
      title: {
        display: true,
        text: "Number of Sessions",
      }
    }],
    
  },

  legend: {
    display: false
  },
  plugins: {
    datalabels: {
      // anchor: "end",
      // align: "end",
      // offset: 20,
      formatter: (value, context) => {
        const datapoints = context.chart.data.datasets[0].data
        const total = datapoints.reduce(
          (total, datapoint) => total + datapoint,
          0
        )
        const percentage = (value / total) * 100
        const label = context.chart.data.labels[context.dataIndex]

        return percentage.toFixed(2) + '%\n' + label
      },
      color: '#80cf00'
    }
  }
}

export const polarData = {
  labels: [
    'Download Sales',
    'In-Store Sales',
    'Mail Sales',
    'Telesales',
    'Corporate Sales'
  ],
  datasets: [
    {
      label: ['Yellow', 'Sky', 'Black', 'Grey', 'Dark Grey'],
      pointHoverBackgroundColor: 'rgba(126,  55, 216, 1)',
      data: [300, 50, 100, 40, 120],
      backgroundColor: ['#7e37d8', '#80cf00', '#fe80b2', '#06b5dd', '#fd517d']
    }
  ]
}

export const polarOption = {
  legend: {
    display: false
  }
}
