import React from "react";
import { Spinner } from "react-bootstrap";
import { Doughnut, Line } from "react-chartjs-2";

const Chart = ({
  chartType,
  data,
  options,
  filledColor,
  title,
  totalCount,
  isLoading,
}) => {
  const chartComponent =
    chartType === "pie" ? (
      <div className="pie-chart">
        <div className="pie-chart-content">
          <div className="chart-header">
            <p className="chart-header-text">{title}</p>
          </div>
          <div className="pie-chart-box">
            {isLoading ? (
              <div className="text-center chart-content-container-height">
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <div className="chart-content-container-height">
                <Doughnut
                  data={data}
                  options={{
                    ...options,
                    circumference: Math.PI,
                    rotation: Math.PI,
                  }}
                />
                <div className="pit-chart-text">
                  <p className="pie-chart-subtitle">Total Count</p>
                  <p className="pie-chart-title">{totalCount}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : chartType === "line" ? (
      <div className="pie-chart">
        <div className="chart-header">
          <p className="chart-header-text">{title}</p>
        </div>

        <div className="line-chart-container">
          {isLoading ? (
            <div className="text-center chart-content-container-height">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <div className="chart-content-container-height">
              <div className="line-chart-title">{options?.title?.text}</div>
              <div className="line-chart-subtitle">
                {options?.subTitle?.text}
              </div>

              <Line
                data={data}
                options={{
                  ...options,
                  plugins: {
                    ...options.plugins,
                    fill: true,
                    fillBackgroundColor: filledColor,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    ) : null;

  return <div>{chartComponent}</div>;
};

export default Chart;
