import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAnalyticsFilterOptions } from '../../../redux/analytics/actions';
import { getDurationFromSecond } from '../../../utils';
import ColorIndicator from './ColorIndicator';
import SummaryCard from '../../appnew/analytics/SummaryCard';
import { toast } from "react-toastify";

const AnalyticsTable = ({
  aggregateList = [],
  loading,
  setTableOverview,
  modelName,
}) => {
  const [lineList, setLineList] = useState([]);
  const [glassList, setGlassList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedTab, setSelectedDataIndex] = useState('Lines');

  const lineListRef = useRef([]);
  const glassListRef = useRef([]);
  const modelListRef = useRef([]);

  const dispatch = useDispatch();
  const lineDocList = useSelector((state) => state.analytics.lineList);
  const glassDocList = useSelector((state) => state.analytics.glassList);
  const modelDocList = useSelector((state) => state.analytics.modelList);
  const filterOptions = useSelector((state) => state.analytics.filterOptions);
  const { line, glass, variant } = filterOptions;

  const [inloading, setLoader] = useState(loading);


  const filterOptionMap = {
    Lines: 'line',
    Glasses: 'glass',
    Variants: 'variant',
  };

  const tabMap = useMemo(() => {
    return {
      Lines: lineList,
      Glasses: glassList,
      Variants: modelList,
    };
  }, [lineList, glassList, modelList]);

  const [list, totalRow] = useMemo(() => {
    const tempList = tabMap[selectedTab];
    const totalRow = tempList.find(
      (row) => row?.name?.toLowerCase?.() === 'total'
    );
    const restList = tempList.filter(
      (row) => row?.name?.toLowerCase?.() !== 'total'
    );
    return [restList, totalRow];
  }, [tabMap, selectedTab]);

  const handleDataIndexChange = (index) => () => {
    if (inloading) toast.error("Data is loading, Please wait...");
    setSelectedDataIndex(index);
  };

  const getSessionAndDuration = (data, key, time, sessionCount, visualization, glassId, lineRef) => {
    if (data['total']) {
      data['total'].sessions += sessionCount || 1;
      data['total'].duration += time;
      data['total'].visualization += visualization;
    } else {
      data['total'] = {
        sessions: sessionCount || 1,
        duration: time,
        visualization,
      };
    }

    if (data[key]) {
      data[key].sessions += sessionCount || 1;
      data[key].duration += time;
      data[key].visualization += visualization;
    } else {
      data[key] = {
        sessions: sessionCount || 1,
        duration: time,
        visualization,
      };
    }
    if (glassId) {
      data[key].glassId = glassId;
    }
    if (lineRef) {
      data[key].lineaRef = lineRef;
    }

    return data;
  };


  const createArrayFromObject = (
    doc,
    nameField,
    parentList = [],
    setterCallback,
    ref
  ) => {
    const parentMap = new Map();
    const list = [];
    const total = {
      ...doc?.total,
      name: 'Total',
      visualization: doc?.total?.visualization || 0, // Assicurati che la visualizzazione totale sia inclusa
    };

    list.push(total);
    delete doc.total;

    parentList.forEach((item) => {
      parentMap.set(item.id, item);
    });

    Object.entries(doc).forEach(([id, value]) => {
      const docDetails = parentMap.get(id);
      // console.log("DETAIL",docDetails)
      if (docDetails) {
        let name = docDetails[nameField];

        // Aggiungi il controllo per nome_occhiale
        if (nameField === 'nomeOcchiale') {
          name = (
            <div className='d-flex flex-wrap'>
              <span>{docDetails.nomeOcchiale}</span>
              <ColorIndicator
                className='ml-2'
                primary={docDetails.esaColorFramePrimary}
                secondary={docDetails.esaColorFrameSecondary}
                type='square'
                size='small'
              />
              <ColorIndicator
                primary={docDetails.esaColorLensesPrimary}
                secondary={docDetails.esaColorLensesSecondary}
                size='small'
              />
            </div>
          );
        }
        // console.log("DATA",docDetails )
        // Assicurati che il campo visualization sia incluso
        const itemData = {
          ...value,
          ...docDetails,
          id,
          name,
          visualization: value.visualization || 0,
        };

        list.push(itemData);
      }
    });

    ref.current = list;
    setterCallback(list);
  };

  const handleSelectionChange = (optionName, value) => () => {
    if (inloading) return toast.error("Processing data!");
    const updatedFilterOptions = { ...filterOptions };
    updatedFilterOptions[optionName] = value || '';
    if (optionName === 'line') {
      updatedFilterOptions.line = value ?? '';
      updatedFilterOptions.glass = '';
      updatedFilterOptions.variant = '';
      // console.log("update", updatedFilterOptions)
    } else if (optionName === 'glass') {
      const glass = glassDocList.find((item) => item.id === value);
      const line = lineDocList.find((item) => item.id === glass?.lineaRef.id);

      updatedFilterOptions.glass = glass?.id ?? '';
      updatedFilterOptions.line = line?.id ?? '';
      updatedFilterOptions.variant = '';
    } else if (optionName === 'variant') {
      // glassId is available in modelList that is created from sessionList
      const model = modelList.find((item) => item.id === value);
      const glass = glassDocList.find((item) => item.id === model?.glassId);
      const line = lineDocList.find((item) => item.id === glass?.lineaRef.id);
      updatedFilterOptions.variant = model?.id ?? '';
      updatedFilterOptions.glass = glass?.id ?? '';
      updatedFilterOptions.line = line?.id ?? '';
    }
    dispatch(setAnalyticsFilterOptions(updatedFilterOptions));
  };

  useEffect(() => {
    let lineData = {};
    let glassData = {};
    let modelData = {};
    let totalLineTime = 0;
    let totalLineSession = 0;
    setLoader(true);

    if (aggregateList.length > 0) {
      for (let i = 0; i < aggregateList.length; i++) {
        const { lineArray, glassesArray, variantsArray } = aggregateList[i];
        // console.log("AGGREGATEE LIST",aggregateList[i])
        lineArray.forEach((item) => {
          totalLineTime += item.time ?? 0;
          totalLineSession += item.numberOfSessions || 0;
          lineData = getSessionAndDuration(
            lineData,
            item.lineRef,
            item.time,
            item.numberOfSessions,
            item.visualization
          );
        });

        if (glassesArray) {
          // console.log(glassesArray)

          glassesArray.forEach((item) => {
            glassData = getSessionAndDuration(
              glassData,
              item.glassesRef,
              item.time,
              item.numberOfSessions,
              item.visualization,


            );
          });
        }

        if (variantsArray) {
          variantsArray.forEach((item) => {
            modelData = getSessionAndDuration(
              modelData,
              item.modelRef,
              item.time,
              item.numberOfSessions,
              item.visualization,
              item.glassesRef,
              item.lineRef,
            );
          });
        }

      }

      createArrayFromObject(lineData, 'nome_linea', lineDocList, setLineList, lineListRef);

      createArrayFromObject(glassData, 'nome_modello', glassDocList, setGlassList, glassListRef);

      createArrayFromObject(modelData, 'nomeOcchiale', modelDocList, setModelList, modelListRef);
      setLoader(false);
    } else {
      createArrayFromObject(glassData, 'nome_modello', glassDocList, setGlassList, glassListRef);
      createArrayFromObject(modelData, 'nomeOcchiale', modelDocList, setModelList, modelListRef);
      setLoader(false);
    }
  }, [glassDocList, lineDocList, modelDocList, aggregateList]);

  // Update model list if model is selected
  useEffect(() => {
    //GET ALL THE CORRESPONDING LINE, GLASS, & VARIANT WHEN SELECTED 
    //executes when line is selected - 
    if (!!line && !!!glass && !!!variant) {
      filterLine()
    }

    //executes when glass is selected
    if (!!glass && !!line && !!!variant) {
      filterGlass();
    }

    //executes when variant is selected
    if (!!variant && !!line && !!glass) {
      filterVariant()
    }



  }, [glass, line, variant, aggregateList]);


  const filterVariant = () => {
    const total = { name: 'Total', sessions: 0, duration: 0 };
    const filteredModelList = modelListRef.current.filter((item) => {
      if (item.id === variant) {
        total.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        total.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === variant;
    });

    setModelList([total, ...filteredModelList]);


    let gtotal = { name: 'Total', sessions: 0, duration: 0 };
    const filteredGlassList = glassListRef.current.filter((item) => {
      if(!filteredModelList[0]) return false
      if (item.id === filteredModelList[0].glassId) {
        gtotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        gtotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === filteredModelList[0].glassId;
    });
    setGlassList([gtotal, ...filteredGlassList]);



    let ltotal = { name: 'Total', sessions: 0, duration: 0 };
    const filteredLineList = lineListRef.current.filter((item) => {
      if(!filteredModelList[0]) return false
      if (item.id === filteredModelList[0].lineaRef) {
        ltotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        ltotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === filteredModelList[0].lineaRef;
    });

    setLineList([ltotal, ...filteredLineList]);

  }

  const filterLine = () => {
    /** LINE FILTER SECTION */
    let total = { name: 'Total', sessions: 0, duration: 0 };
    const filteredLineList = lineListRef.current.filter((item) => {
      if (item.id === line) {
        total.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        total.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === line;
    });
    setLineList([total, ...filteredLineList]);

    /** GLASS FILTER SECTION */
    const gtotal = { name: 'Total', sessions: 0, duration: 0 };
    const lineDoc = filteredLineList[0];
    const lineGlassList = new Set(
      lineDoc?.listaRefsOcchiale?.map((item) => item.id)
    );
    const filteredGlassList = glassListRef.current.filter((item) => {
      if (lineGlassList?.has(item.id)) {
        gtotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        gtotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return lineGlassList?.has(item.id);
    });
    setGlassList([gtotal, ...filteredGlassList]);
    const glassSet = new Set(filteredGlassList.map((item)=>item.id))
    /** VARIANT FILTER SECTION */
    // const glassDoc = filteredGlassList[0];
    const mtotal = { name: 'Total', sessions: 0, duration: 0 };
    const filteredModelList = modelListRef.current.filter((item) => {
        const isGlass = glassSet.has(item.glassId);
      if (isGlass) {
        mtotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        mtotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return isGlass
    });
    
    setModelList([mtotal, ...filteredModelList]);

  }

  const filterGlass = () => {
    let total = { name: 'Total', sessions: 0, duration: 0 };
    const filteredGlassList = glassListRef.current.filter((item) => {
      if (item.id === glass) {
        total.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        total.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === glass;
    });
    setGlassList([total, ...filteredGlassList]);
    const glassSet = new Set(filteredGlassList.map((item)=>item.id))
    
    const glassDoc = filteredGlassList[0];
    
    const mtotal = { name: 'Total', sessions: 0, duration: 0 };
    modelName(glassDoc);

    const filteredModelList = modelListRef.current.filter((item) => {
      const isGlass = glassSet.has(item.glassId);
    if (isGlass) {
      mtotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
      mtotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
    }
    return isGlass
  });
    setModelList([mtotal, ...filteredModelList]);


    let ltotal = { name: 'Total', sessions: 0, duration: 0 };
    const filteredLineList = lineListRef.current.filter((item) => {
      if(!glassDoc) return false;
      if (item.id === glassDoc.lineaRef.id) {
        ltotal.sessions += !isNaN(Number(item.sessions)) ? item.sessions : 0;
        ltotal.duration += !isNaN(Number(item.duration)) ? item.duration : 0;
      }
      return item.id === glassDoc.lineaRef.id;
    });
    setLineList([ltotal, ...filteredLineList]);

  }
  const columns = [
    {
      name: selectedTab,
      selector: (row) => row.name,
      sortable: selectedTab === 'Variants' ? false : true,
      cell: (row) => (
        <div
          className='cursor-pointer variant-name'
          onClick={handleSelectionChange(filterOptionMap[selectedTab], row.id)}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: 'Visualisations',
      selector: (row) => row.visualization,
      sortable: true,
    },
    {
      name: 'Time',
      selector: (row) => row.duration || row.time,
      sortable: true,
      format: (row) => getDurationFromSecond(row.duration || row.time),
    },
  ];

  useEffect(() => {
    setTableOverview({
      lines: tabMap['Lines']?.length ?? 0,
      glasses: tabMap['Glasses']?.length ?? 0,
      variants: tabMap['Variants']?.length ?? 0,
      linesList: tabMap['Lines'],
      glassesList: tabMap['Glasses'],
      variantsList: tabMap['Variants'],
    });
  }, [
    // tabMap['Glasses']?.length ?? 0,
    // tabMap['Lines']?.length ?? 0,
    // tabMap['Variants']?.length ?? 0,
    // aggregateList
    loading
  ]);

  return (
    <>
      <Row style={{ margin: '2rem 0rem', gap: '2rem 0rem' }}>
        {Object.entries(tabMap).map(([key, list]) => {
          let value = 0;
          list.forEach((item) => {
            if (item.name?.toLowerCase?.() !== 'total' && item.sessions) {
              value += 1;
            }
          });
          return (
            <SummaryCard
              key={key}
              label={key}
              value={value}
              loading={inloading}
              active={key === selectedTab}
              onClick={handleDataIndexChange(key)}
            />
          );
        })}
      </Row>
      {totalRow ? (
        <div
          className='cursor-pointer'
          onClick={handleSelectionChange(
            filterOptionMap[selectedTab],
            totalRow.id
          )}
        >
          {`${totalRow?.name} - ${totalRow?.sessions ?? totalRow?.visualization ?? ''} - ${getDurationFromSecond(totalRow.duration || totalRow.time)}`}
        </div>
      ) : null}
      <DataTable
        fixedHeader
        columns={columns}
        data={list.sort((a,b) => b.visualization - a.visualization)}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
      />
    </>
  );
};

export default AnalyticsTable;