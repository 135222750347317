


import React, { useEffect, useState } from "react";
import { aggregateDataForBrand, aggregateDataForAllBrands } from "./Helper";
import { differenceInDays } from "date-fns";

const SessionAggregator = (props) => {
  const [loadingMessage, setLoadingMessage] = useState('');
  const [skippedDays, setSkippedDays] = useState([]);
  const [totalOperations, setTotalOperations] = useState(0);
  const [completedOperations, setCompletedOperations] = useState(0);

  useEffect(() => {
    const startDate = "2024/05/01"; // Data di inizio
    const endDate = "2024/07/07"; // Data di fine

    const incrementCompletedOperations = () => {
      setCompletedOperations(prevCompleted => prevCompleted + 1);
    };

    // Per aggregare i dati di tutti i brand
    aggregateDataForAllBrands(startDate, endDate, setLoadingMessage, setSkippedDays, incrementCompletedOperations, props, setTotalOperations);
  }, []);

  const progressPercentage = totalOperations > 0 ? Math.round((completedOperations / totalOperations) * 100) : 0;

  return (
    <div>
      {loadingMessage && <p>{loadingMessage}</p>}
      <p>Progress: {progressPercentage}%</p>
      {/* Render skippedDays if needed */}
    </div>
  );
};

export default SessionAggregator;


