import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Container, Row } from "reactstrap";
import { doughnutOption } from "../../charts/chartjscharts/chartjsData";
import DeviceCategorize from "./DeviceCategorize";
import SummaryCard from "../../appnew/analytics/SummaryCard";
import DataTable from "react-data-table-component";

const DeviceAnalytics = ({ sessionList = [], aggregateList = [], loading }) => {
  const [data, setData] = useState({ datasets: [], labels: [] });
  const [allDevices, setAllDevices] = useState([]);
  const [deviceMisc, setMisc] = useState([]);
  const [resetGraph, setResetGraph] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [mobileDevices, setMobileDevices] = useState([]);
  const [desktopDevices, setDesktopDevices] = useState([]);
  const [selectedTab, setSelectedDataIndex] = useState("All Devices");
  const mobileCategories = ['android', 'android instant', 'ios', 'ios app clip','fbav (mobile)','instagram (mobile)','samsungbrowser (mobile)','chrome (mobile)','safari (mobile)','firefox (mobile)'];
  const desktopCategories = ['safari (desktop)', 'chrome (desktop)', 'firefox (desktop)', 'samsungbrowser (desktop)'];
  const createDoughnutData = (labels, data) => ({
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          "#42B1AC", "#241F45", "#FFC107", "#E91E63", "#4CAF50",
          "#9C27B0", "#FF5722", "#607D8B", "#F44336", "#2196F3",
        ],
      },
    ],
  });

  const DeviceFilter = (aggList) => {
    const deviceStats = { mobile: 0, desktop: 0, allDevice: 0, misc: [] };
    let allDevices = 0;
    let deviceType = [];


    aggList.forEach(({ deviceData }) => {
      if (deviceData) {

        deviceData.forEach((device) => {
          if (deviceType.includes(device.type)) {
            const index = deviceStats.misc.findIndex((el) => el.name == device.type);
            deviceStats.misc[index].count += (device.count ?? 0);

          } else {
            deviceType.push(device.type);
            deviceStats.misc.push({ name: device.type, count: (device.count ?? 0) });
          }

          if (mobileCategories.includes(device.type.toLowerCase()) || device.type.toLowerCase().includes("mobile")) {
            deviceStats.mobile = (deviceStats.mobile || 0) + device.count;
          } else if (desktopCategories.includes(device.type.toLowerCase()) || device.type.toLowerCase().includes("desktop")) {
            deviceStats.desktop = (deviceStats.desktop || 0) + device.count;
          }
          allDevices += device.count;

        });
        deviceStats.allDevice = allDevices;

      }
    });

    return deviceStats;
  }

  function groupAndJoinData(data) {
    const groupedData = {};
    
    for (const item of data) {
      const browserName = item.name.split(' ')[0].toLowerCase(); // Extract browser name (e.g., Firefox)

      const key = `${browserName}`; // Combine browser name and mobile/desktop

      if (!groupedData[key]) {
        groupedData[key] = { name: browserName.toUpperCase(), count: 0 };
      }

      groupedData[key].count += item.count;
    }

    return Object.values(groupedData).sort((a,b)=>b.count - a.count); // Convert object values to an array
  }


  useEffect(() => {
    const deviceStats = DeviceFilter(aggregateList);
    setDevicesData(deviceStats);
    // setAllDevices(deviceStats.allDevice);
    // setMobileDevices(deviceStats.mobile);
    // setDesktopDevices(deviceStats.desktop);
    // setMisc(deviceStats.misc);
    const labels = groupAndJoinData(deviceStats.misc).map((e) => e.name);
    const dataPoints = groupAndJoinData(deviceStats.misc).map((e) => e.count);
    setData(createDoughnutData(labels, dataPoints));
  }, [aggregateList, resetGraph])

  useEffect(() => {

    let filteredList = [];
    let data;
    // console.log(index);
    if (selectedTab == "Mobile Devices") {
      filteredList = devicesData.misc.filter((el) => mobileCategories.includes(el.name.toLowerCase()));
      filteredList = filteredList.sort((a,b)=> b.count - a.count);
      data = createDoughnutData(filteredList.map((e) => e.name), filteredList.map((e) => e.count))
      setData(data);
    }
    else if (selectedTab == "Desktop Devices") {
      filteredList = devicesData.misc.filter((el) => desktopCategories.includes(el.name.toLowerCase()) || el.name.toLowerCase().includes());
      filteredList = filteredList.sort((a,b)=> b.count - a.count);
      data = createDoughnutData(filteredList.map((e) => e.name), filteredList.map((e) => e.count))
      setData(data);

    }
    else {
      setResetGraph((curr) => !curr)
    }
  }, [selectedTab])

  const handleDataIndexChange = (index) => () => {
    setSelectedDataIndex(index);
  };



  const tabMap = useMemo(() => ({
    "All Devices": devicesData.allDevice,
    "Mobile Devices": devicesData.mobile,
    "Desktop Devices": devicesData.desktop,
  }), [devicesData.allDevice, devicesData.mobile, desktopDevices.desktop, devicesData.misc]);

  const columns = [{
    name: "Device",
    selector: (row) => row.name,
    // sortable: selectedTab === 'Variants' ? false : true,
    cell: (row) => (
      <div
        className='cursor-pointer variant-name'
        onClick={() => { }}
      >
        {row.name}
      </div>
    ),
  },
  {
    name: "Count",
    selector: (row) => row.count,
    // sortable: selectedTab === 'Variants' ? false : true,
    cell: (row) => (
      <div
        className='cursor-pointer variant-name'
        onClick={() => { }}
      >
        {row.count}
      </div>
    ),
  },

  ]

  return (
    <>
      <Row style={{ margin: "2rem 0rem", gap: "2rem 0rem" }}>
        {Object.entries(tabMap).map(([key, value]) => {
          // const value = list.reduce((acc, item) => acc + item[1], 0);
          return (
            <SummaryCard
              key={key}
              label={key}
              value={value}
              loading={loading}
              active={key === selectedTab}
              onClick={handleDataIndexChange(key)}
            />
          );
        })}
      </Row>
      <div className="shadow-sm m-t-20">
        <div className="p-20 rounded text-white" style={{ backgroundColor: "#42B1AC" }}>
          <h5>Users by Device</h5>
        </div>
        <Container style={{ padding: "1rem", width: "100%" }} className="text-white">
          <Bar data={data} options={doughnutOption} height={300} width={800} />
        </Container>
      </div>
      <div className="shadow-sm m-t-20">
        <Container style={{ padding: "1rem", width: "100%" }} className="text-white">
          <DataTable
            fixedHeader
            columns={columns}
            data={devicesData.misc}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
          />


        </Container>
      </div>
    </>
  );
};

export default DeviceAnalytics;
