import React, { useState } from 'react';
import ClientForm from './ClientForm';
import { AddNewClient } from '../../services/OnBordingUtils';
import { db } from '../../data/base'; // Assuming db is correctly imported from Firebase/Firestore
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'; // Assuming you have imported Modal and Alert from reactstrap

const AddClientModal = ({ existingClient, handleCloseModal }) => {
  const [registrationDate, setRegistrationDate] = useState(
    existingClient ? existingClient.registrationDate : firebase.firestore.Timestamp.now()
  );

  const [accountCreationDate, setAccountCreationDate] = useState(
    existingClient ? existingClient.accountCreationDate : firebase.firestore.Timestamp.now()
  );

  const [modal, setModal] = useState(false); // State for modal visibility
  const [successMessage, setSuccessMessage] = useState('');

  const toggleModal = () => setModal(!modal); // Function to toggle modal visibility

  const handleSubmit = async (e, lists) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Construct new client object from form data
    const newClient = {
      address: {
        city: formData.get('city') || '',
        country: formData.get('country') || '',
        postalCode: formData.get('postalCode') || '',
        state: formData.get('state') || '',
        street: formData.get('street') || '',
      },
      allProfileList: [],
      catalogsList: [],
      companyName: formData.get('companyName') || '',
      licenseList: [],
      mainProfileList: [],
      pec: formData.get('pec') || '',
      registrationDate: registrationDate,
      vatNumber: parseInt(formData.get('vatNumber')) || 0,
    };

    // Construct new profile object from form data
    const newProfile = {
      _email: formData.get('_email'),
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      avatarURL: '',
      canPlaceOrders: true,
      catalogsList: [],
      orderList: [],
      ref_log_access: '',
      role: 'Cliente',
      clientRef: '',
      accountCreationDate: accountCreationDate,
    };

    try {
      // Call service function to add new client and profile
      await AddNewClient(newClient, newProfile);
      setSuccessMessage('Client added successfully!');
      toggleModal();
      handleCloseModal();
    } catch (error) {
      console.error('Error adding client:', error.message);
      alert('Error adding client: ' + error.message);
    }
  };

  // Function to handle change in registration date
  const handleRegistrationDateChange = (date) => {
    setRegistrationDate(firebase.firestore.Timestamp.fromDate(date));
  };

  // Function to handle change in account creation date
  const handleAccountCreationDateChange = (date) => {
    setAccountCreationDate(firebase.firestore.Timestamp.fromDate(date));
  };

  return (
    <>
      <ClientForm
        existingClient={existingClient}
        handleSubmit={handleSubmit}
        onRegistrationDateChange={handleRegistrationDateChange}
        onAccountCreationDateChange={handleAccountCreationDateChange}
      />
    </>
  );
};

export default AddClientModal;