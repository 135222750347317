import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import Breadcrumb from "../../component/common/breadcrumb/breadcrumb";
import { db } from "../../data/base";
import LineEditorModal from "./LineEditorModal";

const LineList = () => {
  const [line, setLine] = useState(null);
  const [lineList, setLineList] = useState([]);
  const { selectedBrand } = useSelector((state) => state);

  const toggleLineModal = (selectedLine) => () => {
    setLine(selectedLine);
  };

  useEffect(() => {
    if (selectedBrand.id) {
      const brandRef = db.collection("Brand").doc(selectedBrand.id);
      db.collection("Linea")
        .where("brand", "==", brandRef)
        //   .limit(1)
        .get()
        .then((snap) => {
          const list = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setLineList(list);
        });
    }
  }, [selectedBrand]);

  return (
    <Fragment>
      <Breadcrumb title="Lines" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Line Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Glasses</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lineList.map((item, ind) => (
                        <tr key={item.id}>
                          <th scope="row">{ind + 1}</th>
                          <td className="text-nowrap">
                            <Link
                              className="text-reset"
                              to={`/lines/${item.id}`}
                            >
                              {item.nome_linea}
                            </Link>
                          </td>
                          <td>
                            <Link
                              className="text-reset"
                              to={`/lines/${item.id}`}
                            >
                              {item.descrizione_en}
                            </Link>
                          </td>
                          <td align="center">
                            <div className="d-flex align-items-center">
                              <div
                                className="p-1 rounded-circle cursor-pointer"
                                role="button"
                                onClick={toggleLineModal(item)}
                              >
                                <i className="fa fa-pencil"></i>
                              </div>
                              <Link
                                className="text-reset ml-2"
                                to={`/lines/${item.id}`}
                              >
                                <i className="fa fa-eye"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {/* <div className="d-flex justify-content-center mt-4">
                  <Pagination
                    aria-label="Page navigation example"
                    className="pagination-primary"
                  >
                    <PaginationItem>
                      <PaginationLink role="button">Previous</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink role="button">1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink role="button">2</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink role="button">3</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink role="button">Next</PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <LineEditorModal
        line={line}
        setLineList={setLineList}
        toggleLineModal={toggleLineModal}
      />
    </Fragment>
  );
};

export default LineList;
