import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { addNewBrand } from '../../services/OnBordingUtils';
import firebase from 'firebase/app';
import { db } from '../../data/base';

const AddBrandForm = ({ client, handleCloseModal }) => {
    const history = useHistory();

    const [brandData, setBrandData] = useState({
        code_path: '',
        data_consumption: '',
        descrizione: '',
        descrizione_en: '',
        endDate: '',
        glasses_number: 0,
        hdr_environment: '',
        isPublic: true,
        listaRefsLinea: [],
        main_brand: true,
        nome_brand: '',
        service_list: [],
        show_single_dot_list: true,
        skin: '',
        startDate: '',
        storage_consumption: {
            consumption: 0,
            modelsNumber: 0,
        },
        take_pic_template: '',
        web_domain: '',
        web_url: '',
    });

    const [logoFile, setLogoFile] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'url_logo') {
            setLogoFile(e.target.files[0]);
        } else {
            setBrandData({
                ...brandData,
                [name]: value,
            });
        }
    };

    const getUniqueFileName = async (fileName) => {
        const storageRef = firebase.storage().ref();
        let logoRef = storageRef.child(`immagini/brand_logo/${fileName}`);
        
        let uniqueFileName = fileName;
        let counter = 1;
        
        while (await logoRef.getMetadata().catch(() => false)) {
            const [name, extension] = fileName.split('.');
            uniqueFileName = `${name}_${counter}.${extension}`;
            logoRef = storageRef.child(`immagini/brand_logo/${uniqueFileName}`);
            counter++;
        }
        
        return uniqueFileName;
    };    

    const formatDateToString = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let logoUrl = '';

            if (logoFile) {
                const uniqueFileName = await getUniqueFileName(logoFile.name);
                const storageRef = firebase.storage().ref();
                const logoRef = storageRef.child(`immagini/brand_logo/${uniqueFileName}`);
                const uploadTask = logoRef.put(logoFile);
                const urlPromise = uploadTask.then(() => logoRef.getDownloadURL());
                logoUrl = await urlPromise;
            }

            const registrationDate = new Date();
            const startDate = formatDateToString(registrationDate);
            
            const endDate = new Date(registrationDate);
            endDate.setFullYear(endDate.getFullYear() + 1);
            const endDateString = formatDateToString(endDate);

            const brandToAdd = {
                ...brandData,
                url_logo: logoUrl,
                startDate,
                endDate: endDateString
            };

            const addedBrandId = await addNewBrand(brandToAdd);

            if (addedBrandId) {
                const clientRef = db.collection('Client').doc(client.id);
                await clientRef.update({
                    catalogsList: firebase.firestore.FieldValue.arrayUnion(addedBrandId)
                });

                handleCloseModal();
                history.push('/admin/configureDb');
            } else {
                console.error('Failed to add brand: Invalid brand ID');
            }
        } catch (error) {
            console.error('Error adding brand:', error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="nome_brand">Nome Brand</Label>
                <Input
                    type="text"
                    name="nome_brand"
                    id="nome_brand"
                    value={brandData.nome_brand}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="descrizione">Descrizione</Label>
                <Input
                    type="text"
                    name="descrizione"
                    id="descrizione"
                    value={brandData.descrizione}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="url_logo">Carica Logo (Immagine)</Label>
                <Input
                    type="file"
                    name="url_logo"
                    id="url_logo"
                    onChange={handleChange}
                    accept="image/*"
                    required
                />
            </FormGroup>
            <Button type="submit" color="primary" className="mx-auto d-block">Confirm</Button>
        </Form>
    );
};

export default AddBrandForm;