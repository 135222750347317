import { db } from '../data/base'; // Ensure the path is correct
import firebase from 'firebase/app';
import 'firebase/firestore';

// Add a new client with associated profile data
export async function AddNewClient(clientData, profileData) {
    try {
        // console.log("Profile email:", profileData._email);

        // Add profile to "Profile" collection
        await db.collection("Profile").doc(profileData._email.toLowerCase()).set(profileData);

        // Add client data to "Client" collection
        const clientDocRef = await db.collection("Client").add(clientData);
        const clientWithId = { id: clientDocRef.id, ...clientData };

        // Update client document with profile email in 'allProfileList' property
        await clientDocRef.update({
            allProfileList: firebase.firestore.FieldValue.arrayUnion(profileData._email.toLowerCase()),
        });

        // console.log("Client added successfully:", clientWithId);

        // Log access data structure
        const logAccessData = {
            account: profileData._email.toLowerCase(),
            listaLog: []
        };

        // Create new document in "Coll_LogAccess" collection and get its reference
        const logAccessDocRef = await db.collection("Coll_LogAccess").add(logAccessData);

        // Update profile with client and log access references
        const profileUpdate = {
            ...profileData,
            clientRef: clientDocRef.id,
            ref_log_access: logAccessDocRef.id
        };

        await db.collection("Profile").doc(profileData._email.toLowerCase()).update(profileUpdate);

        // console.log("Profile updated with client reference and log access reference.");

        return clientWithId; // Return the client added with generated ID

    } catch (error) {
        console.error("Error adding client:", error);
        throw new Error("Error adding client: " + error.message);
    }
}

// Add a new subscription
export async function addNewSubscription(subscriptionData) {
    try {
        // Add new subscription to "ARShades_Subscription" collection
        const docSubscriptionRef = await db.collection("ARShades_Subscription").add(subscriptionData);
        const subscriptionWithId = { id: docSubscriptionRef.id, ...subscriptionData };

        // console.log("Subscription added successfully:", subscriptionWithId);

        return subscriptionWithId; // Return the added subscription with generated ID
    } catch (error) {
        console.error("Error adding subscription:", error);
        throw new Error("Error adding subscription: " + error.message);
    }
}

// Add a new profile and update associated client's profile list
export async function addNewProfile(profileData) {
    try {
        // Add profile to 'Profile' collection
        await db.collection('Profile').doc(profileData._email.toLowerCase()).set({
            ...profileData,
        });

        // Update associated client document with profile email in 'allProfileList' property
        const clientDocRef = db.collection('Client').doc(profileData.clientRef);
        await clientDocRef.update({
            allProfileList: firebase.firestore.FieldValue.arrayUnion(profileData._email.toLowerCase()),
        });

        // console.log('Profile successfully added!');
        return true;
    } catch (error) {
        console.error('Error adding profile:', error);
        return false;
    }
}

// Add a new brand in Catalogs on FIREBASE
export async function addNewBrand(brandData) {
    try {
        const docRef = await db.collection('Catalogs').add(brandData);
        // console.log('Brand added successfully with ID:', docRef.id);
        return docRef.id; // Ritorna l'ID del documento appena creato
    } catch (error) {
        console.error('Error adding brand:', error);
        throw new Error("Error adding brand: " + error.message);
    }
}
// Aggiungi catalogo a profilo
export async function addClientCatalogToProfile(profileEmail, catalog, allProfileList, setAllProfileList) {
    try {
        const profileRef = db.collection('Profile').doc(profileEmail.toLowerCase());
        const profileDoc = await profileRef.get();

        if (!profileDoc.exists) {
            console.error(`Profilo con email ${profileEmail} non trovato.`);
            return;
        }

        const updatedCatalogsList = [...profileDoc.data().catalogsList];

        // Verifica se il catalogo è già presente
        if (!updatedCatalogsList.includes(catalog)) {
            updatedCatalogsList.push(catalog);

            // Aggiorna Firestore
            await profileRef.update({ catalogsList: updatedCatalogsList });

            // Aggiorna lo stato locale
            const updatedProfiles = allProfileList.map(profile => {
                if (profile._email === profileEmail) {
                    return {
                        ...profile,
                        catalogsList: updatedCatalogsList
                    };
                }
                return profile;
            });

            setAllProfileList(updatedProfiles);

            // console.log(`Catalogo ${catalog} aggiunto al profilo ${profileEmail}.`);
        } else {
            // console.log(`Catalogo ${catalog} già presente nel profilo ${profileEmail}.`);
        }
    } catch (error) {
        console.error('Errore aggiunta catalogo al profilo cliente:', error);
    }
}

// Rimuovi catalogo da profilo
export async function removeCatalogFromProfile(profileEmail, catalog, allProfileList, setAllProfileList) {
    try {
        const profileRef = db.collection('Profile').doc(profileEmail.toLowerCase());
        const profileDoc = await profileRef.get();

        if (!profileDoc.exists) {
            console.error(`Profilo con email ${profileEmail} non trovato.`);
            return;
        }

        const updatedCatalogsList = profileDoc.data().catalogsList.filter(item => item !== catalog);

        // Aggiorna Firestore
        await profileRef.update({ catalogsList: updatedCatalogsList });

        // Aggiorna lo stato locale
        const updatedProfiles = allProfileList.map(profile => {
            if (profile._email === profileEmail) {
                return {
                    ...profile,
                    catalogsList: updatedCatalogsList
                };
            }
            return profile;
        });

        setAllProfileList(updatedProfiles);

        // console.log(`Catalogo ${catalog} rimosso dal profilo ${profileEmail}.`);
    } catch (error) {
        console.error('Errore rimozione catalogo dal profilo cliente:', error);
    }
}