import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { db } from "../../data/base";
import { useHistory } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const ModelViewerRender = () => {
    const history = useHistory();

    const modelViewerRef = useRef();
    const [animationData, setAnimationData] = useState(null);
    const [currentFrame, setCurrentFrame] = useState(0);
    const [animationFileUrl, setAnimationFileUrl] = useState('https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fanimation_data%20(3).json?alt=media&token=dfcdf8fc-e7b7-4632-8445-1d2cec5673e9');
    const previousTimestamp = useRef(null);
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
    const animationFrameId = useRef();
    const { modelUrl  } = useParams();
    const [modelData, setModelData] = useState(null);
  
    useEffect(() => {
        const fetchData = async () => {
            // console.log("MODEL",modelUrl)

          const modelRef = db.collection('Modello').doc(modelUrl);
          const doc = await modelRef.get();
          
          if (doc.exists) {
            setModelData(doc.data());
            // console.log("MODEL",doc.data().urlGlbComplete)
          } else {
            // console.log("No such document!");
          }
        }
    
        fetchData();
      }, [modelUrl]);
    
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(animationFileUrl);
            // console.log("Load animation");
            setAnimationData(result.data);
            setIsAnimationPlaying(true);
        };

        fetchData();
    }, [animationFileUrl]);

    useEffect(() => {
        if (!animationData || !isAnimationPlaying) {
            return;
        }
        
        const animationLoop = (timestamp) => {
            if (!previousTimestamp.current || timestamp - previousTimestamp.current >= 1000 / 48) {
                const frame = Math.floor(timestamp / (1000 / 48)) % animationData.length;

                if (frame !== currentFrame) {
                    const {
                        camera_location,
                        camera_rotation_euler,
                        target_location,
                        distance_vector
                    } = animationData[frame];

                    const modelViewer = modelViewerRef.current;
                    const distance = Math.sqrt(distance_vector[0] ** 2 + distance_vector[1] ** 2 + distance_vector[2] ** 2);
                    modelViewer.cameraTarget = `${target_location[0]}m ${target_location[2]}m ${target_location[1]*-1}m`;
                    modelViewer.cameraOrbit = `${camera_rotation_euler[2]}rad ${camera_rotation_euler[0]}rad ${distance}m`;

                    setCurrentFrame(frame);
                }

                previousTimestamp.current = timestamp;
            }
            animationFrameId.current = requestAnimationFrame(animationLoop);
        };

        animationFrameId.current = requestAnimationFrame(animationLoop);

        return () => {
            if (animationFrameId.current) {
              cancelAnimationFrame(animationFrameId.current);
              setIsAnimationPlaying(false);
            }
        };
    }, [animationData, isAnimationPlaying]);

    const handleAnimationChange = (event) => {
        setIsAnimationPlaying(false);
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
        }
        setAnimationData(null);
        setCurrentFrame(0);  // reset the current frame when the animation is changed
        setAnimationFileUrl(event.target.value);
    };

    const handleGoBack = () => {
        history.push(`/ARShadesRender/`);
          };

    return (
        <div className="edit-vr-top-container">
           <div style={{ width: "100%"}}>

         <div
          className="container-heading-wrapper page-title"
          onClick={handleGoBack}
        >
          <MdOutlineArrowBackIosNew className="back-icon" />
          <p className="page-heading-text">Template Editor</p>
        </div>

       <div style={{display: 'flex'}}> 
       <div className="select-wrapper" style={{ padding: '50px', alignItems: 'center' }}>

<select onChange={handleAnimationChange} style={{ marginLeft: '20px' }}>
    <option value="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fanimation_data%20(3).json?alt=media&token=dfcdf8fc-e7b7-4632-8445-1d2cec5673e9">
        Animation 1
    </option>
    <option value="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fanimation_data%20(5).json?alt=media&token=df980c3e-6bc9-4678-b543-294adf1827af">
        Animation 2
    </option>
</select>
</div>
<div style={{ padding: '50px',display: 'flex', alignItems: 'center' }}>
     <model-viewer
    ref={modelViewerRef}
    src={modelData?.urlGlbComplete}
    alt="Test"
    style={{ width: '540px', height: '540px' , border:'1px solid black'}}
    min-camera-orbit="auto 0deg 0m" 
     max-camera-orbit="auto 180deg 1m"
    camera-orbit="19.3deg 81.6deg 0.29m"
    field-of-view="20.4deg" 
    interpolation-decay={0.01}
    key={animationFileUrl}
/>
</div>
       </div>
        
     </div>
     </div>
    );
};

export default ModelViewerRender;
