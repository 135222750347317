import React from "react";
import styles from "./BackDropTransparent.module.css";
import ReactDom from "react-dom";

function BackDrop(props) {
  return ReactDom.createPortal(
    <div className={styles.backdropIframe} onClick={props.onCancel}></div>,
    document.getElementById("portal")
  );
}

export default BackDrop;